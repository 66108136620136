import React                         from 'react';
import { countries, states, labels } from 'config/geo';
import { formatPhone }               from 'lib/formatters';
import { labelFromOptions }          from 'lib/options';
import {
  useCart,
  STEP_CONFIRMATION
}                                    from 'providers/CartProvider';
import { useForm }                   from 'providers/FormProvider';
import { FORM_SHIPPING } from '../../providers/CartProvider';

export default function DetailsDisplay(props)
{
  const { formId } = props;
  const { formValue, formValues } = useForm();
  const { activeStep } = useCart();
  const active = activeStep === STEP_CONFIRMATION;

  if (!active) {
    return null;
  }

  const billingInfo = formValues(formId);
console.log('formId >>', formId);
console.log('billingInfo >>', billingInfo);
  if (!billingInfo) {
    return null;
  }

  if (formId === FORM_SHIPPING && formValue('sameShipping', FORM_SHIPPING) === true) {
    return(
      <div className="columns">
        <div className="column is-12">
          same shipping
        </div>
      </div>
    )
  }

  return (
    <div className="columns is-multiline">
      <div className="column is-6">
        <label className="detail highlight">Full Name</label>
        {billingInfo.firstName} {billingInfo.lastName}
      </div>
      <div className="column is-6">
        <label className="detail highlight">Email</label>
        {billingInfo.email}
      </div>

      <CompanyName value={billingInfo.companyName} />

      <div className="column is-6">
        <label className="detail highlight">Street Address</label>
        <div>{billingInfo.streetAddress1}</div>
        {billingInfo.streetAddress2 !== '' ?
          <div>{billingInfo.streetAddress2}</div> :
          ''
        }
      </div>
      <div className="column is-6">
        <label className="detail highlight">Town / City</label>
        {billingInfo.city}
      </div>

      <div className="column is-6">
        <label className="detail highlight">{labels[billingInfo.country].region}</label>
        {labelFromOptions(
            states[billingInfo.country || 'CA'],
            billingInfo.province
        )}
      </div>
      <div className="column is-6">
        <label className="detail highlight">Country</label>
        {labelFromOptions(
            countries,
            billingInfo.country
        )}
      </div>

      <div className="column is-6">
        <label className="detail highlight">{labels[billingInfo.country].mailing}</label>
        {billingInfo.postalCode}
      </div>
      <div className="column is-6">
        <label className="detail highlight">Phone Number</label>
        {formatPhone(billingInfo.phone)}
      </div>
    </div>
  )
}

function CompanyName(props) {
  const { value } = props;

  if (!value) {
    return null;
  }

  return (
    <div className="column is-12">
      <label className="detail highlight">Company</label>
      {value}
    </div>
  )
}
