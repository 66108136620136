'use strict';

import React          from 'react';
import PropTypes      from 'prop-types';
import { connect }    from 'react-redux';
import { storeReady } from 'store/createStore';
import * as DISH      from 'api/dilatedShop';

const classBase = 'container-category-genre-itentifier';

class ShopCategoryGenreItentifier extends React.Component {
    constructor(props)
    {
        super(props);

        this.clickToggler = this._clickToggler.bind(this);
    }

    componentWillReceiveProps(nextProps)
    {
        if (this.props._categoryName !== nextProps._categoryName ||
            this.props._genreName !== nextProps._genreName
        ) {
            let category = nextProps._categoryName || '';
            let genre    = nextProps._genreName !== '' ?
                ': '+nextProps._genreName :
                '';

            DISH.shop.cgiIdentifier(category + genre);
        }
    }

    render()
    {
        // if (storeReady('shopState.categoryName') === false) {
        //     return null;
        // }

        return (
            <article
                className={classBase+this._getOpenClass()}
                onClick={this.clickToggler}
            >
                {this._displayIdentifier()}
                {this._displayToggler()}
            </article>
        )
    }

    _displayIdentifier()
    {
        return (
            <span
                dangerouslySetInnerHTML={{__html: this.props._cgi.identifier}}
            />
        )
    }

    _displayToggler()
    {
        return (
            <section className={classBase+'--toggler'+this._getOpenClass()}>
                <span className="ion-chevron-down opener" />
                <span className="ion-chevron-up closer" />
            </section>
        )
    }

    _getOpenClass()
    {
        return this.props._cgi.open === true ?
            ' open' :
            '';
    }

    _clickToggler()
    {
        DISH.shop.cgiToggle();
    }
};

const mapStateToProps = function(store)
{
    return {
        _categoryName: store.shopState.categoryName,
        _genreName:    store.shopState.genreName,
        _cgi:          store.shopState.categoryGenreIdentifier,
    };
}

export default connect(mapStateToProps)(ShopCategoryGenreItentifier);
