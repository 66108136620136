/**
 * Receives a price value and formats the return as a currency number. A boolean
 * flag can be provided to indicate that the price coming in is a float, otherwise
 * the integer will be divided by 100 to gain a decimal place.
 *
 * @param {integer|flaot} price
 * @param {boolean} asInteger
 */
export const formatPrice = (price, asInteger = true) => {
  const numeral = require('numeral');

  // Because we've been given an integer price value, we have to divide by 100
  // to get a decimal place for formatting.
  if (asInteger === true) {
    price = price / 100;
  }

  return numeral(price).format('0,0.00');
}

export const formatPhone = (phone) => {
  return '(' + phone.substring(0,3) + ') ' +
    phone.substring(3,6) + '-' + phone.substring(6);
}

export const formatMonth = (month, leadingZero = true) => {
  const numeral = require('numeral');

  let format = leadingZero === true ?
    '00' :
    '0';

  return numeral(month).format(format);
}

export const formatDescription = (description) => {
  return description.replace(/\n/g, '<br />');
}
