'use strict';

// An array of mapped fields to keep in sync if the client has asked to in fact,
// keep fields in sync. The left side the current field being modified by the
// client, the right side is the field to mirror.
const syncedFields = [];
syncedFields['billing_firstName']      = 'shipping_firstName';
syncedFields['billing_lastName']       = 'shipping_lastName';
syncedFields['billing_phone']          = 'shipping_phone';
syncedFields['billing_email']          = 'shipping_email';
syncedFields['billing_streetAddress1'] = 'shipping_streetAddress1';
syncedFields['billing_streetAddress2'] = 'shipping_streetAddress2';
syncedFields['billing_city']           = 'shipping_city';
syncedFields['billing_province']       = 'shipping_province';
syncedFields['billing_postalCode']     = 'shipping_postalCode';
syncedFields['billing_country']        = 'shipping_country';
syncedFields['billing_companyName']    = 'shipping_companyName';

/**
 * Syncs any field that has been mapped (above in the syncedFields object), in
 * the event that the client has asked to have fields kept in sync.
 *
 * @param  {object} data
 * @return {object}
 */
export const syncCheckoutBillingInfo = (state, data) => {
    // Whether or not the client has asked to have the fields kept in sync, we
    // need to update current field being modified in the store.
    let updates = {
        [data.field]: data.value,
    }

    // If the flag to keep any fields in sync has been set to false, we'll just
    // return the 'updates' object now and be done.
    if (state.checkout.syncInfo === false) {
        return updates;
    }

    // Down here, we've determined that the client has asked for the fields to be
    // kept in sync, so we should check if the current field being modified has
    // a mapping. If it does, set the fields mapping to the current value in the
    // 'updates' object before returning it.
    if (typeof syncedFields[data.field] !== 'undefined') {
        updates[syncedFields[data.field]] = data.value;
    }

    return updates;
}

/**
 * Clones whatever data is in the billing_* fields into the corresponding
 * shipping_* fields, based on the syncedFields constant defined above.
 *
 * @param   {Object} state
 * @returns {Object}
 */
export const syncShippingInfo = (state) => {
    Object.keys(syncedFields).map((field, key) => {
        state.checkout[syncedFields[field]] = state.checkout[field];
    });

    return state.checkout;
}

/**
 * Clears the billing info fields... resets them back to nothing.
 *
 * @returns {Object}
 */
export const clearBillingInfo = () => {
    return {
        billing_firstName:      '',
        billing_lastName:       '',
        billing_company:        '',
        billing_phone:          '',
        billing_email:          '',
        billing_streetAddress1: '',
        billing_streetAddress2: '',
        billing_city:           '',
        billing_province:       '',
        billing_country:        '',
        billing_postalCode:     '',
        billing_companyName:    '',
    };
}

/**
 * Clears the shipping info fields... resets them back to nothing. User has
 * indicated they would like to use the billing address for shipping.
 *
 * @returns {Object}
 */
export const clearShippingInfo = () => {
    return {
        shipping_firstName:      '',
        shipping_lastName:       '',
        shipping_company:        '',
        shipping_phone:          '',
        shipping_email:          '',
        shipping_streetAddress1: '',
        shipping_streetAddress2: '',
        shipping_city:           '',
        shipping_province:       '',
        shipping_country:        '',
        shipping_postalCode:     '',
        shipping_companyName:    '',
    };
}

/**
 * Clears the credit card info fields... resets the back to nothing.
 *
 * @returns {Object}
 */
export const clearCCInfo = () => {
    return {
        cc_cardnumber: '',
        cc_expiry:     '',
        cc_cvc:        '',
    }
}
