import deepmerge         from 'deepmerge';
import { cart as types } from 'store/reducers/storeTypes';
import * as actions      from 'store/actions/cart';

const initialState = {
    items:       [],
    count:       0,
    uniqueCount: 0,
    subTotal:    0,
    shipping:    0,
    tax:         0,
    total:       0,
    checkout: {
        stage: {
            active: 'billing',
            completed: {
                billing:  false,
                shipping: false,
                payment:  false,
            }
        },
        syncInfo:               true,
        acceptTC:               false,

        billing_firstName:      '',
        billing_lastName:       '',
        // billing_company:        '',
        billing_phone:          '',
        billing_email:          '',
        billing_streetAddress1: '',
        billing_streetAddress2: '',
        billing_city:           '',
        billing_province:       '',
        billing_country:        '',
        billing_postalCode:     '',
        billing_companyName:    '',

        shipping_firstName:      'Jimmy',
        shipping_lastName:       'James',
        // shipping_company:        '',
        shipping_phone:          '',
        shipping_email:          '',
        shipping_streetAddress1: '',
        shipping_streetAddress2: '',
        shipping_city:           '',
        shipping_province:       '',
        shipping_country:        '',
        shipping_postalCode:     '',
        shipping_companyName:    '',

        // Testing mode!
        billing_firstName:      'Jimmy',
        billing_lastName:       'James',
        // billing_company:        '',
        billing_phone:          '4165556666',
        billing_email:          'ding@dong.com',
        billing_streetAddress1: '40 Some Street',
        billing_streetAddress2: 'Unit 2',
        billing_city:           'Toronto',
        billing_province:       'ON',
        billing_country:        'CA',
        billing_postalCode:     'M4M4M4',
        billing_companyName:    'Some Company',

        syncInfo:               false,
        acceptTC:               true,
    },
    checkoutErrors: {
        hasErrors:              false,
        acceptTC:               '',

        // billing_firstName:      '',
        // billing_lastName:       '',
        // // billing_company:        '',
        // billing_phone:          '',
        // billing_email:          '',
        // billing_streetAddress1: '',
        // billing_streetAddress2: '',
        // billing_city:           '',
        // billing_province:       '',
        // billing_country:        '',
        // billing_postalCode:     '',
        // billing_companyName:    '',

        shipping_firstName:      '',
        shipping_lastName:       '',
        // shipping_company:        '',
        shipping_phone:          '',
        shipping_email:          '',
        shipping_streetAddress1: '',
        shipping_streetAddress2: '',
        shipping_city:           '',
        shipping_province:       '',
        shipping_country:        '',
        shipping_postalCode:     '',
        shipping_companyName:    '',

        cc_number:               '',
        cc_expiry:               '',
        cc_cvc:                  '',
    },
};

const cartReducer = function(state = initialState, action) {
    switch(action.type) {
        case types.CHECKOUT_CLEAR_ERROR_FIELDS:
            let newState = deepmerge(state, {
                checkoutErrors: actions.clearBillingInfo(),
            });
            newState = deepmerge(state, {
                checkoutErrors: actions.clearShippingInfo(),
            });
            newState = deepmerge(state, {
                checkoutErrors: actions.clearCCInfo(),
            })

            return {
                ...state,
                checkoutErrors: newState,
            };
        case types.CHECKOUT_CLEAR_SHIPPING_INFO:
            return deepmerge(state, {
                checkout:       actions.clearShippingInfo(),
                checkoutErrors: actions.clearShippingInfo(),
            });
        case types.CHECKOUT_STORE_CC_TOKEN:
            return {
                ...state,
                ccToken: action.data,
            };
        case types.CHECKOUT_SYNC_SHIPPING_INFO:
            return deepmerge(state, {
                checkout: actions.syncShippingInfo(state),
            });
        case types.CHECKOUT_UPDATE_ERROR_FIELDS:
            return deepmerge(state, {
                checkoutErrors: action.data
            });
        case types.CHECKOUT_UPDATE_FIELDS:
            return deepmerge(state, {
                // Synchronize billing fields with shipping fields.
                checkout: actions.syncCheckoutBillingInfo(state, action.data),
            });
        case types.CHECKOUT_UPDATE_STAGE:
            return deepmerge(state, {
                checkout: {
                    stage: action.data
                }
            });
        case types.INITIALIZE:
            return {
                ...state,
                items:       action.data.items,
                count:       action.data.count || 0,
                uniqueCount: action.data.uniqueCount,
                subTotal:    action.data.subTotal,
                shipping:    action.data.shipping,
                tax:         action.data.tax,
                total:       action.data.total,
            };
        case types.EMPTY:
            return {
                ...state,
                items:       initialState.items,
                count:       initialState.count,
                uniqueCount: initialState.uniqueCount,
                subTotal:    initialState.subTotal,
                shipping:    initialState.shipping,
                tax:         initialState.tax,
                total:       initialState.total,
            }
    }

    return state;
}

export default cartReducer;
