import React from 'react';
import Page  from 'components/Layouts/Page';

export default function PrivacyPolicy()
{
  return (
    <Page>
      <article>
        <h1>Privacy Policy</h1>

        <h2>Isotope Privacy Policy</h2>

        <p>Information (as defined in the ISOTOPE TERMS AND CONDITIONS) and certain other data, such as your IP address (“Data”), about you is subject to our “Privacy Policy." The Service adheres to the Safe Harbour Privacy Principles agreed upon by the U.S. and the E.U.. By using the Service you agree to and consent to the collection and use of your Information and Data by Company.</p>
      </article>
    </Page>
  )
}
