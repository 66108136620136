'use strict';

import React from 'react';

class ControlErrorMessage extends React.Component {
    render()
    {
        if (typeof this.props.errorMessage === 'undefined' ||
            this.props.errorMessage === null ||
            this.props.errorMessage === '' ||
            (typeof this.props.displayErrors !== 'undefined' &&
              this.props.displayErrors === false)
        ) {
            return null;
        }

        return (
            <span className="error-message">{this.props.errorMessage}</span>
        )
    }
};

export default ControlErrorMessage;
