import React   from 'react';
import Button  from 'components/Utilities/Button';

export default function ContinueButton(props)
{
    let icons = null;

    if (props.type === 'continue') {
        icons = <React.Fragment>
            <span className="ion-chevron-right"></span><span className="ion-chevron-right"></span>
        </React.Fragment>
    }

    return (
        <div className="column-12 button-row justify-center padding-decent--top">
            <Button
                onClick={props.onClick}
            >{props.children}{icons}</Button>
        </div>
    )
}
