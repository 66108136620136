import React from 'react';

export default function ListHeader(props)
{
    const { children, className } = props;

    const getClassName = () => {
        let classes = ['listheader'];

        if (typeof className !== 'undefined') {
            classes.push(className);
        }

        return classes.join(' ');
    };

    return (
        <div
            className={getClassName()}
        >{children}</div>
    )
}
