/**
 * Figures out which classnames need to be applied to this formItem, based on the
 * information we can glean from the props that are passed in.
 *
 * @param {object} props
 */
export function getClassNames(props)
{
    // The base className for a formItem.
    let classNames = ['container-formItem'];

    // If the formItem has been identified as being required, add a required class
    // so we can style it accordingly.
    if (props.required === true) {
        classNames.push('required');
    }

    // If the formItem has been given some classNames from where it's being added,
    // include those in the list of classes.
    if (props.className !== 'undefined') {
        classNames.push(props.className);
    }

    if (props.noPadding !== 'undefined') {
        classNames.push('no-padding');
    }

    // If there is an error message for this formItem, indicate that.
    if (typeof props.errorMessage !== 'undefined' &&
        props.errorMessage !== null &&
        props.errorMessage !== ''
    ) {
        classNames.push('hasError');
    }

    switch (props.size) {
        case 'small':
            classNames.push(props.size);
    }

    return classNames.join(' ');
}

export function getInputClassNames(props)
{
    let classNames = [];

    if (props.inputClassName !== 'undefined') {
        classNames.push(props.inputClassName);
    }

    return classNames.join(' ');
}

/**
 * Attempt to get an ID out of the props we've been given. If we don't have an ID
 * in the props, return the name from props.
 * @param {object} props
 */
export function getId(props)
{
    return typeof props.id !== 'undefined' ?
        props.id :
        props.name;
}

/**
 * Attempt to get a name out of the props we've been given. If we don't have a
 * name in the props, return the ID from props.
 * @param {object} props
 */
export function getName(props)
{
    return typeof props.name !== 'undefined' ?
        props.name :
        props.id;
}

export function getRandomId(prefix = '', length = 20)
{
   let result           = '';
   let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
   let charactersLength = characters.length;

   for (var i=0; i<length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
   }

   return (prefix != '' ? prefix+'_'+result : result);
}

export function getErrorMessage(field, errorData)
{
    // If the field key doesn't exist in the error data (great!), return
    // null, there aren't any errors to show for that field.
    if (typeof errorData === 'undefined' ||
        typeof errorData[field] === 'undefined'
    ) {
        return null;
    }

    // The way the validator library works, is each possible error message is
    // return as an array. Even when there is one message, it's an array with
    // one element in it. We'll display only the first one.
    return errorData[field][0];
}
