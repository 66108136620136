'use strict';

import React                  from 'react';
import PropTypes              from 'prop-types';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions }            from 'redux-router5';
import { storeReady }         from 'store/createStore';
import * as DISH              from 'api/dilatedShop';
import ShopListingItem        from 'components/Shop/Listing/ShopListingItem';
import Pagination             from 'components/Utilities/Pagination';

const classBase     = 'container-listings';
const defaultPerRow = 4;

class ShopListing extends React.Component {
    constructor(props)
    {
        super(props);

        this.state = {
            perRow: this.props.perRow || defaultPerRow,
        };
    }

    componentDidMount()
    {
        DISH.shop.getInventory(
            this.props.origin,
            this.props.categoryId || null,
            this.props.genreId || null,
            this.props.route.params.page,
            this.props.route.params.perPage
        );
    }

    componentWillReceiveProps(nextProps)
    {
        if (
            nextProps.categoryId !== this.props.categoryId ||
            nextProps.genreId != this.props.genreId ||
            nextProps.route.params.page !== this.props.route.params.page ||
            nextProps.route.params.perPage !== this.props.route.params.perPage
        ) {
            DISH.shop.getInventory(
                nextProps.origin,
                nextProps.categoryId || null,
                nextProps.genreId,
                nextProps.route.params.page,
                nextProps.route.params.perPage
            );
        }
    }

    render()
    {
        return (
            <article className={classBase}>
                <h1 className={classBase+'--header'}>Shop Items</h1>
                {this._displayPagination('top')}
                {this._displayInventory()}
                {this._displayPagination('bottom')}
            </article>
        )
    }

    _displayInventory()
    {
        if (storeReady('shopState.inventory') === false) { return null; }

        return (
            <section className={classBase+'--items'}>
                {this.props._inventory.map((item, key) => {
                    return (
                        <ShopListingItem
                            key={key}
                            item={item}
                        />
                    )
                })}
            </section>
        )
    }

    _displayPagination(location)
    {
        if (typeof this.props.pagination !== 'undefined' &&
            this.props.pagination === false
        ) {
            return null;
        }

        return (
            <Pagination
                location={location}
                currentPage={this.props._pagination.page}
                perPage={this.props._pagination.size}
                totalPages={this.props._pagination.totalPages}
                totalItems={this.props._pagination.total}
                routeName={this.props.route.name}
                routeParams={this.props.route.params}
            />
        )
    }
};

const mapStateToProps = function(store)
{
    return {
        route:       store.router.route,
        _inventory:  store.shopState.inventory,
        _pagination: store.shopState.pagination,
    };
}

export default connect(mapStateToProps)(ShopListing);
