import { storeDispatch, storeTypes } from 'store/createStore';
import * as DISH from 'api/dilatedShop';

export function getOrder(orderToken)
{
    console.log('orderToken >>', orderToken);
    return DISH.requests.GET(`/orders/${orderToken}`)
        .catch(error => {
            return new Promise(resolve => {
                storeDispatch(
                    storeTypes.ORDER.UPDATE_ORDER,
                    {
                        orderToken: orderToken,
                    },
                    resolve()
                );
            });
        })
        .then(response => {
            return new Promise(resolve => {
                storeDispatch(
                    storeTypes.ORDER.UPDATE_ORDER,
                    response.data.data,
                    resolve()
                );
            });
        });
}
