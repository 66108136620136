import React, { useState } from 'react'

const LayoutContext = React.createContext(undefined);

export default function LayoutProvider(props)
{
  const [curtainClick, setCurtainClick] = useState(null);
  const [curtainContents, setCurtainContents] = useState(null);
  const [curtainIsOpen, setCurtainIsOpen] = useState(false);

  const curtainOpen = (contents = false, onClick = null) => {
    setCurtainClick(onClick || null);
    setCurtainContents(contents || null);
    setCurtainIsOpen(true);
  }

  const curtainClose = () => {
    setCurtainIsOpen(false);
  }

  return (
    <LayoutContext.Provider
      value={{
        curtainClick,
        curtainClose,
        curtainContents,
        curtainIsOpen,
        curtainOpen,
      }}
    >
      {props.children}
    </LayoutContext.Provider>
  )
}

export function useLayout() {
  const context = React.useContext(LayoutContext)

  if (context === undefined) {
    throw new Error('useLayout must be used within a LayoutProvider')
  }

  return context
}
