'use strict';

import * as DISH                     from 'api/dilatedShop';
import { storeDispatch, storeTypes } from 'store/createStore';

/**
 * Opens the page curtain (overlay). Can be passed some contents for the curtain,
 * to get two birds stoned at once. If contents are passed, they are added first
 * then the curtain is shown. An onClick function can be passed as well, which
 * will be added to the click handler of the whole curtain.
 *
 * @param {mixed} contents
 * @param {function} onClick
 */
export function curtainOpen(contents = false, onClick = null)
{
    // If we have content for the curtain, add it first and then show the curtain.
    if (contents !== false) {
        return this.curtainContents(contents)
        .then(response => {
            storeDispatch(
                storeTypes.LAYOUT.CURTAIN_OPEN,
                onClick
            );
        });
    }

    storeDispatch(
        storeTypes.LAYOUT.CURTAIN_OPEN,
        onClick
    );
}

/**
 * Simply closes the page curtain (overlay).
 */
export function curtainClose()
{
    storeDispatch(storeTypes.LAYOUT.CURTAIN_CLOSE);
}

/**
 * Adds contents to the curtain. The method returns a Promise so that events can
 * be chained for event ordering.
 *
 * @param   {mixed} contents
 * @returns {Promise}
 */
export function curtainContents(contents)
{
    // Return a promise so we can chain events if need be.
    return new Promise((resolve, reject) => {
        storeDispatch(
            storeTypes.LAYOUT.CURTAIN_CONTENTS,
            contents,
            () => { resolve(); }
        );
    })
}

/**
 * Simply scrolls the screen to the 0,0 position. Useful in route transitions
 * where the navigation changes, but the the position may not.
 */
export function toTop()
{
    window.scrollTo(0, 0);
}
