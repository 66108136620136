import React      from 'react';
import deepmerge  from 'deepmerge';
import Album      from 'components/Pages/Shop/Album';
import Search     from 'components/Pages/Search/Front';
import Shop       from 'components/Pages/Shop/Front';
import * as Pages from 'components/Pages';

// Need to specify BASE, because Webpack's resolve.modules will find THIS file
// when trying to resolve routes/routes. We on purpose want to include the BASE
// routes and add to them for this label.
import baseRoutes from 'BASE/routes/routes';
import { providers as baseProviders } from 'BASE/routes/routes';

export * from 'BASE/routes/routes';

const providers = deepmerge(baseProviders, []);

// When the LABEL would like to use the base routes (it should...), but add some
// more functionality to their "custom" shop, we'll simply import the base routes
// and deep merge the added page paths. Merge because a label may want/need to
// override the base settings in some scenarios.
let labelRoutes = deepmerge(baseRoutes, {
    // Platform Routes
    'home': {
      component: <Shop />,
      exact: true,
      path: '/',
    },
    'search': {
      component: <Search />,
      path: '/search',
    },
    'shop': {
      children: {
        'album': {
          path: '/album/:artist/:album',
          component: <Album />,
        },
      },
    },
    // Label Routes
    'privacyPolicy': {
      path: '/privacy-policy',
      component: <Pages.PrivacyPolicy />,
    },
    'shippingReturns': {
      path: '/shipping-returns',
      component: <Pages.ShippingReturns />,
    },
    'termsConditions': {
      path: '/conditions-of-use',
      component: <Pages.TandC />
    },
});

export default labelRoutes;
export { providers }
