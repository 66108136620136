import React, { useEffect }  from 'react';
import CategoryListingHeader from 'components/Shop/CategoryListing/CategoryListingHeader';
import CategoryListingItems  from 'components/Shop/CategoryListing/CategoryListingItems';
import { useShop }           from 'providers/ShopProvider';


export default function ShopCategoryListing(props)
{
  const { categoryId, perPage, title } = props;
  const { categoryInventory, inventory } = useShop();

  const categoryLabel = `category_${categoryId}`;
  const classBase     = 'container-category-list';

  useEffect(() => {
    categoryInventory(
      categoryId,
      { perPage: perPage || null, inventoryKey: categoryLabel }
    );
  }, []);

  return (
    <section className={classBase}>
      <CategoryListingHeader
        classBase={classBase}
        title={title}
      />
      <CategoryListingItems
        categoryId={categoryId}
        classBase={classBase}
        items={inventory[categoryLabel]}
      />
    </section>
  )
}
