'use strict';

import { router5Reducer }           from 'redux-router5';
import { reducer as toastrReducer } from 'react-redux-toastr'

// Reducers
import cartReducer   from 'store/reducers/cartReducer';
import layoutReducer from 'store/reducers/layoutReducer';
import orderReducer  from 'store/reducers/orderReducer';
import shopReducer   from 'store/reducers/shopReducer';

var reducers = {
    cartState:   cartReducer,
    layoutState: layoutReducer,
    orderState:  orderReducer,
    router:      router5Reducer,
    shopState:   shopReducer,
    toastr:      toastrReducer,
};

export default reducers;
