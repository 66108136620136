import React          from 'react';
import { Elements }   from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

export default function StripeProvider(props)
{
  const { children } = props;

  console.log('process.env.REQUIRES_STRIPE >>', process.env.REQUIRES_STRIPE);
  // if (process.env.REQUIRES_STRIPE === false) {
  //     return children
  // }

  const stripePromise = loadStripe(process.env.STRIPE_KEY);

  return (
    <Elements stripe={stripePromise}>
      {children}
    </Elements>
  )
}
