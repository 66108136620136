import React             from 'react';
import { Route, Switch } from 'react-router-dom';
import Front             from 'components/Pages/Order/Front';
import Order             from 'components/Pages/Order/Order';
import { getRoute }      from 'routes/utilities';

export default {
  Front,
  Order,
};

// export default function OrderX(props)
// {
//     return (
//         <section className="container-orders">
//             <Switch>
//                 <Route
//                     component={Front}
//                     exact
//                     path={getRoute('orders')}
//                 />
//                 <Route
//                     exact
//                     path={getRoute('orders.order')}
//                 >
//                     <Order {...props} />
//                 </Route>
//                 <Route
//                     exact
//                     path={getRoute('orders.thanks')}
//                     render={props => ( <Order {...props} thanks={true} /> )}
//                 />
//             </Switch>
//         </section>
//     )
// }
