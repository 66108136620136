import React         from 'react';
import Pagination    from 'components/Utilities/Pagination';
import { useSearch } from 'providers/SearchProvider';

export default function SearchPagination(props)
{
  const { pagination, results } = useSearch();

  if (!results.length || pagination.totalPages === 1) {
      return null;
  }

  return (
    <Pagination />
  )
}
