// Need to specify BASE, because Webpack's resolve.modules will find THIS file
// when trying to resolve routes/routes. We on purpose want to include the BASE
// config and add to them for this label.
import baseConfig from 'BASE/config/config';
import deepmerge  from 'deepmerge';

export default deepmerge(baseConfig, {
    categories: {
        featured: 1,
    }
});
