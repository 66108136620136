import deepmerge         from 'deepmerge';
import { shop as types } from 'store/reducers/storeTypes';
import * as actions      from 'store/actions/shop';

const initialState = {
    categoryId:   null,
    categoryName: '',
    categorySlug: '',
    genreId:      null,
    genreName:    '',
    genreSlug:    '',
    artistSlug:   '',
    albumSlug:    '',
    categories:   [],
    genres:       [],
    inventory:    [],
    album:        [],
    pagination:   {},
    categoryGenreIdentifier: {
        open:       false,
        identifier: '&nbsp;',
    },
    search:       {
        searching: false,
        string:    '',
        options:   {
            artist:    false,
            album:     false,
            ean:       false,
            label:     false,
            genre:     false,
            exact:     false,
            perPage:   25,
            orderBy:   'artist',
            direction: 'asc',
        },
        results:   [],
    },
    pagination: {
        currentPage: 1,
        totalPages: 1,
        nextLink: '',
        prevLink: '',
    }
};

const shopReducer = function(state = initialState, action) {
    switch(action.type) {
        case types.SET_ACTIVE_ALBUM:
            return {
                ...state,
                artistSlug: action.data.artistSlug,
                albumSlug:  action.data.albumSlug,
            };
        case types.SET_ACTIVE_CATEGORY:
            let sacCategory = actions.resolveActiveCategoryId(action.data, state);

            return {
                ...state,
                categoryId:   sacCategory.categoryId,
                categoryName: sacCategory.categoryName,
                categorySlug: sacCategory.categorySlug,
            };
        case types.CGI_CLOSE:
            let cgiClose = state.categoryGenreIdentifier;

            cgiClose.open = false;

            return deepmerge(state, {
                categoryGenreIdentifier: cgiClose,
            });
        case types.CGI_IDENTIFIER:
            return {
                ...state,
                categoryGenreIdentifier: actions.cgiIdentifier(action.data, state)
            }
        case types.CGI_TOGGLE:
            return deepmerge(state, {
                ...state,
                categoryGenreIdentifier: actions.cgiToggle(state),
            });
        case types.SET_ACTIVE_GENRE:
            let sagGenre = actions.resolveActiveGenreId(action.data, state);

            return {
                ...state,
                genreId:   sagGenre.genreId,
                genreName: sagGenre.genreName,
                genreSlug: sagGenre.genreSlug,
            };
        case types.UPDATE_ALBUM:
            // let uaAlbum = actions.resolveAlbum(action.data, state);

            return {
                ...state,
                album: action.data,
            };
        case types.CLEAR_ALBUM_DATA:
            return {
                ...state,
                artistSlug: '',
                albumSlug:  '',
                album:      [],
            };
        case types.SEARCH_RESULTS_CLOSE:
            let srClose = state.search;

            srClose.open = false;

            return deepmerge(state, {
                search: srClose,
            });
        case types.SEARCH_RESULTS_OPEN:
            let srOpen = state.search;

            srClose.open = true;

            return deepmerge(state, {
                search: srClose,
            });
        case types.UPDATE_CATEGORIES:
            return {
                ...state,
                categories: action.data
            };
        case types.UPDATE_GENRES:
            return {
                ...state,
                genres: action.data
            };
        case types.UPDATE_INVENTORY:
            let inventoryKey = 'data';
console.log('action.data >>', action.data);
            if (typeof action.data.inventoryKey !== 'undefined' &&
                action.data.inventoryKey !== null
            ) {
                inventoryKey = action.data.inventoryKey;
            }

            let inventoryData = Object.assign(
                {},
                state.inventory,
                { [inventoryKey]: action.data.data }
            );

            return {
                ...state,
                inventory: inventoryData
            };
        case types.UPDATE_PAGE_META:
            return {
                ...state,
                pagination: {
                    size:       action.data.pagination.size,
                    total:      action.data.pagination.total,
                    totalPages: action.data.pagination.totalPages,
                    page:       action.data.pagination.page,
                    nextLink:   action.data.links.next,
                    prevLink:   action.data.links.prev,
                }
            };
        case types.UPDATE_SEARCH:
            return deepmerge(state, {
                search: action.data,
            });
        case types.UPDATE_SEARCH_RESULTS:
            let ussResults = state.search;

            ussResults.results = action.data;
            ussResults.open    = true;

            return deepmerge(state, {
                search: ussResults,
            });
        case types.UPDATE_SEARCH_STRING:
            let ussString = state.search;

            ussString.string = action.data;

            return deepmerge(state, {
                search: ussString,
            });
        case types.UPDATE_SEARCH_OPTIONS:
            return {
                ...state,
                search: deepmerge(state.search, { options: action.data }),
            };
        case types.UPDATE_PAGINATION:
            return {
                ...state,
                pagination: deepmerge(state.pagination, action.data),
            };
    }

    return state;
}

export default shopReducer;
