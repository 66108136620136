import React         from 'react'
import { useSearch } from 'providers/SearchProvider';

export default function SearchButton(props) {
  const { classBase, hideSearchButton } = props;
  const { doSearch } = useSearch();

  if (hideSearchButton === true) {
    return null;
  }

  return (
    <article className={`${classBase}--button`}>
      <button
        className="btn btn--large"
        onClick={doSearch}
      >
        Search
      </button>
    </article>
  )
}
