import React, { useEffect, useState, } from 'react';
import * as DISH from 'api/dilatedShop';
import { useAuth } from 'providers/AuthProvider';
import { useCart } from 'providers/CartProvider';

const FrameworkContext = React.createContext();

export default function FrameworkProvider(props)
{
  const [settings, setSettings] = useState({});
  const { doAuthInit } = useAuth();
  const { doCartInit, resolveOrGenerateCartToken } = useCart();
  const requiresLogin = !!process.env.REQUIRES_LOGIN

  useEffect(() => {
    if (requiresLogin) {
      doAuthInit();
    }
  }, [requiresLogin]);

  const doFrameworkInit = () => {
    // Method to try to retrieve a cartToken out of localStorage (so that it can
    // survive visits to the site). If a token cannot be retrieved from storage,
    // a request to the backend to generate a new cartToken is made - which is
    // then persisted in localStorage and returned here.
    resolveOrGenerateCartToken()
      .then(cartToken => {
        // Making an initialization request to the backend, which is a unifying
        // request - Gets cart information AND channel/site settings in one
        // request to not have to make many... Trying to save on back-and-fifth.
        return DISH.requests.GET('/channels/initialize', {
          params: {
            cartToken: cartToken,
          }
        });
      })
      .then(initResponse => {
        const data = initResponse.data.data;

        setSettings(data.settings);
        doCartInit(data.cartToken, data.cart);
      });
  };

  const getSettings = (settingsKey = null) => {
    // const settings = JSON.parse(localStorage.getItem('channelSettings'));

    // If there are no settings to process, null outta here.
    if (!Object.keys(settings).length) {
      return null;
    }

    // If we don't have a setttingsKey to return for, just return ALL of the
    // settings we got out of storage.
    if (settingsKey === null) {
      return settings;
    }

    // If there is no . (dot) in the settingsKey value, we're being asked for a
    // first-class citizen of the settings. Easy...
    if (settingsKey.includes('.') === false) {
      return settings[settingsKey];
    }

    let keys = settingsKey.split('.');
    let data = settings;

    // Iterate through our keys to try and get the data for the deep-nested location
    // we've been asked for.
    for (let i=0; i<keys.length; i++) {
      // If there is no element at the key we're iterating, we're not going to
      // be able to set anything or go deeper, so return null, we're done.
      if (data.hasOwnProperty(keys[i]) === false) {
        return null;
      }

      // Set our local value to the settings attribute we're currently on, so
      // we can get deeper as we go.
      data = data[keys[i]];
    }

    return data;
  }


  return (
    <FrameworkContext.Provider
      value={{
        doFrameworkInit,
        getSettings,
        requiresLogin,
        settings,
      }}
    >
      {props.children}
    </FrameworkContext.Provider>
  )
}

export function useFramework() {
  const context = React.useContext(FrameworkContext)

  if (context === undefined) {
    throw new Error('useFramework must be used within a FrameworkProvider')
  }

  return context
}

export function useRenderError(error) {
  console.error('ERROR >>', error);
}
