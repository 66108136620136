import { storeDispatch, storeTypes, storeGet }     from 'store/createStore';
import * as DISH                                   from 'api/dilatedShop';
import { billingConstraints, shippingConstraints } from 'validators/checkout';
import * as Exceptions                             from 'config/exceptions';
import history                                     from 'historyX';

// /**
//  * Given a cart token, a request goes to the backend to get all the details that
//  * match the token.
//  *
//  * @param {string} token
//  */
// export function initialize(token)
// {
//     return DISH.requests.GET('/cart/'+token)
//     .then(cartResponse => {
//         // Because other things will be initializing the cart (see: unified backend
//         // requests), there is a separate method used to do the actual data set.
//         return DISH.cart.setCartStore(cartResponse.data.data);
//     });
// }

// /**
//  * Receives an object representing the important bits of a cart in the backend.
//  * The data is used to set the items into the store in the app.
//  *
//  * @param {object} cartData
//  */
// export function setCartStore(cartData)
// {
//     return new Promise((resolve, reject) => {
//         storeDispatch(
//             storeTypes.CART.INITIALIZE,
//             {
//                 items:       cartData.items,
//                 count:       cartData.items_count,
//                 uniqueCount: cartData.items_unique_count,
//                 subTotal:    cartData.amounts.sub_total,
//                 shipping:    cartData.amounts.shipping,
//                 tax:         cartData.amounts.tax,
//                 total:       cartData.amounts.total,
//             },
//             () => { resolve(cartData); }
//         );
//     });
// }

// /**
//  * Get/Set the cart token from/in localStorage. This token is used to identify
//  * the user's shopping cart in the backend.
//  *
//  * @param {string} token
//  * @param {boolean} create
//  */
// export function token(token = null)
// {
//     // If the token value is null, requester wants the value, so return the value.
//     if (token === null) {
//         return localStorage.getItem('cartToken');
//     }

//     // When the token value is false, we're removing the cart in storage.
//     if (token === false) {
//         return localStorage.removeItem('cartToken');
//     }

//     // Here means we've been given a token value to save in localStorage.
//     localStorage.setItem('cartToken', token);

//     // Return a Promise for chaining... Resolving with the cart token.
//     return new Promise((resolve, reject) => {
//         resolve(localStorage.getItem('cartToken'));
//     });
// }

// /**
//  * If a cart token already exists in localStorage, that is returned to the caller.
//  * When a cart token doesn't already exist, the backend is called to create a
//  * new one (by passing a non-token string). It is then set to localStorage and
//  * the value returned to the caller. Optional flag to return the cartToken as a
//  * Promise for async chaining.
//  *
//  * @param {boolean} asPromise
// */
// export function generateToken(asPromise = false)
// {
//     // If the token already exists in localStorage, return that and be done.
//     if (this.token() !== null) {
//         return asPromise === false ?
//             // If caller just wants the token.
//             this.token() :
//             // Otherwise a Promise for async chaining.
//             new Promise((resolve, reject) => {
//                 resolve(this.token());
//             });
//     }

//     // Noooope, we'll have to generate a new one.
//     return DISH.requests.GET('/cart/generate')
//     .then(response => {
//         // Store the response in localStorage for future.
//         return this.token(response.data.data.token);
//     })
//     .then(cartToken => {
//         // Return the token to the caller.
//         return asPromise === false ?
//             // If caller just wants the token.
//             cartToken :
//             // Otherwise a Promise for async chaining.
//             new Promise((resolve, reject) => {
//                 resolve(cartToken);
//             });
//     });
// }

/**
 * Accessor method that is the opposite of hasItems().
 *
 * @returns {boolean}
 */
export function isEmpty(checkToken = false)
{
    return !this.hasItems(checkToken);
}

/**
 * Indicates whether the cart has any items in it. If we've been asked to confirm
 * that there is a credit card token (Stripe) as well, we'll check that against
 * the cartReducer. If we have NOT been asked to confirm the token as well, we
 * default that check to true.
 *
 * @param   {boolean} checkToken
 * @returns {boolean}
 */
export function hasItems(checkToken = false)
{
    let _cart    = storeGet('cartState');

    if (typeof _cart.items === 'undefined') {
        return false;
    }

    let hasItems = Object.keys(_cart.items).length > 0;
    let hasToken = checkToken === true ?
        Object.keys(_cart.ccToken).length > 0 :
        true;

    return hasItems && hasToken
}

// export function add(inventoryId, quantity, type, item)
// {
//     // alert('adding: '+inventoryId+" : "+quantity+" : "+type+" : items");

//     // Get the token before we try adding to cart.
//     let token  = this.token();
//     // Default to "already has a cart (opportunistic...)".
//     let url    = process.env.DILATED_API+'/cart/'+token;
//     let method = 'put';

//     // If the token is null, we have to switch the endpoint and method so we can
//     // create a new cart.
//     if (token === null) {
//         url    = process.env.DILATED_API+'/cart';
//         method = 'post';
//     }

//     // Send the cart update to the backend
//     return DISH.requests.REQUEST({
//         method: method,
//         url:  url,
//         data: {
//             item: inventoryId,
//             qty:  quantity,
//         },
//         headers: {
//             'Content-Type': 'multipart/form-data',
//         },
//     })
//     .then(response => {
//         // We get a cart token in the response from the backend, we need to store
//         // this in local storage of the client, for reference later.
//         return new Promise((resolve, reject) => {
//             this.token(response.data.data.token);

//             storeDispatch(
//                 storeTypes.CART.INITIALIZE,
//                 {
//                     items:       response.data.data.items,
//                     count:       response.data.data.items_count,
//                     uniqueCount: response.data.data.items_unique_count,
//                     subTotal:    response.data.data.amounts.sub_total,
//                     shipping:    response.data.data.amounts.shipping,
//                     tax:         response.data.data.amounts.tax,
//                     total:       response.data.data.amounts.total,
//                 },
//                 () => { resolve(); }
//             );
//         });
//     })
//     .catch(error => {
//         return false;
//     });
// }

// export function remove(itemToken)
// {
//     let token = this.token();

//     return DISH.requests.DELETE('/cart/'+token+'/items/'+itemToken)
//     .then(response => {
//         return this.initialize(token);
//     });
// }

// export function updateQuantity(itemToken, inventoryId, quantity)
// {
//   let token = this.token();

//   return DISH.requests.PUT(`/cart/${token}/items/${itemToken}`, {
//     data: {
//       item: inventoryId,
//       qty: quantity,
//     }
//   })
//   .then(response => {
//     return this.initialize(token);
//   });
// }

/**
 * Toggles the state of shipping information being the same as billing information
 * in the cardReducer.
 *
 * @param {boolean} newState
 */
export function toggleShipping(newState)
{
    // Toggle the current state of the sync shipping info option.
    return this.updateCheckoutFields({
        field: 'syncInfo',
        value: newState,
    })
    .then(response => {
        // If the shipping information is NOT synced with the billing information,
        // we should clear out the shipping inputs before showing them to user.
        if (newState === false) {
            return this.clearSyncedShippingInfo();
        }

        // Clone the billing information into the shipping information at this
        // point.
        return this.syncShippingInfo();
    });
}

/**
 * Receives an object of data that represents a field that was edited and the
 * value it currently holds. This data is used to update the cartReducer to keep
 * the state of the checkout form. Returns a Promise for async awesome.
 *
 * @param   {Object} data
 * @returns {Promise}
 */
export function updateCheckoutFields(data)
{
    return new Promise((resolve, reject) => {
        storeDispatch(
            storeTypes.CART.CHECKOUT_UPDATE_FIELDS,
            {
                field: data.field,
                value: data.value,
            },
            () => { resolve(); }
        );
    });
}

/**
 * Updates the cartReducer with any error messages that are pertinent to the
 * checkout submission. Returns a Promise for chaining.
 *
 * @param   {Object} data
 * @returns {Promise}
 */
export function updateCheckoutErrorFields(data)
{
    return new Promise((resolve, reject) => {
        storeDispatch(
            storeTypes.CART.CHECKOUT_UPDATE_ERROR_FIELDS,
            data,
            () => { resolve(); }
        );
    });
}

/**
 * Tell the cartReducer to clear out whatever data is currently stored for the
 * shipping fields. This action usualy coincides with user indicating they want
 * to use their billing address for shipping.
 *
 * @returns {Promise}
 */
export function clearSyncedShippingInfo()
{
    return new Promise((resolve, reject) => {
        storeDispatch(
            storeTypes.CART.CHECKOUT_CLEAR_SHIPPING_INFO,
            {},
            () => { resolve(); }
        );
    });
}

/**
 * When called, clones whatever information is stored in the billing_* fields of
 * the cardReducer over to the shipping_* equiavlent. Mainly, this would be used
 * when a user decides to NOT clone their shipping info, and then decides they
 * would in fact like to clone it. Upon "un-syncing", the shipping_* fields are
 * cleared, so this restores...
 */
export function syncShippingInfo()
{
    return new Promise((resolve, reject) => {
        storeDispatch(
            storeTypes.CART.CHECKOUT_SYNC_SHIPPING_INFO,
            {},
            () => { resolve(); }
        )
    });
}

/**
 * Clears out any previous error messages from an earlier attempt to validate
 * the checkout form. Returns a Promise for chaining.
 *
 * @returns {Promise}
 */
export function clearErrorFields()
{
    return new Promise((resolve, reject) => {
        storeDispatch(
            storeTypes.CART.CHECKOUT_CLEAR_ERROR_FIELDS,
            {},
            () => { resolve(); }
        );
    });
}

/**
 * Validates the Billing fields of the checkout. The format of the checkout is
 * separate "steps", so each section is validated in isolation.
 */
export function validateBillingDetails()
{
    // Clear any previous errors.
    this.clearErrorFields();

    return new Promise((resolve, reject) => {
        // Get the values that user has entered and is now trying to submit.
        let _checkout   = storeGet('cartState.checkout');

        // Using the validation constraints for the billing information, validate
        // that the input is the way we're expecting it to be inside the _checkout
        // store.
        let errorFields = DISH.validate(_checkout, billingConstraints);
console.log('errorFields >>', errorFields);
        // If there were any fields found to be missing, update the cartReducer
        // with the missing fields, so we can render the problems to the screen
        // and user can fix them.
        if (typeof errorFields !== 'undefined' &&
            Object.keys(errorFields).length > 0
        ) {
            this.updateCheckoutErrorFields(errorFields);

            // reject() the promise that we've returned, because errors.
            reject(new Exceptions.CheckoutValidation(
                'Missing a required field'
            ));
        }

        resolve(true);
    });
}

/**
 * Validates the Shipping fields of the checkout. The format of the checkout is
 * separate "steps", so each section is validated in isolation.
 */
export function validateShippingDetails()
{
    // Clear any previous errors.
    this.clearErrorFields();

    return new Promise((resolve, reject) => {
        // Initialize!
        let errorFields = {};
        // Get the values that user has entered and is now trying to submit.
        let _checkout   = storeGet('cartState.checkout');

        // If user has indicated they are submitting different information for
        // shipping than what is there for billing, we'll merge any new error
        // messages from the shipping constraints, into the exiting error messages,
        // if there are any.
        if (_checkout.syncInfo === true) {
            this.syncShippingInfo();
        }

        errorFields = DISH.validate(_checkout, shippingConstraints);

        // If there were any fields found to be missing, update the cartReducer
        // with the missing fields, so we can render the problems to the screen
        // and user can fix them.
        if (typeof errorFields !== 'undefined' &&
            Object.keys(errorFields).length > 0
        ) {
            this.updateCheckoutErrorFields(errorFields);

            // reject() the promise that we've returned, because errors.
            reject(new Exceptions.CheckoutValidation(
                'Missing a required field'
            ));
        }

        resolve(true);
    });
}

export function completeStep(step)
{
    // let state = this.state;
    let initPaymentProcessor = false;
console.log('comlete step');
    let data = {};

    switch (step) {
        case 'billing':
            data = {
                active: 'shipping',
                completed: {
                    billing: true,
                }
            };
            // state.active            = 'shipping';
            // state.completed.billing = true;
            break;
        case 'shipping':
            data = {
                active: 'payment',
                completed: {
                    shipping: true,
                }
            };
            // state.active             = 'payment';
            // state.completed.shipping = true;
            initPaymentProcessor     = true;
            break;
        case 'payment':
            data = {
                active: 'confirmation',
                completed: {
                    payment: true,
                }
            };
            break;
    }

    return new Promise((resolve) => {
        storeDispatch(
            storeTypes.CART.CHECKOUT_UPDATE_STAGE,
            data,
            resolve()
        );
    });
}

export function submitOrder()
{
  console.log('SUBMITING ORDER >>');
    DISH.layout.curtainOpen();

    let cartToken    = this.token();
    // let _checkout = storeGet('cartState.checkout');
    // let _ccToken  = storeGet('cartState.ccToken.token');
    const billingInfo  = getCustomerShippingInfo('customer');
    const shippingInfo = getCustomerShippingInfo('shipping');

    return DISH.requests.POST(`/cart/${cartToken}/checkout`, {
        data: {
            customer_firstName:   billingInfo.firstName,
            customer_lastName:    billingInfo.lastName,
            customer_email:       billingInfo.email,
            customer_phone:       billingInfo.phone,
            customer_address1:    billingInfo.address1,
            customer_address2:    billingInfo.address2,
            customer_city:        billingInfo.city,
            customer_province:    billingInfo.province,
            customer_postalCode:  billingInfo.postalCode,
            customer_country:     billingInfo.country,
            // customer_cc_token:    _ccToken.id,
            // customer_cc_last4:    _ccToken.card.last4,
            // customer_cc_expMonth: _ccToken.card.exp_month,
            // customer_cc_expYear:  _ccToken.card.exp_year,
            shipping_firstName:   shippingInfo.firstName,
            shipping_lastName:    shippingInfo.lastName,
            shipping_email:       shippingInfo.email,
            shipping_phone:       shippingInfo.phone,
            shipping_address1:    shippingInfo.address1,
            shipping_address2:    shippingInfo.address2,
            shipping_city:        shippingInfo.city,
            shipping_province:    shippingInfo.province,
            shipping_postalCode:  shippingInfo.postalCode,
            shipping_country:     shippingInfo.country,
        }
    })
    .catch(error => {
        alert('uh oh, errors: ' + error);
    })
    .then(response => {
        const orderToken = response.data.data.orderToken;

        DISH.layout.curtainClose();
        // Remove the current cart token from local storage.
        DISH.cart.token(false);
        // Generate a new cart token so that there won't be any mixup with
        // subsequent orders after a successful checkout.
        return DISH.cart.generateToken(true)
          .then(response => {
            return new Promise(resolve => {
              storeDispatch(
                storeTypes.CART.EMPTY,
                {},
                () => resolve(orderToken)
              );
            });
          })
          // .then(response => {
              // DISH.framework.redirect(history, 'order.thanks', {
              //     orderToken: orderToken,
              // });
          // });
    });
}

/**
 * Gets the Customer or Shipping info, depending on which is being asked for. If
 * the current user is set to do invoice purchases, the information is obtained
 * from localStorage. Otherwise, it comes from the storage, which user will have
 * entered in the checkout form.
 *
 * @param {string} key
 */
function getCustomerShippingInfo(key = null)
{
    if (DISH.framework.getSettings('checkout.invoicePurchase') === true) {
        const settings = DISH.framework.getSettings(key);

        return {
            firstName:  settings.firstName,
            lastName:   settings.lastName,
            email:      settings.email,
            phone:      settings.phone,
            address1:   settings.address1,
            address2:   settings.address2,
            city:       settings.city,
            province:   settings.province,
            postalCode: settings.postalCode,
            country:    settings.country,
        }
    }

    const _checkout = storeGet('cartState.checkout');

    return {
        firstName:  _checkout[`${key}_firstName`],
        lastName:   _checkout[`${key}_lastName`],
        email:      _checkout[`${key}_email`],
        phone:      _checkout[`${key}_phone`],
        address1:   _checkout[`${key}_streetAddress1`],
        address2:   _checkout[`${key}_streetAddress2`],
        city:       _checkout[`${key}_city`],
        province:   _checkout[`${key}_province`],
        postalCode: _checkout[`${key}_postalCode`],
        country:    _checkout[`${key}_country`],
    };
}
