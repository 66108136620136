'use strict';

import React from 'react';

class TandC extends React.Component {
    render()
    {
        return (
            <article>
                <h1>Conditions of Use</h1>

                <h2>TERMS OF SERVICE AGREEMENT</h2>

                <h3>ASSENT TO TERMS AND AMENDMENTS</h3>

                <p>Screaming Entertainment, along with its successors, assigns, subsidiaries, affiliates, officers, employees, agents, partners and licensors/licensees ( collectively "Company" or “we” or “us” or “our”), provide the distribution services (as defined below) to you subject to the following terms of service (these "TERMS"), all of which you acknowledge, agree to and consent to by using the service. These TERMS include the Privacy Policy, which is hereby incorporated by reference.</p>

                <p>These TERMS may be amended by Company from time to time without notice to you. You will periodically review the controlling version of these TERMS, and by continuing to use the Service subsequent to Company making available an amended version of these TERMS, you thereby acknowledge, agree and consent to such amendment.</p>

                <p>You consent to entering these TERMS electronically by means of electronic signature, and to Company storing records related to your contracts with Company in electronic form. You also represent that you are of legal age to form this binding contract and are not a person barred from receiving services under the laws of the applicable jurisdiction.</p>

                <p>When using the Service, you shall be subject to any posted rules or policies, which may be posted from time to time by Company. Such rules and policies are hereby incorporated by reference into these TERMS. Company may also offer other services that are governed by different terms of service.</p>

                <h3>SERVICE DESCRIPTION</h3>

                <p>Company provides users with access to communications tools, which may heretofore or hereafter be accessed through any medium or device now known or hereafter devised, including but not limited to websites and applications that deliver information and/or enable purchase orders through its network of properties (the "Service").</p>

                <p>The Service may include advertisements and certain communications from Company, including without limitation promotional marketing emails. When you complete the Service’s registration process, you are indicating that you wish to receive such communications. If you do not wish to receive promotional marketing communications from Company, please email us at: store@screamingentertainment.com</p>

                <p>Unless explicitly stated otherwise, any new tools that alter or improve the current Service shall be subject to these TERMS.</p>

                <p>Please be aware that you must be at least 13 years of age to access and use the Service. If you are not at least 13 years of age, please do not attempt to access the Service again until you reach the age of 13.</p>

                <h3>USERNAME AND PASSWORD</h3>

                <p>You will create a “username” upon completing the Service's registration process. You will provide accurate, current and complete information about yourself (the "Information") as prompted by the Service's registration forms and timely update the Information to keep it true, accurate, current and complete. You are responsible for maintaining the confidentiality of the password and username and are fully responsible for all activities that occur under your password or username. You will immediately notify Company of any unauthorized use of your password or username or any other breach of security. Company will not be liable or responsible for any claim arising from your failure to comply with this paragraph.</p>

                <h3>THE CONTENT YOU SUBMIT</h3>

                <p>You understand that all Information, data, or content of any kind, uploaded, posted, made available, shared or transmitted by you or another user to or through the Service ("Content") is the sole responsibility of the person from whom such Content originated. Company does not control such Content and does not make any guarantee whatsoever related to such Content. By using the Service, you may be exposed to Content, as well as material uploaded, posted, made available, shared or transmitted to or through the Service by Company, that is offensive, indecent or objectionable. Under no circumstances will Company be liable or responsible in any way for any claim related to such Content or material.</p>

                <p>You grant Company a royalty-free, irrevocable, sublicensable and non-exclusive perpetual license throughout the universe for use in any and all media whether now known or hereafter devised to use and exploit (including by reproduction, distribution, public display, adaptation and/or public performance) any and all Content that you post, make available or transmit to or though the Service. You also waive all moral rights in relation to such Content.</p>

                <p>If any of the Content that you upload, post, make available, share or transmit to or though the Service contains ideas, suggestions, documents, and/or proposals to Company, Company will have no obligation of confidentiality, express or implied, with respect to such Content, and Company shall be entitled to use, exploit or disclose (or choose not to use or disclose) such Content at its sole discretion without any obligation of Company to you whatsoever (i.e., you will not be entitled to any compensation or reimbursement of any kind from Company under any circumstances).</p>

                <h3>PRICING POLICY</h3>

                <p>Items in your Shopping Cart will always reflect the most recent price displayed on the item's product detail page. Please note that this price may differ from the price shown for the item when you first placed it in your cart. Placing an item in your cart does not reserve the price shown at that time. It is also possible that an item's price may decrease between the time you place it in your cart and the time you purchase it.</p>

                <p>Some discounts are limited-time offers. Also, as the discount we are able to offer for any item is dependent upon its availability, the Site’s price will necessarily change on occasion.</p>

                <p>With respect to items sold, Company cannot confirm the price of an item until you order. Despite our best efforts, a small number of the items in our catalog may be incorrectly priced. If Company discovers an incorrect price, it will do one of the following:</p>

                <ul>
                    <li>If an item's correct price is lower than our stated price, Company will charge the lower amount and ship you the item. If an item's correct price is higher than stated price, Company will, at its discretion, either contact you for instructions before shipping or cancel your order and notify you of such cancellation.</li>
                </ul>

                <h3>PROCEDURE FOR MAKING CLAIMS OF COPYRIGHT INFRINGEMENT</h3>

                <p>If you have a good faith belief that your copyright is being infringed by any material on the Service, please send a Notice of Claimed Infringement, including the information listed below, to Company’s Designated Copyright Agent: Designated Copyright Agent: Isotope Music Inc.</p>

                <p>Agent address: 80 Shorting Rd, Toronto ON CANADA  M1S 3S4</p>

                <p>Phone: (647)947-1252</p>

                <p>Email: store@screamingentertainment.com</p>

                <p>Company has instituted a policy designed to enable, at Company’s sole discretion, the expeditious removal of infringing material and the termination of the usernames and access of users who qualify as repeat infringers. Please make sure that any Notice of Claimed Infringement you send to Company’s Designated Copyright Agent includes all of the following:</p>

                <ul>
                    <li>an identification of the copyrighted work claimed to have been infringes, or, if multiple copyrighted works are covered by a single notification, a representative list of such works;</li>
                    <li>an identification of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit Company to locate the material;</li>
                    <li>information reasonably sufficient to permit Company to contact you, such as an address, telephone number, and, if available, an electronic mail address at which you maybe contacted.</li>
                    <li>a statement that the you have a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law.</li>
                    <li>a statement that the information in the notification is accurate, and under penalty of perjury, that you are authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.</li>
                </ul>

                <p>Company has also instituted a Counter Notification policy designed to enable, at Company’s sole discretion, the prompt replacement of material that is removed in response to a Notice of Claimed Infringement as a result of a mistake of misidentification. Please make sure that any Counter Notification you send to Company’s Designated Copyright Agent includes all of the following:</p>

                <ul>
                    <li>your physical or electronic signature;</li>
                    <li>an identification of the material that has been removed or to which access has been disabled and the location at which the material appeared before it was removed or access to it was disabled;</li>
                    <li>a statement under penalty of perjury that you have a good faith belief that the material was removed or disabled as a result of mistake or misidentification of the material that was removed or disabled;</li>
                    <li>your name, address, and telephone number, and a statement that you consent to the jurisdiction of Federal District Court for the judicial district in which your address is located, or if your address is outside of the United States, for any judicial district in which Company may be found, and that you will accept service of process from the person who provided the Notice of Claimed Infringement or an agent of such person.</li>
                </ul>

                <h3>TRADEMARKS</h3>

                <p>Screaming Entertainment and their associated logos are trademarks and/or service marks of Company. All other trademarks, service marks and logos used on or through the Service are the trademarks, service marks or logos of their respective owners.</p>

                <h3>YOUR CONDUCT</h3>

                <p>You will not use the Service to:</p>

                <ul>
                    <li>violate any applicable law or encourage or provide instructions to another to do so;</li>
                    <li>upload, post, make available, share or transmit any Content that is unlawful (including without limitation Content that infringes or violates any patent, trademark, trade secret, copyright, right of publicity or other right), confidential, harmful, threatening, abusive, inflammatory, harassing, tortuous, defamatory, vulgar, obscene, libelous, pornographic, invasive of another's privacy, or racially, ethnically, or otherwise objectionable;</li>
                    <li>misrepresent your identity;</li>
                    <li>upload, post, make available, share or transmit any Content containing unsolicited or unauthorized advertising, promotional materials, spam, junk mail, chain letters, pyramid schemes or any other form of solicitation;</li>
                    <li>upload, post, make available, share or transmit any Content that contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment;</li>
                    <li>act in a manner that negatively affects other users' ability to use the Service;</li>
                    <li>harass, intimidate or stalk anyone;</li>
                    <li>collect or store personal data about anyone; and/or</li>
                    <li>reproduce (other than as necessary to lawfully access the Service), adapt, distribute, display or perform any portion of the Service or Content or material residing on the Service at the direction of Company or a user other than yourself, in whole or in part.</li>
                </ul>

                <p>Any interaction you have with advertisers found on or through the Service, including payment and delivery of related goods or services, and any other terms, conditions, warranties or representations associated with such dealings, are solely between you and such advertiser.</p>
            </article>
        )
    }
};

export default TandC;
