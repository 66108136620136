import React            from 'react';
import Page             from 'components/Layouts/Page';
import ShopSearch       from 'components/Shop/Search';
import SearchListing    from 'components/Shop/Search/SearchListing';

export default function Front(props)
{
  return (
    <Page>
      <div className="columns is-multiline">
        <div className="column is-10 is-offset-1 pt-4 pb-6">
          <ShopSearch
            instantInputSearch={false}
            quickSearchResults={false}
          />
        </div>
        <div className="column is-12">
          <SearchListing />
        </div>
      </div>
    </Page>
  )
}
