'use strict';

import React from 'react';

class ControlDescription extends React.Component {
    render()
    {
        if (typeof this.props.description === 'undefined' ||
            this.props.description === null ||
            this.props.description === ''
        ) {
            return null;
        }

        return (
            <span className="description">{this.props.description}</span>
        )
    }
};

export default ControlDescription;
