'use strict';

export function CheckoutValidation(message)
{
    this.message = message;
    this.type    = 'CheckoutValidationException'
}

export function CheckoutStripe(data)
{
    this.message = data.message;
    this.error   = {
        code: data.code,
        type: data.type,
    };
}
