import deepmerge from 'deepmerge';

window.axios = require('axios');
let axiosHeaders = {
    'Content-type':  'application/json',
    'Accept':        'application/json',
    'Authorization': `Bearer ${process.env.DILATED_TOKEN}`,
};
let session = localStorage.getItem('sessionToken');
if (session !== null) {
    axiosHeaders['X-Authentication'] = `Bearer ${session}`;
}
window.axios.defaults.headers.common = axiosHeaders;
window.axios.interceptors.response.use(function (response) {
    if (response.status === 202) {
        document.location = response.data.redirect;
    }

    return response;
}, function (error) {
    // Do something with response error
    return Promise.reject(error);
});

const defaultOptions = {
    api:    process.env.DILATED_API,
    params: {},
    crossDomain: true,
};

export function REQUEST(options = {})
{
    return window.axios(options);
}

export function GET(location, options = {})
{
    options = deepmerge(defaultOptions, options);

    return window.axios.get(
        options.api+location,
        options
    );
}

export function DELETE(location, options = {})
{
    options = deepmerge(defaultOptions, options);

    return window.axios.delete(
        options.api+location,
        options
    );
}

export function PUT(location, options = {})
{
    options = deepmerge(defaultOptions, options);

    return window.axios({
        method: 'put',
        url:    options.api+location,
        data:   options.data || {}
    });
}

export function POST(location, options = {})
{
    options = deepmerge(defaultOptions, options);

    return window.axios({
        method: 'post',
        url:    options.api+location,
        data:   options.data || {}
    });
}
