import React from 'react';

export default function DetailsHeader(props)
{
  const { active, confirmation, editStep, section } = props;

  if (!active) {
    return null;
  }

  return (
    <h1 className={props.classBase+'--header'}>
      {section} Details
      <EditLink confirmation={confirmation} onClick={editStep} />
    </h1>
  )
}

function EditLink(props) {
  const { confirmation, onClick } = props;

  if (!confirmation) {
    return null;
  }

  return (
    <span
      className="text-small link"
      onClick={onClick}
    >
      Edit
    </span>
  )
}
