import React, { useEffect } from 'react';
import { useLocation }      from 'react-router';
import ShopSearchFields     from 'components/Shop/Search/SearchFields';
import SearchListing        from 'components/Shop/Search/SearchListing';
import SearchQuickResults   from 'components/Shop/Search/SearchQuickResults';
import { useSearch }        from 'providers/SearchProvider';

export default function ShopSearch(props)
{
  const { doSetOptionsFromParams, isSearching } = useSearch();
  const location = useLocation();

  const classNames = ['columns', 'is-multiline'];

  if (isSearching) {
    classNames.push('searching');
  }

  useEffect(() => {
    doSetOptionsFromParams(location)
  }, [location]);

  return (
    <article className={classNames.join(' ')}>
      <div className="column is-12">
        <ShopSearchFields {...props} />
      </div>
      <div className="column is-10 is-offset-1">
        <SearchQuickResults {...props} />
      </div>
    </article>
  )
}

function SearchResults(props)
{
  const { renderSearchResults } = props;

  if (typeof renderSearchResults !== 'undefined' &&
      renderSearchResults === false
  ) {
    return null;
  }

  return <SearchListing />
}
