import React, { useState } from 'react'
import * as DISH           from 'api/dilatedShop';
import config              from 'config/config';

const ShopContext = React.createContext(undefined);

export const ORIGIN_CATEGORY = 'category';
export const ORIGIN_GENRE = 'genre';

export const KEY_DEFAULT = 'default';
export const KEY_FEATURED_ITEMS = 'featuredItems';

export default function ShopProvider(props)
{
  const [pagination, setPagination] = useState({});
  const [links, setLinks] = useState({});
  const [inventory, setInventory] = useState({});

  const [currentAlbumMeta, setCurrentAlbumMeta] = useState({});
  const [currentAlbum, setCurrentAlbum] = useState(null);

  const getInventory = (
    origin,
    categoryId,
    genreId = null,
    page = 1,
    perPage = null,
    inventoryKey = null
  ) => {
    perPage = perPage === null ?
      config.inventory.defaultPerPage :
      perPage;

    inventoryKey = inventoryKey === null ?
      KEY_DEFAULT :
      inventoryKey;

    // If the origin is the category page, we need to make sure that there is a
    // categoryId passed in so we can display the right content and not have an
    // FoC (Flash of Content).
    if (origin == ORIGIN_CATEGORY && categoryId === null) {
      return null;
    }

    // If the origin is the genre page, we need to make sure that there is a
    // genreId passed in so we can display the right content and not have an FoC
    // (Flash of Content).
    if (origin === ORIGIN_GENRE && genreId === null) {
      return null;
    }

    return DISH.requests.GET('/inventory', {
      params: {
        page:       page,
        size:       perPage,
        categoryId: categoryId,
        genreId:    genreId,
      }
    })
    .then(response => {
      return new Promise((resolve, reject) => {
        setPagination(current => Object.assign(
          {},
          current,
          {[inventoryKey]: response.data._meta}
        ));

        setLinks(current => Object.assign(
          {},
          current,
          {[inventoryKey]: response.data._links}
        ));

        setInventory(current => Object.assign(
          {},
          current,
          {[inventoryKey]: response.data.data}
        ));

        resolve(response);
      });
    });
  }

  const getAlbum = (artistSlug, albumSlug, force = false) => {
    // If what we've been passed for an artistSlug or an albumSlug are false, we
    // don't need to get anything from storage, we'll get out.
    if (artistSlug === false || albumSlug === false) {
      return null;
    }

    // If the passed in artist slug AND album slug are what we already have stored
    // in context AND we're being forced to fetch, we'll get out.
    if ((!artistSlug || artistSlug === currentAlbumMeta.artistSlug) &&
      (!albumSlug || albumSlug === currentAlbumMeta.albumSlug) &&
      force === false
    ) {
      return null;
    }

    return DISH.requests.GET('/artists/'+artistSlug+'/albums/'+albumSlug)
      .then(response => {
        setCurrentAlbumMeta({
          artistSlug: artistSlug,
          albumSlug: albumSlug,
        });

        setCurrentAlbum(response.data.data[0]);
      });
  }

  const clearAlbum = () => setCurrentAlbum(null);

  const categoryInventory = (categoryId, options = {}) => {
    return getInventory(
      ORIGIN_CATEGORY,
      categoryId,
      null,
      options.page || 1,
      options.perPage || null,
      options.inventoryKey || null
    );
  }

  return (
    <ShopContext.Provider
      value={{
        categoryInventory,
        clearAlbum,
        currentAlbum,
        getAlbum,
        getInventory,
        inventory,
        links,
        pagination,
      }}
    >
      {props.children}
    </ShopContext.Provider>
  )
}

export function useShop() {
  const context = React.useContext(ShopContext)

  if (context === undefined) {
    throw new Error('useShop must be used within a ShopProvider')
  }

  return context
}
