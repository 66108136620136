'use strict';

import React        from 'react';
import PropTypes    from 'prop-types';
import { connect }  from 'react-redux';

const classBase = 'container-hero-carousel';

class Carousel extends React.Component {
    constructor(props)
    {
        super(props);

        this.doActivateTile = this._doActivateTile.bind(this);

        this.state = {
            active: -1,
        };
    }

    componentDidMount()
    {
        this._setSlideInterval();
        this._doAdvanceTile();
    }

    componentWillUnmount() {
        // Clearing the slide interval when this component is unmounted, so we
        // don't get nonsense setState() related errors.
        this._clearSlideInterval();
    }

    render()
    {
        return (
            <article className={classBase}>
                {this._displaySlider()}
                {this._displayIndicators()}
            </article>
        )
    }

    _displaySlider()
    {
        return (
            <div className={classBase+'--slider'}>
                {this.props._items.map((item, key) => {
                    return (
                        <div
                            key={key}
                            className={this._getClassNames(key, 'slider-tile')}
                            style={{
                                backgroundImage: 'url('+item.img+')',
                                backgroundPosition: item.position.horizontal+' '+item.position.vertical
                            }}
                        />
                    )
                })}
            </div>
        )
    }

    _displayIndicators()
    {
        return (
            <div className={classBase+'--indicators'}>
                {this.props._items.map((item, key) => {
                    return (
                        <div
                            key={key}
                            className={this._getClassNames(key, 'indicators-indicator')}
                            onClick={this.doActivateTile.bind(this, key)}
                        />
                    )
                })}
            </div>
        )
    }

    _getClassNames(tile, which)
    {
        let classNames = [classBase+'--'+which];

        if (this.state.active == tile) {
            classNames.push('active');
        }

        return classNames.join(' ');
    }

    _clearSlideInterval()
    {
        clearInterval(window.heroCarouselInterval);
    }

    _setSlideInterval()
    {
        this._clearSlideInterval()

        window.heroCarouselInterval = setInterval(() => {
            this._doAdvanceTile();
        }, 8000);
    }

    _doAdvanceTile()
    {
        let tile = this.state.active;

        tile = Number(tile) + 1;

        if (tile > Number(this.props._items.length) - 1) {
            tile = 0;
        }

        this._doActivateTile(tile);
    }

    _doActivateTile(tile)
    {
        this.setState({
            active: tile,
        });

        this._setSlideInterval();
    }
};

const mapStateToProps = function(store)
{
    return {
        _items: store.layoutState.hero.carousel.items,
    };
}

export default connect(mapStateToProps)(Carousel);
