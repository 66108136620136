import * as Cart  from 'components/Pages/Cart';
import Home  from 'components/Pages/Home/Home';
import Login from 'components/Pages/Login/Login';
import Order from 'components/Pages/Order';
import Shop  from 'components/Pages/Shop/Shop';

export default {
    Cart,
    Home,
    Login,
    Order,
    Shop,
};
