import * as basePages  from 'BASE/components/Pages';
import Album           from 'components/Pages/Shop/Album';
import PrivacyPolicy   from 'components/Pages/PrivacyPolicy';
import ShippingReturns from 'components/Pages/ShippingReturns';
import TandC           from 'components/Pages/TandC';
import Shop            from 'components/Pages/Shop/Front';
import Search          from 'components/Pages/Search/Front';
import deepmerge       from 'deepmerge';

export default deepmerge(basePages.default, {
    Album,
    PrivacyPolicy,
    Search,
    ShippingReturns,
    Shop,
    TandC,
});
