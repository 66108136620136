import React from 'react';

export default function SearchQuickResults(props)
{
  const { quickSearchResults } = props;
  if (typeof quickSearchResults !== 'undefined' &&
      quickSearchResults === false
  ) {
    return null;
  }

  return (
    <div>resulst!!!</div>
  )
}
