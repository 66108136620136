import { storeGet } from 'store/createStore';
import { getRoute } from 'routes/utilities';
import Oops         from 'components/Pages/Oops';
import * as DISH    from 'api/dilatedShop';
import { useAuth }  from 'providers/AuthProvider';
import useRedirect  from 'routes/useRedirect';

// export function initialize()
// {
//   const { doInit } = useAuth();

//   // Get (or generate) the cart token - We pass this as an optional parameter
//   // to the initialization of the Channel from the backend. This request is a
//   // unifying request, that returns a bunch of useful data in one response. The
//   // generateToken() method first tries to get a token out of localstorage, if
//   // one isn't present, a request to the backend to generate a new one is made.
//   // At this point, we should always have a token.
//   return DISH.cart.generateToken(true)
//     .then(cartToken => {
//       return DISH.requests.GET('/channels/initialize', {
//         params: {
//           cartToken: cartToken,
//         }
//       });
//     })
//     .then(initResponse => {
//       // Check if this channel requires a user authentication before showing
//       // any pages/content.
//       if (requiresLogin() === true) {
//         DISH.user.sessionInit();
//       }

//       return initResponse;
//     })
//     .then(initResponse => {
//       return DISH.cart.setCartStore(initResponse.data.data.cart)
//         .then(() => { return initResponse; });
//     })
//     .then(initResponse => {
//       return new Promise(resolve => {
//         setSettings(initResponse.data.data.settings);
//         resolve(initResponse);
//       });
//     });
// }

// export function requiresLogin()
// {
//   return process.env.REQUIRES_LOGIN;
// }

// function setSettings(settings = null)
// {
//   if (settings === null) {
//     return;
//   }

//   return localStorage.setItem('channelSettings', JSON.stringify(settings));
// }

// export function getSettings(settingsKey = null)
// {
//   const settings = JSON.parse(localStorage.getItem('channelSettings'));

//   // If there are no settings to process, null outta here.
//   if (settings === null) {
//     return null;
//   }

//   // If we don't have a setttingsKey to return for, just return ALL of the
//   // settings we got out of storage.
//   if (settingsKey === null) {
//     return settings;
//   }

//   // If there is no . (dot) in the settingsKey value, we're being asked for a
//   // first-class citizen of the settings. Easy...
//   if (settingsKey.includes('.') === false) {
//     return settings[settingsKey];
//   }

//   let keys = settingsKey.split('.');
//   let data = settings;

//   // Iterate through our keys to try and get the data for the deep-nested location
//   // we've been asked for.
//   for (let i=0; i<keys.length; i++) {
//     // If there is no element at the key we're iterating, we're not going to
//     // be able to set anything or go deeper, so return null, we're done.
//     if (data.hasOwnProperty(keys[i]) === false) {
//       return null;
//     }

//     // Set our local value to the settings attribute we're currently on, so
//     // we can get deeper as we go.
//     data = data[keys[i]];
//   }

//   return data;
// }

// /**
//  * Get/Set the session token from/in localStorage. This token is used to identify
//  * the user's authenticated session.
//  *
//  * @param {string} token
//  * @param {boolean} create
//  */
// export function session(token = null)
// {
//   // If the token value is null, requester wants the value, so return the value.
//   if (token === null) {
//     return localStorage.getItem('sessionToken');
//   }

//   // When the token value is false, we're removing the session in storage.
//   if (token === false) {
//     return localStorage.removeItem('sessionToken');
//   }

//   // Here means we've been given a token value to save in localStorage.
//   localStorage.setItem('sessionToken', token);

//   // Return a Promise for chaining... Resolving with the session token.
//   return new Promise((resolve, reject) => {
//     resolve(localStorage.getItem('sessionToken') || null);
//   });
// }

// /**
//  * Supplied with an array of routes, each one is iterated to add an "auth"
//  * attribute to the route definition.
//  *
//  * @param {array} routes
//  */
// export function authenticatedRoutes(routes)
// {
//   let authedRoutes = [];

//   // Map each route in the array so we can apply the "auth: true" attribute to
//   // it. The router is looking for this attribute to be set, along with a
//   // session token, to determine if the request is ok, or needs to be authenticated.
//   routes.map((route, key) => {
//     // Have to make sure there's no "doNotAuth: true" attribute set. There
//     // may be reasons that a frontend's route should not be authed.
//     if (typeof route.doNotAuth === 'undefined' ||
//       route.doNotAuth !== true
//     ) {
//       route['auth'] = true;
//     }

//     // Our current route may have children defined (more routes), so we're
//     // getting recursive with the children.
//     if (typeof route.children !== 'undefined') {
//       route.children = authenticatedRoutes(route.children);
//     }

//     // Push the newly auth-enabled route into our processed array, to be
//     // returned at the end.
//     authedRoutes.push(route);
//   });

//   return authedRoutes;
// }

// export function login(user, password)
// {
//   DISH.requests.POST('/login', {
//     data: {
//       email:   user,
//       password:  password,
//       cartToken: DISH.cart.token(),
//     }
//   })
//     .then(response => {
//       // Here means we've been given tokens to save in localStorage.
//       DISH.framework.session(response.data.data.sessionToken);
//       DISH.cart.token(response.data.data.cartToken);

//       return window.location.href = '/';
//     })
//     .catch(error => {
//       console.log('error >>', error);
//     });
// }

/**
 * Resolves a component from the route that is being requested, out of the array
 * of components supplied. Will loop through the route name, segmenting off dots
 * until either a component is matched in the array, or it runs out of segments
 * and returns an error.
 *
 * @param {strign} route
 * @param {array} components
 */
export function resolveRouteComponent(route, components)
{
  let oopsComponent = {
    component: Oops,
    props:   { route: route },
  };

  // Attempt to resolve the route's name, we need this to be able to figure out
  // which component to resolve (based on what was passed in as an array of
  // possible components).
  let segment = route ?
    route.name :
    undefined;

  let originalSegment = segment;
  let segmentParts = [];

  // Check the components we've been supplied against the segment we're working
  // with to see if there is a matching definition for the route name that has
  // been requested. We'll loop through and pop one element off the end of the
  // segment until we either find a define component or we're out of segments
  // to check.
  while (
    typeof components[segment] === 'undefined' &&
    segment !== false
  ) {
    // Turn the dot-notated segment into an array, splitting at . (dot) so we
    // can pop one element off the end and join it back together to be a
    // string and re-check the segment against the components we've been
    // supplied.
    segmentParts = segment.split('.');
    segmentParts.pop();
    segment = segmentParts.join('.');

    // If segment is an empty string, we've looped through all elements of
    // the segment and we didn't find anyting. We'll have to set the segment
    // variable to false so we don't infinitely loop.
    segment = segment !== '' ?
      segment :
      false;
  }

  // If what we've resolved out of the route name doesn't exist in the array of
  // components we've been given, error out.
  if (segment === false) {
    console.error(
      'Farts, no defined components for route name',
      originalSegment,
      components
    );

    return oopsComponent;
  }

  // If we have a key for the segment in the supplied array of components, but
  // it doesn't have a component specified to return, error out.
  if (typeof components[segment].component === 'undefined') {
    console.error('Farts, no component.component specified for ' + segment, components);
    return oopsComponent;
  }

  // Here we're all good. Return an object with keys for the component and props.
  // If the props have not been supplied, return an empty object, createElement()
  // likes that more than nothing.
  return {
    component: components[segment].component,
    props:   components[segment].props || {}
  };
}

// export function stateReady(property, zeroLengthOk = false) {
//   let thing = storeGet(property);

//   if (typeof thing === 'undefined') {
//     return false;
//   }

//   if (
//     zeroLengthOk === false &&
//     thing.length === 0
//   ) {
//     return false;
//   }

//   return true;
// }

/**
 * Redirects to a route that has been defined in the application's router.
 *
 * @param {string} route
 * @param {object} params
 * @param {object} options
 */
export function redirect(
  navigate,
  routeName,
  routeParams = {}
) {
  const redirect = useRedirect()

  redirect(routeName, routeParams);
  // navigate(getRoute(routeName, routeParams))
}
