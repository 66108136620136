import React            from 'react';
import AddressDisplay   from 'components/Utilities/AddressDisplay';
import SectionHeader    from 'components/Utilities/SectionHeader';
import { formatPrice }  from 'lib/formatters';
import { useFramework } from 'providers/FrameworkProvider';

export default function OrderInformation(props)
{
  const { order, order: { token } } = props;
  const { getSettings } = useFramework();

  if (token === null) {
    return null;
  }

  const paymentType = getSettings('checkout.invoicePurchase') === true ?
    'Invoice' :
    'Credit Card';

  return (
    <section className="container-order--information">
      <div className="information--shipping">
        <SectionHeader>Shipping Address</SectionHeader>
        <article>
          <AddressDisplay
            phone={false}
            addressObject={order.destination}
          />
        </article>
      </div>
      <div className="information--payment">
        <SectionHeader>Payment Method</SectionHeader>
        <article>
          <div className="payment--type">{paymentType}</div>
        </article>
      </div>
      <div className="information--summary">
        <SectionHeader>Order Summary</SectionHeader>
        <article>
          <label>Items:</label>
          <span> {order.items_count}</span>

          <label>SubTotal:</label>
          <span> $ {formatPrice(order.amounts.sub_total)}</span>

          <label>Shipping:</label>
          <span> $ {formatPrice(order.amounts.shipping)}</span>

          <label>Tax:</label>
          <span> $ {formatPrice(order.amounts.tax)}</span>

          <label>Total:</label>
          <span> $ {formatPrice(order.amounts.total)}</span>
        </article>
      </div>
    </section>
  )
}
