import React          from 'react';
import ButtonNext     from 'components/Checkout/ButtonNext';
import ButtonPrevious from 'components/Checkout/ButtonPrevious';
import {
  countries,
  labels,
  states,
}                     from 'config/geo';
import {
  FORM_BILLING,
  FORM_SHIPPING
}                     from 'providers/CartProvider'
import {
  useForm,
  Checkbox,
  Input,
  Select
}                     from 'providers/FormProvider'
import { useEffect } from 'react';

export default function DetailsForm(props)
{
  const { formId, nextClick, previousClick } = props;
  const { currentForm, formChangeHandler, formError, formSetValues, formValue } = useForm();

  useEffect(() => {
    // if (formId === FORM_BILLING) {
    //   formSetValues({
    //     firstName: 'billy',
    //     lastName: 'bompson',
    //     email: 'billy@bompson.com',
    //     country: 'CA',
    //     streetAddress1: '40 Some Street',
    //     city: 'Toronto',
    //     province: 'ON',
    //     postalCode: 'M4M4M4',
    //     phone: '4445556666',
    //     acceptTC: true,
    //   }, FORM_BILLING);
    // }

    // if (formId === FORM_SHIPPING) {
    //   formSetValues({
    //     sameShipping: true,
    //   }, FORM_SHIPPING);
    // }
  }, [formId]);


  let detailsFormId = FORM_BILLING;
  if (currentForm === FORM_SHIPPING && !formValue('sameShipping')) {
    detailsFormId = FORM_SHIPPING;
  }

  const isDisabled = formId === FORM_SHIPPING &&
    formValue('sameShipping') === true;

  return (
    <>
      <ShippingSameAsBilling formId={formId} />

      <div className="column is-6">
        <Input
          disabled={isDisabled}
          errorMessage={formError('firstName')}
          label="First Name"
          name="firstName"
          onChange={formChangeHandler}
          required
          value={formValue('firstName', detailsFormId)}
        />
      </div>
      <div className="column is-6">
        <Input
          disabled={isDisabled}
          errorMessage={formError('lastName')}
          label="Last Name"
          name="lastName"
          onChange={formChangeHandler}
          required
          value={formValue('lastName', detailsFormId)}
        />
      </div>

      <div className="column is-12">
        <Input
          description="This is the Email notifications will be sent to"
          disabled={isDisabled}
          errorMessage={formError('email')}
          label="Email Address"
          name="email"
          onChange={formChangeHandler}
          required
          value={formValue('email', detailsFormId)}
        />
      </div>
      <div className="column is-12">
        <Input
          disabled={isDisabled}
          errorMessage={formError('companyName')}
          label="Company Name"
          name="companyName"
          onChange={formChangeHandler}
          preText="Optional / If applicable"
          value={formValue('companyName', detailsFormId)}
        />
      </div>
      <div className="column is-12">
        <Select
          disabled={isDisabled}
          errorMessage={formError('country')}
          label="Country"
          name="country"
          onChange={formChangeHandler}
          options={countries}
          required
          value={formValue('country', detailsFormId)}
        />
      </div>
      <div className="column is-12">
        <Input
          disabled={isDisabled}
          errorMessage={formError('streetAddress1')}
          label="Street Address"
          name="streetAddress1"
          onChange={formChangeHandler}
          placeholder="Number and Street Name"
          required
          value={formValue('streetAddress1', detailsFormId)}
        />
      </div>
      <div className="column is-12 no-row-gutter">
        <Input
          disabled={isDisabled}
          errorMessage={formError('streetAddress2')}
          name="streetAddress2"
          onChange={formChangeHandler}
          placeholder="Suite, Unit, Apartment (optional)"
          value={formValue('streetAddress2', detailsFormId)}
        />
      </div>
      <div className="column is-6">
        <Input
          disabled={isDisabled}
          errorMessage={formError('city')}
          label="Town / City"
          name="city"
          onChange={formChangeHandler}
          required
          value={formValue('city', detailsFormId)}
        />
      </div>
      <div className="column is-6">
        <Select
          disabled={isDisabled}
          errorMessage={formError('province')}
          label={labels[formValue('country', detailsFormId)].region}
          name="province"
          onChange={formChangeHandler}
          options={states[formValue('country', detailsFormId)]}
          required
          value={formValue('province', detailsFormId)}
        />
      </div>
      <div className="column is-6">
        <Input
          disabled={isDisabled}
          errorMessage={formError('postalCode')}
          label={labels[formValue('country')].mailing}
          maxLength="7"
          name="postalCode"
          onChange={formChangeHandler}
          required
          value={formValue('postalCode', detailsFormId)}
        />
      </div>
      <div className="column is-6">
        <Input
          disabled={isDisabled}
          errorMessage={formError('phone')}
          label="Phone Number"
          name="phone"
          onChange={formChangeHandler}
          required
          value={formValue('phone', detailsFormId)}
        />
      </div>

      <AcceptTC formId={formId} />

      <Buttons nextClick={nextClick} previousClick={previousClick} />
    </>
  )
}

function ShippingSameAsBilling(props) {
  const { formId } = props;
  const { formToggleHandler, formValue, formValues } = useForm();

  if (formId !== FORM_SHIPPING) {
    return null;
  }

  return (
    <div className="column is-12">
      <Checkbox
        label="Ship to the Billing Address"
        name="sameShipping"
        checked={formValue('sameShipping')}
        onChange={formToggleHandler}
      />
    </div>
  )
}

function AcceptTC(props) {
  const { formId } = props;
  const { formError, formToggleHandler, formValue } = useForm();

  // Only want to show the Accept T&C checkbox on the billing details form.
  if (formId !== FORM_BILLING) {
    return null;
  }

  return (
    <div className="column is-12">
      <Checkbox
        className="no-buffer"
        errorMessage={formError('acceptTC')}
        label="I accept the Terms and Conditions"
        name="acceptTC"
        onChange={formToggleHandler}
        required
        checked={formValue('acceptTC', null, false)}
      />
    </div>
  )
}

function Buttons(props) {
  const { nextClick, previousClick } = props;

  if (!previousClick) {
    return (
      <div className="column is-6 is-offset-6">
        <ButtonNext onClick={nextClick} />
      </div>
    )
  }

  return (
    <>
      <div className="column is-6 is-flex is-justify-content-flex-end">
        <ButtonPrevious onClick={previousClick} />
      </div>
      <div className="column is-6">
        <ButtonNext onClick={nextClick} />
      </div>
    </>
  )
}
