import React            from 'react';
import { NavLink }      from 'react-router-dom';
import HeaderNavigation from 'components/Layouts/HeaderNavigation';
import { getRoute }     from 'routes/utilities';

const classBase = 'header-page';

export default function PageHeader(props)
{
  return (
    <header className={classBase}>
      <div className="columns is-vcentered is-multiline is-fullheight">
        <div className="column is-2">
          <div className={classBase+'--content-logo'}>
            <NavLink to={getRoute('home')} />
          </div>
        </div>
        <div className="column is-10 has-text-right">
          <HeaderNavigation />
        </div>
      </div>
    </header>
  )
}
