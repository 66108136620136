import React               from 'react';
import ControlErrorMessage from 'components/Form/ControlErrorMessage';
import ControlLabel        from 'components/Form/ControlLabel';
import * as DISH           from 'api/dilatedShop';

class Select extends React.Component {
    render()
    {
        return (
            <article className={DISH.form.getClassNames(this.props)}>
                <ControlLabel {...this.props} />
                <div className="select-wrap">
                    <select
                        id={DISH.form.getId(this.props)}
                        name={DISH.form.getName(this.props)}
                        onChange={this.props.onChange || null}
                        ref={this.props.refx}
                        value={this.props.value}
                        disabled={this.props.disabled || false}
                    >
                        {this._getFirstEmptyOption()}
                        {this._getOptions()}
                    </select>
                </div>
                <ControlErrorMessage {...this.props} />
            </article>
        )
    }

    /**
     * Checks the props to determine if we need to display an empty option in the
     * first position.
     */
    _getFirstEmptyOption()
    {
        // If this component has the prop firstEmptyOption defined and it's value
        // is false, we'll return null to not display the empty option.
        if (typeof this.props.firstEmptyOption !== 'undefined' &&
            this.firstEmptyOption === false
        ) {
            return null;
        }

        let firstEmptyLabel = '';

        if (typeof this.props.firstEmptyLabel !== 'undefined') {
            firstEmptyLabel = this.props.firstEmptyLabel;
        }

        // Otherwise, blank option.
        return (
            <option
                value=""
                disabled
                defaultValue
            >
                {firstEmptyLabel}
            </option>
        )
    }

    _getOptions()
    {
        if (typeof this.props.options === 'undefined' ||
            this.props.options.length < 1
        ) {
            return null;
        }

        return this.props.options.map((item, key) => {
            return this._getOptionFromItem(item, key);
        });
    }

    _getOptionFromItem(item, key)
    {
        return (
            <option
                key={key}
                value={item.value}
            >
                {item.label
            }</option>
        )
    }
};

export default Select;
