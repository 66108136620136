import React           from 'react';
import ListHeader      from 'components/Utilities/ListHeader';
import { formatPrice } from 'lib/formatters';

export default function OrderListingItem(props)
{
  const { item, header } = props;

  if (typeof header !== 'undefined' && header === true) {
    return (
      <div className="container-orderlistingitems--item">
        <ListHeader className="artist">Artist</ListHeader>
        <ListHeader className="album">Album</ListHeader>
        <ListHeader className="barcode">EAN /Barcode</ListHeader>
        <ListHeader className="format">Format</ListHeader>
        <ListHeader className="quantity textalign-center">Qty</ListHeader>
        <ListHeader className="price textalign-right">Item Price</ListHeader>
        <ListHeader className="total textalign-right">Total Price</ListHeader>
      </div>
    )
  }
console.log('item >>', item);
  return (
    <div className="container-orderlistingitems--item">
      <div className="artist">{item.contributors[0].name}</div>
      <div className="album">
        {`${item.album.title} [${item.album.productionYear}]`}
        <span className="text-small padding-abit--left">({item.album.publisher})</span>
      </div>
      <div className="barcode">{item.barcode}</div>
      <div className="format">{item.format.main.format}</div>
      <div className="quantity textalign-center">{item.quantity}</div>
      <div className="price textalign-right">
        $ {formatPrice(item.price)}
      </div>
      <div className="total textalign-right">
        $ {formatPrice(item.price * item.quantity)}
      </div>
    </div>
  )
}
