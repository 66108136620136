import { useNavigate } from 'react-router-dom';
import { getRoute }    from 'routes/utilities';

export default function useRedirect() {
  const navigate = useNavigate()

  return (routeName, routeParams, query = null) => {
    let queryString = '';

    if (query) {
      const hasQuestionMark = query.charAt(0) === '?';

      queryString = hasQuestionMark ?
        query :
        `?${query}`;
    }

    return navigate(`${getRoute(routeName, routeParams)}${queryString}`)
  }
}
