'use strict';

const cart = {
    CHECKOUT_CLEAR_ERROR_FIELDS:  'cart.checkout_clear_error_fields',
    CHECKOUT_CLEAR_SHIPPING_INFO: 'cart.checkout_clear_shipping_info',
    CHECKOUT_STORE_CC_TOKEN:      'cart.checkout_store_cc_token',
    CHECKOUT_SYNC_SHIPPING_INFO:  'cart.checkout_sync_shipping_info',
    CHECKOUT_UPDATE_ERROR_FIELDS: 'cart.checkout_update_error_fields',
    CHECKOUT_UPDATE_FIELDS:       'cart.checkout_update_fields',
    CHECKOUT_UPDATE_STAGE:        'cart.checkout_update_stage',
    INITIALIZE:                   'cart.initialize',
    EMPTY:                        'cart.empty',
};

const layout = {
    CURTAIN_CLOSE:    'layout.curtain_close',
    CURTAIN_CONTENTS: 'layout.curtain_contents',
    CURTAIN_OPEN:     'layout.curtain_open',
};

const order = {
    UPDATE_ORDER: 'order.update_order',
};

const shop = {
    CGI_CLOSE:             'shop.cgi_close',
    CGI_IDENTIFIER:        'shop.cgi_identifier',
    CGI_TOGGLE:            'shop.cgi_toggle',
    CLEAR_ALBUM_DATA:      'shop.clear_album_data',
    SEARCH_RESULTS_CLOSE:  'shop.search_results_close',
    SEARCH_RESULTS_OPEN:   'shop.search_results_open',
    SET_ACTIVE_ALBUM:      'shop.set_active_album',
    SET_ACTIVE_CATEGORY:   'shop.set_active_category',
    SET_ACTIVE_GENRE:      'shop.set_active_genre',
    UPDATE_ALBUM:          'shop.update_album',
    UPDATE_CATEGORIES:     'shop.update_categories',
    UPDATE_CATEGORY:       'shop.update_category',
    UPDATE_GENRE:          'shop.update_genre',
    UPDATE_GENRES:         'shop.update_genres',
    UPDATE_INVENTORY:      'shop.update_inventory',
    UPDATE_PAGE_META:      'shop.update_page_meta',
    UPDATE_SEARCH:         'shop.update_search',
    UPDATE_SEARCH_RESULTS: 'shop.update_search_results',
    UPDATE_SEARCH_STRING:  'shop.update_search_string',
    UPDATE_SEARCH_OPTIONS: 'shop.update_search_options',
    UPDATE_PAGINATION:     'shop.update_pagination',
}

const storeTypes = {
    CART:   cart,
    LAYOUT: layout,
    ORDER:  order,
    SHOP:   shop,
};
export default storeTypes;

export { cart, layout, order, shop };
