import React       from 'react';
import { NavLink } from 'react-router-dom';

export default function MoreFromLink(props)
{
  return (
    <NavLink
      className="more-from"
      to={{
        pathname: '/search',
        search: `?s=${props.artist}`
      }}
    >
      {`${props.text || 'See More From'} ${props.artist}`}
    </NavLink>
  )
}
