import { useEffect }    from 'react';
import { useAuth }      from 'providers/AuthProvider';
import { useFramework } from 'providers/FrameworkProvider';
import useRedirect      from 'routes/useRedirect';

const SessionTopUp = ({ children }) => {
  const { doTouch, isLoggedIn } = useAuth();
  const { requiresLogin } = useFramework();
  const redirector = useRedirect();

  useEffect(() => {
    doTouch();
  });

  const publicPage = document.location.pathname === '/login' ||
    document.location.pathname === '/logout';

  // If the current Channel requires a login to see the pages/content, if the
  // current request doesn't have an active session AND the current request is
  // NOT a public page, we'll send the user off to the login page.
  if (requiresLogin && !isLoggedIn && !publicPage) {
    // return redirector('login');
    return document.location = '/login';
  }

  return children;
};

export default SessionTopUp;
