import React from 'react';
import Page  from 'components/Layouts/Page';

export default function ShippingReturns(props)
{
  return (
    <Page>
      <div>Isotope: shipping & returns</div>
    </Page>
  )
}
