'use strict';

import React                  from 'react';
import PropTypes              from 'prop-types';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions }            from 'redux-router5';
import * as DISH              from 'api/dilatedShop';
import Button                 from 'components/Utilities/Button';
import { formatPrice }        from 'lib/formatters';

class CartSummary extends React.Component {
    constructor(props)
    {
        super(props);

        this.classBase = this.props.classBase+'--summary';
    }

    render()
    {
        // Don't paint without the _checkout prop.
        if (typeof this.props._checkout === 'undefined') {
            return null;
        }

        return (
            <article className={this.classBase+' margin-standard--bottom grid no-row-gutter'}>
                {this._displayHeader()}
                {this._displaySubTotal()}
                {this._displayShipping()}
                {this._displayTax()}
                {this._displayTotal()}
            </article>
        )
    }

    _displayHeader()
    {
        let grammar = this.props._cart.count === 1 ?
            'Item' :
            'Items';

        return(
            <div className="column-12">
                <h1 className={this.classBase+'--header'}>
                    Cart Summary: {this.props._cart.count} {grammar}
                </h1>
            </div>
        )
    }

    _displaySubTotal()
    {
        return (
            <div className={this.classBase+'--sub-total column-3 textalign-center'}>
                <h2>SubTotal: $ {formatPrice(this.props._cart.subTotal)}</h2>
            </div>
        )
    }

    _displayShipping()
    {
        return (
            <div className={this.classBase+'--shipping column-3 textalign-center'}>
                <h2>Shipping: $ {formatPrice(this.props._cart.shipping)}</h2>
            </div>
        )
    }

    _displayTax()
    {
        return (
            <div className={this.classBase+'--tax column-3 textalign-center'}>
                <h2>Tax: $ {formatPrice(this.props._cart.tax)}</h2>
            </div>
        )
    }

    _displayTotal()
    {
        return (
            <div className={this.classBase+'--sub-total column-3 textalign-center'}>
                <h2>Total: $ {formatPrice(this.props._cart.total)}</h2>
            </div>
        )
    }
};

const mapStateToProps = function(store)
{
    return {
        _checkout:  store.cartState.checkout,
        _cart:      store.cartState,
        _cartItems: store.cartState.items,
    };
}

export default connect(
    mapStateToProps,
    dispatch => bindActionCreators({ navigateTo: actions.navigateTo }, dispatch)
)(CartSummary);
