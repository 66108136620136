import React from 'react';

export default function AddressDisplay(props)
{
    if (Object.keys(props.addressObject).length === 0) {
        return null;
    }

    const { addressObject: data, className } = props;

    return (
        <article
            className={`address-display ${className}`}
        >
            <div className="fullname">{data.firstName} {data.lastName}</div>
            <div className="">{data.address1}</div>
            <div className="">{data.address2}</div>
            <div className="">{`${data.city}, ${data.province} ${data.postalCode}`}</div>
            <div className="">{data.country}</div>
        </article>
    )
}
