import React       from 'react';
import { connect } from 'react-redux';
import * as DISH   from 'api/dilatedShop';
import * as Form   from 'components/Form';
import Button      from 'components/Utilities/Button';

import DetailsHeader from 'components/Checkout/DetailsHeader';
import PaymentDetailsForm from 'components/Checkout/PaymentDetailsForm';
import PaymentDetailsDisplay from 'components/Checkout/PaymentDetailsDisplay';
import StripeProvider       from 'components/Stripe/StripeProvider';
import { formatMonth }      from 'lib/formatters';

class PaymentDetails extends React.Component {


    // constructor(props)
    // {
    //     super(props);

    //     this.state = {
    //         initialied: false,
    //     };
    // }

    // componentDidMount()
    // {
    //     if (this.props._stage.active === 'payment') {
    //         DISH.payments.initialize();
    //     }
    // }
    // static getDerivedStateFromProps(props)
    // {
    //     if (props._stage.active === 'payment') {
    //         DISH.payments.initialize();
    //     }
    //     return null;
    // }

    // componentDidUpdate(props)
    // {
    //     console.log('PAYMENT props >>', props);
    //     if (props._stage.active === 'payment') {
    //         console.log('PAYMENT INIT');
    //         DISH.payments.initialize();
    //     }
    // }

    render()
    {
      if (process.env.REQUIRES_STRIPE === false) {
          return null;
      }

        // Don't paint without the _checkout prop.
        if (typeof this.props._checkout === 'undefined') {
            return null;
        }

        let classBase = this.props.classBase;

        return (
            <article className={this._getClassNames(classBase)}>
                <DetailsHeader
                    section="Payment"
                    step="3"
                    {...this.props}
                />

                <StripeProvider>
                  <PaymentDetailsForm
                      details={this.props._checkout}
                      errors={this.props._errors}
                      stage={this.props._stage}
                  />
                </StripeProvider>

                {/* <PaymentDetailsDisplay
                    ccToken={this.props._ccToken}
                    stage={this.props._stage}
                /> */}
            </article>
        )
    }

    // /**
    //  * "Switcher" that decides which content to be displayed for the Payment
    //  * Details. Depending on whether the Billing & Shipping Information has been
    //  * supplied yet, or if this item is active... Different types of data are
    //  * displayed.
    //  */
    // _displayContents()
    // {
    //     // If we're in confirmation mode, just display the details and be done.
    //     if (this.props.confirmation === true) {
    //         return this._displayDetails();
    //     }

    //     // If Payment Details are not active yet, and we're not completed, show
    //     // a "not yet" type of message.
    //     if (this.props.active === false && this.props.isCompleted === false) {
    //         return <div className="textalign-right text-small">Step 3 of 3</div>
    //     }

    //     // If Payment Details are active, display a form.
    //     if (this.props.active === true) {
    //         return this._displayForm();
    //     }

    //     // Otherwise, we want to show a summary of what has been entered for the
    //     // Payment Details.
    //     return this._displayDetails();
    // }

    // _displayForm()
    // {
    //     return (
    //         <section>
    //             <div className="grid">
    //                 <div className="column-12">
    //                     <Form.StripeInput
    //                         label="Card Number"
    //                         id="cc_cardnumber"
    //                         errorMessage={DISH.form.getErrorMessage('cc_cardnumber', this.props._errors)}
    //                         required
    //                     />
    //                 </div>
    //                 <div className="column-6">
    //                     <Form.StripeInput
    //                         label="Expiry"
    //                         id="cc_expiry"
    //                         errorMessage={DISH.form.getErrorMessage('cc_expiry', this.props._errors)}
    //                         required
    //                     />
    //                 </div>
    //                 <div className="column-6">
    //                     <Form.StripeInput
    //                         label="CVC"
    //                         id="cc_cvc"
    //                         errorMessage={DISH.form.getErrorMessage('cc_cvc', this.props._errors)}
    //                         required
    //                     />
    //                 </div>
    //             </div>
    //             <div className="stripeerror" id="stripeError" />

    //             <div className="grid">
    //                 <div className="column-12 button-row justify-center padding-decent--top">
    //                     <Button
    //                         value="Review & Submit Your Order"
    //                         onClick={this.clickNext}
    //                     />
    //                 </div>
    //             </div>
    //         </section>
    //     )
    // }

    _displayDetails()
    {
        if (typeof this.props._ccToken === 'undefined') {
            return null;
        }

        return (
            <div className={this._getClassNames(this.props.classBase, '--details grid')}>
                <div className="column-12">
                    <label className="detail highlight">Card Number</label>
                    **** **** **** {this.props._ccToken.token.card.last4}
                </div>
                <div className="column-6">
                    <label className="detail highlight">Card Type</label>
                    {this.props._ccToken.token.card.brand}
                </div>
                <div className="column-6">
                    <label className="detail highlight">Expiration</label>
                    {formatMonth(this.props._ccToken.token.card.exp_month)} / {this.props._ccToken.token.card.exp_year}
                </div>
            </div>
        )
    }

    _getClassNames(classBase, extraClasses = '')
    {
        let classNames = [classBase+'--payment-details'+extraClasses];

        if (this.props.active === true) {
            classNames.push('active');
        }

        if (this.props.active === false && this.props.isCompleted === false) {
            classNames.push('disabled');
        }

        if (this.props.isCompleted === true) {
            classNames.push('completed');
        }

        return classNames.join(' ');
    }

    // _clickNext()
    // {
    //     DISH.payments.processPaymentFields()
    //     .then(paymentFieldsResponse => {
    //         return DISH.payments.storePaymentIdentifiers(paymentFieldsResponse);
    //     })
    //     .then(response => {
    //         this.props.completeStep('payment');
    //     })
    //     .catch(error => {
    //         alert('errors lkj34'+ error);
    //     });
    // }
};

const mapStateToProps = function(store)
{
    return {
        _checkout: store.cartState.checkout,
        _ccToken:  store.cartState.ccToken,
        _errors:   store.cartState.checkoutErrors,
        _stage:    store.cartState.checkout.stage,
    };
}

export default connect(mapStateToProps)(PaymentDetails);
