import React from 'react';

export default function Loader(props)
{
  return (
    <div className="columns is-gapless">
      <div className="column is-flex is-justify-content-center">
        <div className="dot-pulse" />
      </div>
    </div>
  )
}
