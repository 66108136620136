'use strict';

let validate = require('validate.js');

validate.validators.presence.options = {allowEmpty: false};

validate.validators.alpha = (value, options, key, attributes) => {
    // Base pattern for alpha values.
    let patternString = 'a-zA-Z';

    // If we're allowing dashes, tack those onto the allowable string.
    if (typeof options.dash !== 'undefined' &&
        options.dash === true
    ) {
        patternString += '-_';
    }

    // If we're allowing numbers, tack that onto the allowable string.
    if (typeof options.numeric !== 'undefined' &&
        options.numeric === true
    ) {
        patternString += '0-9';
    }

    // If we're allowing spaces, tack that onto the allowable string.
    if (typeof options.spaces !== 'undefined' &&
        options.spaces === true
    ) {
        patternString += ' ';
    }

    let pattern = new RegExp('^['+patternString+']+$');

    if (pattern.test(value) === true) {
        // Since the value being tested matches fully the RegExp, return null to
        // indicate that the test passed (weird right?).
        return null;
    }

    // Error message (excluding field name (weird right?)) because we failed. If
    // a message has been provided in the options, use that one.
    return typeof options.message !== 'undefined' ?
        options.message :
        'has invalid characters';
};

/**
 * Checks form inputs for a boolean value.
 */
validate.validators.boolean = (value, options, key, attributes) => {
    // If the value being passed in is equal to the state that is required, return
    // null to indicate success.
    if (value === options.state) {
        return null;
    }

    // Error message (excluding field name (weird right?)) because we failed. If
    // a message has been provided in the options, use that one.
    return typeof options.message !== 'undefined' ?
        options.message :
        'is not equal to '+options.state;
};

export default validate;
