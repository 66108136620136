import React         from 'react';
import { useSearch } from 'providers/SearchProvider';

export default function SortableListHeader(props)
{
  const { label, onClick } = props;

  const doClick = () => {
    if (!onClick) {
      return;
    }

    onClick();
  }

  return (
    <div
      className={getClassNames(props)}
      onClick={doClick}
    >
      {label}
      <div className="container-toggles">
        <i className="ion ion-ios-arrow-up toggle toggle-up" />
        <i className="ion ion-ios-arrow-down toggle toggle-down" />
      </div>
    </div>
  )
}

function getClassNames(props)
{
  const { options } = useSearch();
  const { className, identifier } = props;
  const keyOrderBy   = props.keyOrderBy || 'orderBy';
  const keyDirection = props.keyDirection || 'direction';

  const classNames = [];

  if (className) {
    classNames.push(className);
  }

  if (identifier === options[keyOrderBy]) {
      classNames.push(`sort-${options[keyDirection]}`);
  }

  return classNames.join(' ');
}
