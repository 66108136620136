import { order as types } from 'store/reducers/storeTypes';

const initialState = {
    id: null,
    token: null,
    reference: '',
    itemsCount: 0,
    uniqueItemsCount: 0,
    items: [],
    amounts: {
        subTotal: 0,
        shipping: 0,
        tax: 0,
        total: 0,
    },
    customer: {},
    destination: {},
    created: '',
    updated: '',
};

const orderReducer = function(state = initialState, action) {
    switch(action.type) {
        case types.UPDATE_ORDER:
            // console.log('action.data >>', action.data);
            return {
                ...state,
                id: action.data.id,
                token: action.data.token,
                reference: action.data.reference,
                itemsCount: action.data.items_count,
                uniqueItemsCount: action.data.items_unique_count,
                items: action.data.items,
                amounts: {
                    subTotal: action.data.amounts.sub_total,
                    shipping: action.data.amounts.shipping,
                    tax: action.data.amounts.tax,
                    total: action.data.amounts.total,
                },
                customer: action.data.customer,
                destination: action.data.destination,
                created: action.data.created,
                updated: action.data.updated,
            };
    }

    return state;
}

export default orderReducer;
