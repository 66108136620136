import React, { useEffect } from 'react';
import { useLocation }      from 'react-router';
import * as Form            from 'components/Form';
import { useSearch }        from 'providers/SearchProvider';
import { getRoute }         from 'routes/utilities';

export default function SearchInput(props) {
  const { instantInputSearch } = props;
  const {
    doClearSearch,
    doUpdateSearchString,
    searchString,
    setInstantInputSearch
  } = useSearch();
  const location = useLocation();

  useEffect(() => {
    setInstantInputSearch(instantInputSearch);
  }, [instantInputSearch]);

  useEffect(() => {
    if (location.pathname !== getRoute('search')) {
      doClearSearch();
    }
  }, [location]);

  return (
    <article className="is-relative">
      <Form.Input
        onChange={doUpdateSearchString}
        onKeyDown={doUpdateSearchString}
        placeholder="Search..."
        value={searchString}
      />
      <ClearButton />
    </article>
  )
}

const ClearButton = () => {
  const { doClearSearch, searchString } = useSearch();

  let classNames = [
    'btn-no-padding',
    'plain',
    'flat',
    'ion-ios-close-circle-outline',
    'search-fields--input--closer',
  ];

  if (searchString) {
    classNames.push('visible');
  }

  return (
    <i className={classNames.join(' ')} onClick={doClearSearch} />
  )
}
