import React from 'react';

import OrderListingItem from 'components/Order/OrderListingItem';

export default function OrderItems(props)
{
  const { items } = props;

  return (
    <article className="container-orderlistingitems">
      <OrderListingItem header={true} />

      {items.map((item, key) => {
        return (
          <OrderListingItem
            item={item}
            key={key}
          />
        )
      })}
    </article>
  )
}
