import Album    from 'components/Pages/Shop/Album';
import Category from 'components/Pages/Shop/Category';
import Front    from 'components/Pages/Shop/Front';
import Genre    from 'components/Pages/Shop/Genre';

export {
    Album,
    Category,
    Front,
    Genre,
};
