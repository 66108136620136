'use strict';

import { layout as types } from 'store/reducers/storeTypes';

const initialState = {
    headerNavLinks: [
        // {
        //     title: 'New Releases',
        //     route: '/shop/music/new-releases',
        //     params: { category: 'music'}
        // },
        // { title: 'Vinyl',        link: '/shop/music/vinyl' },
        // {
        //     title: 'Music',
        //     route: 'shop.category',
        //     params: { category: 'music' }
        // },
        // {
        //     title: 'Cart',
        //     route: 'cart',
        // },
    ],
    curtain: {
        open:     false,
        contents: '',
        onClick:  null,
    },
    hero: {
        carousel: {
            items: [
                {
                    img:      '//cdn.dilatednetwork.com/img/assets/heroes/david-bowie.jpg',
                    link:     'dong',
                    position: {
                        horizontal: 'center',
                        vertical:   '20%',
                    }
                },
                {
                    img:      '//cdn.dilatednetwork.com/img/assets/heroes/the-white-buffalo.jpg',
                    link:     'opmr',
                    position: {
                        horizontal: 'center',
                        vertical:   '30%',
                    }
                },
                {
                    img:      '//cdn.dilatednetwork.com/img/assets/heroes/madball.jpg',
                    link:     'opmr',
                    position: {
                        horizontal: 'center',
                        vertical:   '15%',
                    }
                },
                {
                    img:      '//cdn.dilatednetwork.com/img/assets/heroes/elton-john.jpg',
                    link:     'opmr',
                    position: {
                        horizontal: 'center',
                        vertical:   '35%',
                    }
                },
            ],
        },
    },
};

const layoutReducer = function(state = initialState, action) {
    switch(action.type) {
        case types.CURTAIN_OPEN:
            let coState = state;

            coState.curtain.open    = true;
            coState.curtain.onClick = action.data;

            return {
                ...state,
                curtain: coState.curtain
            };
        case types.CURTAIN_CLOSE:
            let ccState = state;

            ccState.curtain = {
                open:     false,
                contents: '',
            };

            return {
                ...state,
                curtain: ccState.curtain
            };
        case types.CURTAIN_CONTENTS:
            let cc2State = state;

            cc2State.curtain.contents = action.data;

            return {
                ...state,
                curtain: cc2State.curtain,
            };
    }

    return state;
}

export default layoutReducer;
