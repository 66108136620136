import React         from 'react';
import { useLayout } from 'providers/LayoutProvider';

export default function Container(props)
{
  const { curtainIsOpen } = useLayout();

  let classNames = ['container container-main'];

  // If the page curtain is open, we don't want the main content scrolling.
  // The curtain is fixed overtop of the content, so let's not be annoying.
  if (curtainIsOpen === true) {
    classNames.push('no-scroll');
  }

  return (
    <section className={classNames.join(' ')}>
      {props.children}
    </section>
  )
}
