import React  from 'react';
import styled from 'styled-components';

export default function Typography(props)
{
  const { element: Element, ...rest } = props;

  // const Element = props.element;

  return (
    <Element {...rest}>{props.children}</Element>
  )
}

export function Header(props)
{
  const { children, ...rest } = props;

  return (
    <Typography
      element="h1"
      {...rest}
    >{children}</Typography>
  )
}

export function SmallHeader(props)
{
  const { children, ...rest } = props;

  return (
    <Typography
      element="h2"
      {...rest}
    >{children}</Typography>
  )
}

export function Text(props)
{
  const { children, ...rest } = props;

  return (
    <Typography
      element="p"
      {...rest}
    >{children}</Typography>
  )
}
