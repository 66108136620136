import React from 'react';
import { Select } from 'components/Form';

const quantityOptions = [
    {value: 1,  label: '1'},
    {value: 2,  label: '2'},
    {value: 3,  label: '3'},
    {value: 4,  label: '4'},
    {value: 5,  label: '5'},
    {value: 6,  label: '6'},
    {value: 7,  label: '7'},
    {value: 8,  label: '8'},
    {value: 9,  label: '9'},
    {value: 10, label: '10'},
];

export default function AddToCartQuantityDropdown(props)
{
    if (typeof props.withQuantityDropdown === 'undefined') {
    console.log('props >>', props);
        return null;
    }

    return (
        <Select
            firstEmptyOption={false}
            options={props.options || quantityOptions}
            onChange={props.onChange}
            noPadding
            size={props.size}
            value={props.value}
        />
    )
}
