import React              from 'react';
import CartCheckoutButton from 'components/Cart/CartCheckoutButton';
import LabelValue         from 'components/Utilities/LabelValue';
import { formatPrice }    from 'lib/formatters';
import { useCart }        from 'providers/CartProvider';
import { useFramework }   from 'providers/FrameworkProvider';

const classBase = 'container-cartsummary';

export default function CartSummary(props)
{
  const { count } = useCart();

  return (
    <article className={classBase}>
      <h1 className={`${classBase}--header`}>Summary</h1>
      <Items amountItems={count} />
      <SubTotal />
      <Shipping />
      <Tax />
      <Total />
      <CartCheckoutButton amountItems={count} classBase={classBase} />
    </article>
  )
}

function Items(props)
{
  const { amountItems } = props;

  return (
    <LabelValue
      className={`${classBase}--number-of-items`}
      label="Items"
      value={amountItems}
      valueClassName="amount"
    />
  )
}

function SubTotal()
{
  const { subTotal } = useCart();

  return (
    <LabelValue
      className={`${classBase}--sub-total`}
      label="SubTotal"
      valueClassName="amount"
    >
      $ {formatPrice(subTotal)}
    </LabelValue>
  )
}

function Shipping()
{
  const { getSettings } = useFramework();

  if (getSettings('checkout.displayShipping') === false) {
    return null;
  }

  const { shipping } = useCart();

  return (
    <LabelValue
      className={`${classBase}--shipping`}
      label="Shipping"
      valueClassName="amount"
    >
      $ {formatPrice(shipping)}
    </LabelValue>
  )
}

function Tax()
{
  const { getSettings } = useFramework();

  if (getSettings('checkout.displayTax') === false) {
      return null;
  }

  const { tax } = useCart();

  return (
    <LabelValue
      className={`${classBase}--tax`}
      label="Tax"
      valueClassName="amount"
    >
      $ {formatPrice(tax)}
    </LabelValue>
  )
}

function Total()
{
  const { total } = useCart();

  return (
    <LabelValue
      className={`${classBase}--total`}
      label="Total"
      valueClassName="amount"
    >
      $ {formatPrice(total)}
    </LabelValue>
  )
}
