'use strict';

import React                  from 'react';
import PropTypes              from 'prop-types';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions }            from 'redux-router5';
import Button                 from 'components/Utilities/Button';
import Link                   from 'components/Utilities/Link';

const classBase = 'container-shoplistingitem';

class ShopListingItem extends React.Component {
    constructor(props, context)
    {
        super(props);
        this.router = context.router;
    }

    render()
    {
        let item = this.props.item;

        const { navigateTo } = this.props;

        return (
            <article className={classBase}>
                <Link
                    router={this.router}
                    navigateTo={navigateTo}
                    routeName='shop.album'
                    routeParams={{
                        category: this.props._categorySlug || item.categories[0].slug,
                        genre:    this.props._genreSlug || item.genre.slug,
                        artist:   item.artist.slug,
                        album:    item.album.slug
                    }}
                >
                    {this._displayImage(item)}
                    {this._displayFormat(item)}
                    {this._displayPrimaryTitle(item)}
                    {this._displayViewButton(item)}
                </Link>
            </article>
        )
    }

    _displayImage(item)
    {
        return (
            <div
                className={classBase+'--image'}
                style={{backgroundImage: 'url('+item.album.image_url+')'}}
            >
                <img
                    src={item.album.image_url}
                    alt={item.artist.name+' - '+item.album.name}
                />
            </div>
        )
    }

    _displayFormat(item)
    {
        let formats = [];

        item.media.map((media, key) => {
            formats.push(media.mediaType);
        });

        return (
            <div className={classBase+'--format'}>
                {formats.join(', ')}
            </div>
        )
    }

    _displayPrimaryTitle(item)
    {
        return (
            <div className={classBase+'--title-primary'}>
                <span className={classBase+'--title-primary-artist'}>
                    {item.artist.name}
                </span>
                <span className={classBase+'--title-primary-separator'}>
                    -
                </span>
                <span className={classBase+'--title-primary-album'}>
                    {item.album.name}
                </span>
            </div>
        )
    }

    _displayViewButton(item)
    {
        return (
            <div className={classBase+'--view'}>
                <Button className="btn--large">View</Button>
            </div>
        )
    }
};

ShopListingItem.contextTypes = {
    router: PropTypes.object.isRequired
};

const mapStateToProps = function(store)
{
    return {
        _categorySlug: store.shopState.categorySlug,
        _genreSlug:    store.shopState.genreSlug,
    };
}

export default connect(
    mapStateToProps,
    dispatch => bindActionCreators({ navigateTo: actions.navigateTo }, dispatch)
)(ShopListingItem);
