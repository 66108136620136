import React           from 'react';
import { formatMonth } from 'lib/formatters';

export default function PaymentDetailsDisplay(props)
{
    // If we haven't completed the payment stage of input, we don't need to
    // render this information display.
    if ((props.stage.completed.payment || false) === false) {
        return null;
    }

    return (
        <div className={`${props.classBase}--payment-details--details grid`}>
            <div className="column-12">
                <label className="detail highlight">Card Number</label>
                **** **** **** {props.ccToken.token.card.last4}
            </div>
            <div className="column-6">
                <label className="detail highlight">Card Type</label>
                {props.ccToken.token.card.brand}
            </div>
            <div className="column-6">
                <label className="detail highlight">Expiration</label>
                {formatMonth(props.ccToken.token.card.exp_month)} / {props.ccToken.token.card.exp_year}
            </div>
        </div>
    )
}
