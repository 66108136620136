import React, {useState, useEffect} from 'react'
import * as DISH                    from 'api/dilatedShop';
import { useRenderError }           from 'providers/FrameworkProvider';
import useRedirect                  from 'routes/useRedirect';

const CartContext = React.createContext(undefined);

export const CART_TOKEN = 'cartToken';

export const STEP_BILLING = 'billing';
export const STEP_SHIPPING = 'shipping';
export const STEP_PAYMENT = 'payment';
export const STEP_CONFIRMATION = 'confirmation';

export const FORM_BILLING = 'checkoutBilling';
export const FORM_SHIPPING = 'checkoutShipping';

export default function CartProvider(props)
{
  const [items, setItems] = useState([]);
  const [count, setCount] = useState(0);
  const [uniuqeCount, setUniqueCount] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [shipping, setShipping] = useState(0);
  const [tax, setTax] = useState(0);
  const [total, setTotal] = useState(0);

  const [activeStep, setActiveStep] = useState();

  const redirector = useRedirect();

  useEffect(() => {
    resetCheckout();
  }, []);

  const doCartInit = (cartToken, suppliedCartData = null) => {
    // Setting cart data locally to be easier to work with. If we've already been
    // given an object of cartData, we'll use that (it's already been grabbed
    // from the backend (unifying initialize request)). Otherwise, we need to
    // make a request to the backend to get the cart data for the supplied token.
    const cartData = suppliedCartData || DISH.requests.GET(`/cart/${cartToken}`)
      .then(cartResponse => cartResponse.data.data);

    doSetCartData(cartData);
  };

  /**
   * Sets the state for all the data items of the cart.
   *
   * @param {object} cartData
   */
  const doSetCartData = (cartData) => {
    setItems(cartData.items);
    setCount(cartData.items_count);
    setUniqueCount(cartData.items_unique_count);
    setSubTotal(cartData.amounts.sub_total);
    setShipping(cartData.amounts.shipping);
    setTax(cartData.amounts.tax);
    setTotal(cartData.amounts.total);
  }

  const resetCheckout = () => {
    setActiveStep(STEP_BILLING);
  }

  const nextCheckoutStep = () => {
    switch (activeStep) {
      case STEP_BILLING:
        setActiveStep(STEP_SHIPPING);
        break;
      case STEP_SHIPPING:
        paymentConfirmationStep();
        break;
    }
  }

  const previousCheckoutStep = () => {
    switch (activeStep) {
      case STEP_PAYMENT:
        setActiveStep(STEP_SHIPPING);
        break;
      case STEP_SHIPPING:
        setActiveStep(STEP_BILLING);
        break;
    }
  }

  const goToStep = (step) => {
    setActiveStep(step);

    switch (step) {
      case STEP_BILLING:
        redirector('cart.checkout');
        break;
      case STEP_SHIPPING:
        redirector('cart.checkout');
        break;
      case STEP_PAYMENT:
        redirector('cart.checkout');
        break;
      case STEP_CONFIRMATION:
        redirector('cart.confirmation');
        break;
    }
  }

  const paymentConfirmationStep = () => {
    if (process.env.REQUIRES_STRIPE) {
      setActiveStep(STEP_PAYMENT);

      return;
    }

    setActiveStep(STEP_CONFIRMATION);
    redirector('cart.confirmation');
  }

  const resolveOrGenerateCartToken = () => {
    const cartToken = useToken();

    // If the token already exists in localStorage, return that and be done.
    if (cartToken !== null) {
      return new Promise(resolve => resolve(cartToken));
    }

    // Noooope, we'll have to generate a new one.
    return DISH.requests.GET('/cart/generate')
      .then(response => {
          // Store the response in localStorage for future.
          useToken(response.data.data.token);

          return new Promise(resolve => resolve(response.data.data.token));
      });
  };

  const doAddToCart = (inventoryId, quantity = 1, type, item) => {
    const token = useToken();

    const url = `${process.env.DILATED_API}/cart/${token}`;
    const method = 'put';

    // If the token is null, we have to switch the endpoint and method so we can
    // create a new cart.
    if (token === null) {
        url = `${process.env.DILATED_API}/cart`;
        method = 'post';
    }

    // Send the cart update to the backend
    return DISH.requests.REQUEST({
      method: method,
      url:  url,
      data: {
        item: inventoryId,
        qty:  quantity,
      },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(response => {
        // Just to be safe, always set the token out of the response, if there's
        // any reason the backend is changing, we need to stay in sync.
        useToken(response.data.data.token);

        // We get a cart token in the response from the backend, we need to store
        // this in local storage of the client, for reference later.
        return new Promise(resolve => {
          resolve(doSetCartData(response.data.data));
        });
      })
      .catch(error => {
        useRenderError(error);
        return false;
      });
  }

  const doRemoveFromCart = (itemToken) => {
    const token = useToken();

    return DISH.requests.DELETE(`/cart/${token}/items/${itemToken}`)
      .then(response => {
        // Just to be safe, always set the token out of the response, if there's
        // any reason the backend is changing, we need to stay in sync.
        useToken(response.data.data.token);

        // We get a cart token in the response from the backend, we need to store
        // this in local storage of the client, for reference later.
        return new Promise(resolve => {
          resolve(doSetCartData(response.data.data));
        });
      })
      .catch(error => {
        useRenderError(error);
        return false;
      });
  }

  const doUpdateQuantity = (itemToken, inventoryId, quantity) => {
    const token = useToken();

    return DISH.requests.PUT(`/cart/${token}/items/${itemToken}`, {
      data: {
        item: inventoryId,
        qty: quantity,
      }
    })
      .then(response => {
        // Just to be safe, always set the token out of the response, if there's
        // any reason the backend is changing, we need to stay in sync.
        useToken(response.data.data.token);

        // We get a cart token in the response from the backend, we need to store
        // this in local storage of the client, for reference later.
        return new Promise(resolve => {
          resolve(doSetCartData(response.data.data));
        });
      })
      .catch(error => {
        useRenderError(error);
        return false;
      });
  }

  return (
    <CartContext.Provider
      value={{
        items,
        count,
        uniuqeCount,
        subTotal,
        shipping,
        tax,
        total,

        activeStep,
        // addToCart,
        doAddToCart,
        doCartInit,
        doRemoveFromCart,
        doUpdateQuantity,
        goToStep,
        nextCheckoutStep,
        previousCheckoutStep,
        resetCheckout,
        resolveOrGenerateCartToken,
      }}
    >
      {props.children}
    </CartContext.Provider>
  )
}

export function useCart() {
  const context = React.useContext(CartContext)

  if (context === undefined) {
    throw new Error('useCart must be used within a CartProvider')
  }

  return context
}

/**
 * Get/Set the cart token from/in localStorage. This token is used to identify
 * the user's shopping cart in the backend.
 *
 * @param {string} token
 * @param {boolean} create
 */
export function useToken(token = null) {
  // If the token value is null, requester wants the value, so return the value.
  if (token === null) {
    return localStorage.getItem(CART_TOKEN);
  }

  // When the token value is false, we're removing the cart in storage.
  if (token === false) {
    return localStorage.removeItem(CART_TOKEN);
  }

  // Here means we've been given a token value to save in localStorage.
  localStorage.setItem(CART_TOKEN, token);

  // Return a Promise for chaining... Resolving with the cart token.
  return new Promise((resolve, reject) => {
    resolve(localStorage.getItem(CART_TOKEN));
  });
};
