import React, { useEffect }       from 'react';
import * as DISH                  from 'api/dilatedShop';
import DetailsForm                from 'components/Checkout/DetailsForm';
import { useCart, FORM_BILLING, FORM_SHIPPING, STEP_SHIPPING }
                                  from 'providers/CartProvider';
import { useForm }                from 'providers/FormProvider';
import { billingConstraints, shippingConstraints }
                                  from 'validators/checkout';

export default function ShippingDetailsForm(props)
{
  const { nextCheckoutStep, previousCheckoutStep } = useCart();
  const { formInit, formValidate, formValue } = useForm();
  const { activeStep } = useCart();

  // useEffect(() => {
  //   formInit(FORM_SHIPPING);
  // }, []);

  // If we're not on the Shipping Information gathering step, we'll render nothing.
  if (activeStep !== STEP_SHIPPING) {
    return null;
  }

  const handleNextStep = () => {
    let validations = shippingConstraints;
    let formId = FORM_SHIPPING;

    if (formValue('sameShipping') === true) {
      validations = billingConstraints;
      formId = FORM_BILLING;
    }

    if (formValidate(validations, formId) === false) {
      DISH.layout.toTop();

      return;
    }

    nextCheckoutStep();
  }

  return (
    <div className="columns is-multiline is-variable is-2">
      <DetailsForm
        formId={FORM_SHIPPING}
        validations={shippingConstraints}
        nextClick={handleNextStep}
        previousClick={previousCheckoutStep}
      />
    </div>
  )
}
