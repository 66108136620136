import React        from 'react';
import PropTypes    from 'prop-types';
import { connect }  from 'react-redux';

function Button(props)
{
  const {
    routeName,
    routeParams,
    routeOptions,
    routeCallback,
    navigateTo,
    innerHTML,
    value,
    children,
    ...otherProps
  } = props;

  const onClick = (event) => {
    event.preventDefault();

    if (typeof navigateTo !== 'undefined') {
      navigateTo(routeName, routeParams, routeOptions, routeCallback);
    }

    if (typeof props.onClick !== 'undefined') {
      props.onClick();
    }
  };
  // let handleClick = (event) => {
  //   event.preventDefault();

  //   if (typeof navigateTo !== 'undefined') {
  //     navigateTo(routeName, routeParams, routeOptions, routeCallback);
  //   }

  //   if (typeof props.onClick !== 'undefined') {
  //     props.onClick();
  //   }
  // };
  // let onClick = handleClick;

  const className = props.className;

  if (typeof props.value !== 'undefined') {
    return <button { ...{ onClick, ...otherProps } }>{value}</button>
  }

  return <button { ...{ onClick, ...otherProps } }>{children}</button>
}

Button.propTypes = {
  routeName:    PropTypes.string,
  routeParams:  PropTypes.object,
  routeOptions: PropTypes.object,
  navigateTo:   PropTypes.func,
  title:        PropTypes.string,
};

Button.defaultProps = {
  routeName:     '',
  routeParams:   {},
  routeOptions:  {},
  routeCallback: {},
  className:     null,
  title:         null,
};

export default Button;
