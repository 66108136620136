import React               from 'react';
import { NavLink }         from 'react-router-dom';
import ShopAddToCartButton from 'components/Shop/ShopAddToCartButton';
import { formatPrice }     from 'lib/formatters';
import { useSearch }       from 'providers/SearchProvider';
import { getRoute }        from 'routes/utilities';

const classBase        = 'container-shopsearchlistingitem';

export default function SearchListingItem(props)
{
  const { item } = props;
  const { searchString } = useSearch();

  const highlight = (data) => {
    return data.replace(
      new RegExp(`(${searchString})`, 'ig'),
      '<span class="highlight">$1</span>'
    );
  }

  return (
    <article className={classBase}>
      <div
        className={`${classBase}-product-name-primary`}
        dangerouslySetInnerHTML={{__html: highlight(item.contributors[0].name)}}
      />
      <div className={`${classBase}-product-name-secondary`}>
        <NavLink
          to={getRoute('shop.album', {
            artist: item.contributors[0].slug,
            album: item.album.slug,
          })}
          dangerouslySetInnerHTML={{__html: highlight(item.album.title)}}
        />
      </div>
      <div
        className={`${classBase}-barcode`}
        dangerouslySetInnerHTML={{__html: highlight(item.barcode)}}
      />
      <div
        className={`${classBase}-label`}
        dangerouslySetInnerHTML={{__html: highlight(item.album.publisher)}}
      />
      <div className={`${classBase}-format`}>
        {item.format.main.format}
      </div>
      <div className={`${classBase}-quantity`}>
        {item.quantity}
      </div>
      <div className={`${classBase}-price`}>
        ${formatPrice(item.currentPrice)}
      </div>
      <div className={`${classBase}-add-to-cart`}>
        <ShopAddToCartButton
          inventoryId={item.id}
          withQuantityDropdown
          size="small"
          buttonLabel="Add"
          iconOnly
        />
      </div>
    </article>
  )
}
