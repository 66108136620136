'use strict';

import React          from 'react';
import PropTypes      from 'prop-types';
import { connect }    from 'react-redux';
import ShopSidebar    from 'components/Shop/ShopSidebar';
import ShopListing    from 'components/Shop/ShopListing';
import * as DISH      from 'api/dilatedShop';

class Genre extends React.Component {
    constructor(props, context)
    {
        super(props);
        this.router = context.router;
    }

    componentDidMount()
    {
        // When mounting the component, we'll want to ensure we end up with the
        // data from the backend proper, so send the flag to force the grab of
        // the important bits.
        DISH.shop.initialize(true);
    }

    componentWillReceiveProps(nextProps)
    {
        // Checking if the newly acquired genreId is NOT null and NOT equal to
        // the current genreId. Also checking if both the current genreSlug and
        // the new genreSlug are empty. If either those cases are true, we'll
        // redirect back to the category page.
        if (
            (nextProps._genreId != this.props._genreId && nextProps._genreId == null) ||
            (nextProps._genreSlug === '' && this.props._genreSlug === '')
        ) {
            DISH.framework.redirect(this.router, 'shop.category', {
                category: this.props.route.params.category
            });
        }

        DISH.shop.initialize();
    }

    render()
    {
        return (
            <div className="grid">
                <section className="column-12 column-sm-3">
                    <ShopSidebar
                        categorySlug={this.props._categorySlug}
                    />
                </section>
                <section className="column-12 column-sm-9">
                    <ShopListing
                        categoryId={this.props._categoryId}
                        genreId={this.props._genreId}
                        origin="genre"
                    />
                </section>
            </div>
        )
    }
};

Genre.contextTypes = {
    router: PropTypes.object.isRequired,
};

const mapStateToProps = function(store)
{
    return {
        route:         store.router.route,
        _categoryId:   store.shopState.categoryId,
        _categorySlug: store.shopState.categorySlug,
        _genreId:      store.shopState.genreId,
        _genreSlug:    store.shopState.genreSlug,
    };
}

export default connect(mapStateToProps)(Genre);
