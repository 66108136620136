import React from 'react';

export default function NewlineText(props)
{
  const { children, text } = props;
  const source = children || text;

  if (!source) {
    return null;
  }

  const newText = source.split('\n').map((str, key) => <p key={key}>{str}</p>);

  return newText;
}
