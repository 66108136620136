import React, { useEffect } from 'react';
import * as DISH            from 'api/dilatedShop';
import BillingDetails       from 'components/Checkout/BillingDetails';
import CartSummary          from 'components/Checkout/CartSummary';
import PaymentDetails       from 'components/Checkout/PaymentDetails';
import ShippingDetails      from 'components/Checkout/ShippingDetails';
import Page                 from 'components/Layouts/Page';
import { useCart }          from 'providers/CartProvider';
import { useForm }          from 'providers/FormProvider';
import { billingConstraints, shippingConstraints } from 'validators/checkout';

export default function Checkout()
{
  const { resetCheckout, FORM_BILLING, FORM_SHIPPING } = useCart();
  const { formInit } = useForm();

  // If the cart is empty, there's nothing to checkout, so there's nothing
  // to paint to the page. useEffect to keep checking after state changes.
  useEffect(() => {
    if (DISH.cart.isEmpty() === true) {
      return;
    }

    resetCheckout();

console.log('DISH.framework.getSettings(\'customer\') >>', DISH.framework.getSettings('customer'));

    formInit(
      FORM_BILLING,
      billingConstraints,
      DISH.framework.getSettings('customer')
    )
      .then(() => {
        formInit(
          FORM_SHIPPING,
          shippingConstraints,
          DISH.framework.getSettings('shipping')
        )
      })

  }, []);

  return (
    <Page>
      <div className="columns is-multiline is-centered">
        <div className="column is-12">
          <CartSummary />
        </div>
        <div className="column is-8">
          <BillingDetails />
          <ShippingDetails />
          <PaymentDetails />
        </div>
      </div>
    </Page>
  )
}
