import React                   from 'react';
import { NavLink }             from 'react-router-dom';
import { Header, SmallHeader } from 'components/UI/Typography';
import AddToCart               from 'components/Utilities/AddToCart/AddToCart';
import TrackDuration           from 'components/Utilities/TrackDuration';
import {
  formatDescription,
  formatPrice
} from 'lib/formatters';

export default function Details(props)
{
  const { item } = props;

  return (
    <div className="columns is-multiline">
      <div className="column is-12 mb-4">
        <TitleInfo item={item} />
      </div>

      <div className="column is-12 mb-4">
        <Price item={item} />
      </div>

      <div className="column is-6">
        <Description item={item} renderOnEmpty/>
      </div>
      <div className="column is-6">
        <TrackListing
          isMultiDisc={!!item.album.numberOfDiscs}
          tracks={item.tracks}
        />
      </div>
    </div>
  )
}

export function TitleInfo(props)
{
  const { item } = props;
  const { numberOfDiscs, productionYear, publisher, title } = item.album;
  const { format, barcode } = item;

  return (
    <div className="columns is-multiline mb-4">
      <div className="column is-12">
        <Header>
          <NavLink
            to={{
              pathname: '/search',
              search: `?s=${item.contributors[0].name}`
            }}
          >{item.contributors[0].name}</NavLink> - <NavLink
            to={{
              pathname: '/search',
              search: `?s=${title}`
            }}
          >{title}</NavLink> [{productionYear}]
        </Header>
      </div>

      <div className="column is-2 pl-5 py-1">Publisher:</div>
      <div className="column is-10 py-1">{publisher}</div>

      <div className="column is-2 pl-5 py-1">Format:</div>
      <div className="column is-10 py-1">{format.main.format} / {format.sub.format}</div>

      <div className="column is-2 pl-5 py-1">EAN / Barcode:</div>
      <div className="column is-10 py-1">{barcode}</div>

      <div className="column is-2 pl-5 py-1">Multiple Discs:</div>
      <div className="column is-10 py-1">{numberOfDiscs > 1 ? 'Yes' : 'No'}</div>
    </div>
  )
}

export function Price(props)
{
  const { item } = props;

  return (
    <div className="columns is-gapless">
      <div className="column is-2">
        <Header>${formatPrice(item.current_price)}</Header>
      </div>
      <div className="column is-10">
        <AddToCart
          inventoryId={item.id}
          withQuantityDropdown
          size="small"
        />
      </div>
    </div>
  )
}

export function Description(props)
{
    const { item, renderOnEmpty } = props;

    // If the prop to renderOnEmpty is not passed to us and the description value
    // is in fact empty, we're done. We're not rendering anything.
    if (!renderOnEmpty && (item.album.description || '') === '') {
      return null;
    }

    const description = item.album.description || 'No Description Available';

    return (
      <>
        <Header>Description</Header>
        <div
          className="content"
          dangerouslySetInnerHTML={{__html: formatDescription(description)}}
        />
      </>
    )
}

export function TrackListing(props)
{
  const { isMultiDisc, tracks } = props;

  let lastDisc = '';

  return (
    <>
      <Header>Tracks</Header>

      <div className="pt-2">
        <div className="columns is-multiline">
          {tracks.map((track, key) => {
            let trackNum = key + 1;

            const isNewDisc = track.disc !== lastDisc;
            lastDisc = track.disc;

            return (
              <React.Fragment key={key}>
                <DiscHeader
                  disc={track.disc}
                  render={isMultiDisc && isNewDisc}
                />

                <div className="column is-1 py-1">{track.discOrder}.</div>
                <div className="column is-9 py-1">{track.title}</div>
                <div className="column is-2 py-1">
                  <TrackDuration duration={track.duration} />
                </div>
              </React.Fragment>
            )
          })}
        </div>
      </div>
    </>
  )
}

function DiscHeader(props)
{
  const { disc, render } = props;

  if (!render) {
    return null;
  }

  return (
    <div className="column is-12 mt-5 pb-0">
      <SmallHeader>Disc {disc}</SmallHeader>
    </div>
  )
}
