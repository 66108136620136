import React                from 'react';
import SearchListingHeader  from 'components/Shop/Search/SearchListingHeader';
import SearchListingItem    from 'components/Shop/Search/SearchListingItem';
import Loader               from 'components/Utilities/Loader';
import { useSearch }        from 'providers/SearchProvider';

export default function SearchListingItems(props)
{
  const { isSearching, results } = useSearch();

  if (isSearching) {
    return (
      <section className={`${props.classBase}items empty-results`}>
        <Loader />
      </section>
    )
  }

  if (!isSearching && results.length === 0) {
    return (
      <section className={`${props.classBase}items empty-results`}>
        <div>No Items Available</div>
      </section>
    )
  }

  return (
    <section className={`${props.classBase}items`}>
      <SearchListingHeader
        classBase={props.classBase}
      />

      {results.map((item, key) => {
        return (
          <SearchListingItem
            key={key}
            item={item}
          />
        )
      })}
    </section>
  )
}
