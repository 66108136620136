import React, { useEffect } from 'react';
import * as DISH            from 'api/dilatedShop';
import * as Form            from 'components/Form';
import ContinueButton       from 'components/Checkout/ContinueButton';
import { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  Elements,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';
import history from 'historyX';
import { getRoute } from 'routes/utilities';

export default function PaymentDetailsForm(props)
{
    // useEffect to call the Payment Initialization routine(s) after this
    // // component has been rendered.
    // useEffect(() => {
    //     // Need to make sure that the current stage is the payment stage before
    //     // we try to initlialize the CC fields. Errors happen otherwise.
    //     if (typeof props !== 'undefined' &&
    //         (props.stage || '') === 'payment'
    //     ) {
    //         DISH.payments.initialize();
    //     }
    // });

// Custom styling can be passed to options when creating an Element.
const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      lineHeight: '40px',
      '::placeholder': {
        color: '#aab7c4'
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  }
};

  const [error, setError] = useState(null);
  const stripe            = useStripe();
  const elements          = useElements();

//   // Handle real-time validation errors from the card Element.
//   const handleChange = (event) => {
//     if (event.error) {
//       setError(event.error.message);
//     } else {
//       setError(null);
//     }
//   }



//   // Handle form submission.
//   const handleSubmit = (event) => {
//     event.preventDefault();
//     const card = elements.getElement(CardElement);
//     const result = stripe.createToken(card)
//     if (result.error) {
//       // Inform the user if there was an error.
//       setError(result.error.message);
//     } else {
//       setError(null);
//       // Send the token to your server.
//       stripeTokenHandler(result.token);
//     }
//   };




    // If we're not currently in the shipping stage of input, we don't need to
    // render this form.
    if ((props.stage.active || '') !== 'payment') {
        // return null;
    }

    return (
      <>
        <div className="grid">
          <div className="column-12">
            <Form.StripeInput
              label="Card Number"
              errorMessage={DISH.form.getErrorMessage('cc_cardnumber', props.errors)}
            >
              <CardNumberElement options={CARD_ELEMENT_OPTIONS} />
            </Form.StripeInput>
            {/* <Form.StripeInput
                label="Card Number"
                id="cc_cardnumber"
                errorMessage={DISH.form.getErrorMessage('cc_cardnumber', props.errors)}
                required
            /> */}
          </div>
          <div className="column-6">
            <Form.StripeInput
              label="Expiry"
              errorMessage={DISH.form.getErrorMessage('cc_cardnumber', props.errors)}
            >
              <CardExpiryElement options={CARD_ELEMENT_OPTIONS} />
            </Form.StripeInput>
            {/* <Form.StripeInput
                label="Expiry"
                id="cc_expiry"
                errorMessage={DISH.form.getErrorMessage('cc_expiry', props.errors)}
                required
            /> */}
          </div>
          <div className="column-6">
            <Form.StripeInput
              label="CVC"
              errorMessage={DISH.form.getErrorMessage('cc_cardnumber', props.errors)}
            >
              <CardCvcElement options={CARD_ELEMENT_OPTIONS} />
            </Form.StripeInput>
            {/* <Form.StripeInput
                label="CVC"
                id="cc_cvc"
                errorMessage={DISH.form.getErrorMessage('cc_cvc', props.errors)}
                required
              /> */}
          </div>
        </div>

        <div className="stripeerror" id="stripeError" />

        <ContinueButton onClick={() => { _clickNext(); }} type="continue">
            Review & Submit Your Order
        </ContinueButton>
      </>
    )
}

function _clickNext()
{
    return DISH.cart.completeStep('payment')
        .then(response => {
            history.push(getRoute('cart.confirmation'));
        });

    // return history.push(getRoute('cart.confirmation'));

    return DISH.payments.processPaymentFields(CardNumberElement)
        .then(paymentFieldsResponse => {
            return DISH.payments.storePaymentIdentifiers(paymentFieldsResponse);
        })
        .then(response => {
            DISH.cart.completeStep('payment');
        })
        .catch(error => {
            alert('errors lkj34'+ error);
            DISH.layout.toTop();
        });
}
