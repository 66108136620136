import React                      from 'react';
import DetailsDisplay             from 'components/Checkout/DetailsDisplay';
import DetailsHeader              from 'components/Checkout/DetailsHeader';
import ShippingDetailsForm        from 'components/Checkout/ShippingDetailsForm';
import { useCart, FORM_SHIPPING, STEP_SHIPPING } from 'providers/CartProvider';

export default function ShippingDetails(props)
{
  const { classBase, confirmation, isCompleted } = props;
  const { activeStep, goToStep } = useCart();
  const active = activeStep === STEP_SHIPPING;

  const classNames = [`${classBase}--shipping-details`];

  if (active === true) {
      classNames.push('active');
  }

  if (active === false && isCompleted === false) {
      classNames.push('disabled');
  }

  if (isCompleted === true) {
      classNames.push('completed');
  }

  const editStep = () => goToStep(STEP_SHIPPING);

  return (
    <article className={classNames.join(' ')}>
      <DetailsHeader
        active={active || confirmation}
        editStep={editStep}
        section="Shipping"
        {...props}
      />

      <ShippingDetailsForm />

      <DetailsDisplay formId={FORM_SHIPPING} />
    </article>
  )
}
