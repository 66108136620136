'use strict';

import React          from 'react';
import ShopCategories from 'components/Shop/ShopCategories';
import ShopCategoryGenreIdentifier
                      from 'components/Shop/ShopCategoryGenreIdentifier';
import ShopGenres     from 'components/Shop/ShopGenres';
import ShopSearch     from 'components/Shop/Search';

const classBase = 'container-shop-sidebar';
const defaultCategory = 'music';

class ShopSidebar extends React.Component {
    render()
    {
        return (
            <article className={classBase}>
                <h1 className={classBase+'--header'}>Category</h1>
                <ShopCategoryGenreIdentifier />
                <ShopSearch />
                <ShopCategories
                    categorySlug={this.props.categorySlug || defaultCategory}
                />
                <ShopGenres />
            </article>
        )
    }
};

export default ShopSidebar;
