import React, { useEffect } from 'react';
import * as DISH            from 'api/dilatedShop';
import Page                 from 'components/Layouts/Page';
import HeroCarousel         from 'components/Hero/Carousel';
import ShopSidebar          from 'components/Shop/ShopSidebar';
import ShopListing          from 'components/Shop/ShopListing';
import ShopSearch           from 'components/Shop/Search';
import FeaturedItems        from 'components/Shop/FeaturedItems';
import CategoryListing      from 'components/Shop/CategoryListing/CategoryListing';

export default function Front(props) {
  useEffect(() => {
    const { location } = props;

    DISH.shop.initialize(true, {activeCategory: 'music'}, location);
  }, []);

  return (
    <Page>
      <div className="columns is-multiline">
        <div className="column is-10 is-offset-1 pt-4 pb-6">
          <ShopSearch
            instantInputSearch={false}
            quickSearchResults={false}
          />
        </div>
        <section className="column is-12">
          <FeaturedItems />
        </section>
        <section className="column is-12 container-front-ad-box">
          Some news box or something, maybe some advertising...?
        </section>

        <section className="column is-6 is-12-mobile container-front-category">
          <h1>New This Week</h1>
          <CategoryListing categoryId="2" perPage="5" />
        </section>
        <section className="column is-6 is-12-mobile container-front-category">
          <h1>Staff Picks</h1>
          <CategoryListing categoryId="3" perPage="5" />
        </section>
      </div>
    </Page>
  )
}
