import React             from 'react';
import * as Pages        from 'components/Pages';
import FormProvider      from 'providers/FormProvider';
import OrderProvider     from 'providers/OrderProvider';
import SearchProvider    from 'providers/SearchProvider';

import CartCheckout      from 'components/Pages/Cart/Checkout';
import CartConfirmation  from 'components/Pages/Cart/Confirmation';
import CartFront         from 'components/Pages/Cart/Front';
import Login             from 'components/Pages/Login/Login';
import Order             from 'components/Pages/Order/Order';

const providers = [
  FormProvider,
  SearchProvider,
];

const routes = {
  'home': {
      path: '/',
      exact: true,
      component: <Pages.Home />,
  },
  // 'shop': {
  //     path: '/shop',
  //     component: Pages.Shop,
  // },
  // 'shop.category': {
  //     path: '/shop/:category?:page&:perPage',
  //     component: Pages.Shop,
  // },
  // 'shop.genre': {
  //     path: '/shop/:category/:genre?:page&:perPage',
  //     component: Pages.Shop,
  // },
  // 'shop.album': {
  //     path: '/shop/:category/:genre/album/:artist/:album',
  //     component: Pages.Shop,
  // },
  // 'search': {
  //     path: '/search',
  //     component: Pages.Cart.Cart,
  // },
  'cart': {
    path: '/cart',
    component: <CartFront />,
    children: {
      'checkout': {
          component: <CartCheckout />,
          exact: true,
          path: '/checkout',
      },
      'confirmation': {
          component: <CartConfirmation />,
          exact: true,
          path: '/confirmation',
      },
    },
  },
  'orders': {
    component: <Order />,
    path: '/orders',
    provider: OrderProvider,
    children: {
      'order': {
        component: <Order />,
        path: '/:orderToken',
        children: {
          'thanks': {
            component: <Order thanks={true} />,
            path: '/thanks'
          },
        },
      },
    },
  },
}

const authRoutes = {
  'login': {
    path: '/login',
    exact: true,
    component: <Login />,
  },
  'logout': {
    path: '/logout',
    exact: true,
    component: <Login logout={true} />,
  },
};

export default routes;
export {
  authRoutes,
  providers,
};
