'use strict';

import React        from 'react';
import { connect }  from 'react-redux';

class Front extends React.Component {
    render()
    {
        return (
            <div>
                ORder FRONT!
            </div>
        )
    }
};

const mapStateToProps = function(store)
{
    return {
        //
    };
}

export default connect(mapStateToProps)(Front);
