import React, { useEffect, useState } from 'react'
import { useLocation}                 from 'react-router-dom'
import qs                             from 'qs';
import * as DISH                      from 'api/dilatedShop';
import useDebounce                    from 'components/Utilities/useDebounce';
import history                        from "BASE/historyX";
import { getRoute }                   from 'routes/utilities';
import useRedirect                    from 'routes/useRedirect';

const SearchContext = React.createContext(undefined);

const OPTIONS_KEY_ARTIST = 'artist';
const OPTIONS_KEY_ALBUM = 'album';
const OPTIONS_KEY_EAN = 'ean';
const OPTIONS_KEY_LABEL = 'label';
const OPTIONS_KEY_GENRE = 'genre';
const OPTIONS_KEY_EXACT = 'exact';
const OPTIONS_KEY_DIRECTION = 'direction';
const OPTIONS_KEY_ORDER_BY = 'orderBy';
const OPTIONS_KEY_CURRENT_PAGE = 'currentPage';
const OPTIONS_KEY_PER_PAGE = 'perPage';
const SORT_ASC = 'asc';
const SORT_DESC = 'desc';
const DEFAULT_ORDER_BY = OPTIONS_KEY_ARTIST;
const DEFAULT_CURRENT_PAGE = 1;
const DEFAULT_PER_PAGE = 25;

export default function SearchProvider(props)
{
  const [isSearching, setIsSearching] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [instantInputSearch, setInstantInputSearch] = useState(true);
  const [instantOptionSearch, setInstantOptionSearch] = useState(false);
  const [results, setResults] = useState([]);
  const [options, setOptions] = useState({
    [OPTIONS_KEY_ARTIST]:       false,
    [OPTIONS_KEY_ALBUM]:        false,
    [OPTIONS_KEY_EAN]:          false,
    [OPTIONS_KEY_LABEL]:        false,
    [OPTIONS_KEY_GENRE]:        false,
    [OPTIONS_KEY_EXACT]:        false,
    [OPTIONS_KEY_CURRENT_PAGE]: DEFAULT_CURRENT_PAGE,
    [OPTIONS_KEY_PER_PAGE]:     DEFAULT_PER_PAGE,
    [OPTIONS_KEY_ORDER_BY]:     DEFAULT_ORDER_BY,
    [OPTIONS_KEY_DIRECTION]:    SORT_ASC,
  });
  const [pagination, setPagination] = useState({
    currentPage:  1,
    totalPages:   1,
    totalResults: 0,
    perPage:      DEFAULT_PER_PAGE,
    nextLink:     '',
    prevLink:     '',
  })
  const debouncedSearchString = useDebounce(searchString, 500);

  const redirector = useRedirect();
  const location = useLocation();

  const doUpdateSearchString = (event) => {
    // If the key pressed is the "enter" key, we want to start the search, no
    // more updating the searchString value.
    if (event.key == 'Enter' || event.keyCode === 'Enter') {
      resetCurrentPage();
      return search();
    }

    setSearchString(event.target.value);
  };

  const doClearSearch = () => {
    setSearchString('');
    setOptions(currentOptions => {
      return {
        ...currentOptions,
        [OPTIONS_KEY_ARTIST]:       false,
        [OPTIONS_KEY_ALBUM]:        false,
        [OPTIONS_KEY_EAN]:          false,
        [OPTIONS_KEY_LABEL]:        false,
        [OPTIONS_KEY_GENRE]:        false,
        [OPTIONS_KEY_EXACT]:        false,
        [OPTIONS_KEY_CURRENT_PAGE]: DEFAULT_CURRENT_PAGE,
        [OPTIONS_KEY_PER_PAGE]:     DEFAULT_PER_PAGE,
        [OPTIONS_KEY_ORDER_BY]:     DEFAULT_ORDER_BY,
        [OPTIONS_KEY_DIRECTION]:    SORT_ASC,
      }
    })
  }
  const doClearResults = () => setResults([]);
  const doSearch = () => search();
  const toggleInstantInputSearch = () => setInstantInputSearch(current => !current);

  const resetCurrentPage = () => setOptions(currentOptions => {
    return {
      ...currentOptions,
      [OPTIONS_KEY_CURRENT_PAGE]: DEFAULT_CURRENT_PAGE,
    };
  });

  const doSetOption = (option, value, doSearch = false, resetPage = false) => {
    setInstantOptionSearch(doSearch);

    setOptions(currentOptions => {
      return {
        ...currentOptions,
        [option]: value,
      }
    });

    if (resetPage) {
      resetCurrentPage();
    }
  };

  const doToggleOption = (option, doSearch = false, resetPage = false) => {
    doSetOption(option, !options[option], doSearch, resetPage);
  };

  const doSetOrderBy = (orderBy, direction = null) => {
    // If the incoming orderBy value is what is ALREADY being ordered by, we're
    // just going to toggle the direction.
    if (orderBy === options[OPTIONS_KEY_ORDER_BY]) {
      // If we've been given a direction in this call, we'll use that for the
      // updated value. If no direction was supplied, we'll toggle whatever is
      // already set.
      const newDirection = direction ||
        options[OPTIONS_KEY_DIRECTION] === SORT_ASC ? SORT_DESC : SORT_ASC;

      return doSetOption(OPTIONS_KEY_DIRECTION, newDirection, true);
    }

    const newDirection = direction || SORT_ASC;

    doSetOption(OPTIONS_KEY_ORDER_BY, orderBy);
    doSetOption(OPTIONS_KEY_DIRECTION, newDirection, true);
  };

  const doSetOptionsFromParams = (params, doSearch = true) => {
    // We need parameters before we can do anything...
    if (Object.keys(params).length === 0 || params.search === '') {
        return null;
    }

    setInstantOptionSearch(doSearch);

    params = qs.parse(params.search, { ignoreQueryPrefix: true });

    const localSearchString = params.s || '';
    const orderByParts = typeof params.orderBy !== 'undefined' ?
        params.orderBy.split('|') :
        [DEFAULT_ORDER_BY, SORT_ASC];
    const optionParts = typeof params.options !== 'undefined' ?
        params.options.split('&') :
        [];

    let localOptions = {};
    optionParts.map((item, key) => {
        let temp = item.split('=');
        localOptions[temp[0]] = Boolean(Number(temp[1]));
    });

    setSearchString(localSearchString);

    doSetOption(OPTIONS_KEY_ARTIST, localOptions[OPTIONS_KEY_ARTIST]);
    doSetOption(OPTIONS_KEY_ALBUM, localOptions[OPTIONS_KEY_ALBUM]);
    doSetOption(OPTIONS_KEY_EAN, localOptions[OPTIONS_KEY_EAN]);
    doSetOption(OPTIONS_KEY_LABEL, localOptions[OPTIONS_KEY_LABEL]);
    doSetOption(OPTIONS_KEY_GENRE, localOptions[OPTIONS_KEY_GENRE]);
    doSetOption(OPTIONS_KEY_EXACT, localOptions[OPTIONS_KEY_EXACT]);

    doSetOption(OPTIONS_KEY_ORDER_BY, orderByParts[0]);
    doSetOption(OPTIONS_KEY_DIRECTION, orderByParts[1]);
    doSetOption(OPTIONS_KEY_CURRENT_PAGE, params.page);
    doSetOption(OPTIONS_KEY_PER_PAGE, params.size, true);
  };

  const search = () => {
    if (searchString === null || searchString === '') {
      return null;
    }

    let searchOptions = qs.stringify(options)
          .replace(/true/g, '1')
          .replace(/false/g, '0'),
        currentPage   = options.currentPage,
        perPage       = options.perPage,
        orderBy       = `${options.orderBy}|${options.direction}`;

    let searchParams = {
        s:       searchString,
        size:    perPage,
        page:    currentPage,
        orderBy: orderBy,
        options: searchOptions,
    };

    const searchPath = getRoute('search');

    // Since we're searching, if we're not on the search route, we'll redirect to
    // the route with the search parameters massaged into the queryString to
    // provide deep link capability for search results.
    if (location.pathname !== searchPath) {
      redirector('search', {}, qs.stringify(searchParams));
    }

    // Doing another check here on the search parameters. If we're already on
    // the search route but options change, we do need/want to update the history
    // so that the changes are reflected in the queryString.
    if (history.location.search !== qs.parse(location.search, { ignoreQueryPrefix: true })) {
      history.push(`${searchPath}?${qs.stringify(searchParams)}`);
    }

    setIsSearching(true);
    setResults([]);

    return DISH.requests.GET('/search', {
        params: searchParams
    }).then(response => {
      return new Promise(resolve => {
        setPagination({
          currentPage:  response.data._meta.page,
          totalPages:   response.data._meta.totalPages,
          totalResults: response.data._meta.total,
          perPage:      response.data._meta.size,
          nextLink:     response.data._links.next,
          prevLink:     response.data._links.prev
        });

        setResults(response.data.data);
        setIsSearching(false);

        resolve(response);
      });
    });
  };

  useEffect(() => {
    if (!debouncedSearchString || !instantInputSearch) {
      return;
    }

    search();
  }, [debouncedSearchString]);

  /**
   * Whenever the search options change, we're going to do a search() call. UNLESS!
   * If the instantOptionSearch flag is false, we're done working.
   */
  useEffect(() => {
    if (!instantOptionSearch) {
      return;
    }

    search();
  }, [options]);

  return (
    <SearchContext.Provider
      value={{
        doClearResults,
        doClearSearch,
        doSearch,
        doSetOption,
        doSetOptionsFromParams,
        doSetOrderBy,
        doToggleOption,
        doUpdateSearchString,
        isSearching,
        options,
        pagination,
        results,
        searchString,
        setInstantInputSearch,
        toggleInstantInputSearch,
      }}
    >
      {props.children}
    </SearchContext.Provider>
  )
}

export function useSearch() {
  const context = React.useContext(SearchContext)

  if (context === undefined) {
    throw new Error('useSearch must be used within a SearchProvider')
  }

  return context
}
