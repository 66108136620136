import React             from 'react';
import { Route, Routes } from 'react-router-dom';
import routes            from 'routes/routes';

export default function AppRoutes(props)
{
  const { adhocRoutes } = props;

  return renderRoutes(adhocRoutes || routes);
  // return renderProviders(
  //   providers,
  //   () => renderRoutes(adhocRoutes || routes)
  // );
}

function renderProviders(providers, routesCallback, key = 0) {
  if (!providers.length) {
    return routesCallback();
  }

  const Provider = providers.shift();

  return (
    <Provider key={key}>
      {renderProviders(providers, routesCallback, key+1)}
    </Provider>
  )
}

function renderRoutes(routes, incomingPath = '', recurKey = 0) {
  if (!routes) {
    return null
  }

  return Object.keys(routes).map((routeKey, key) => {
    const route = routes[routeKey];
    const path = `${incomingPath}${route.path ?? ''}`
    const children = routes[routeKey].children || null;

    if (route.provider) {
      const providers = Array.isArray(route.provider) ?
        route.provider :
        [route.provider];

      delete route.provider;

      return renderProviders(
        providers,
        () => renderRoutes({[routeKey]: route}),
        key+100
      );
    }

    if (!route.component) {
      return renderRoutes(children, path);
    }

    return (
      <React.Fragment key={key}>
        <Routes>
          {renderSingleRoute(route, path)}
        </Routes>
        {renderRoutes(children, path)}
      </React.Fragment>
    )
  })
}

function renderSingleRoute(route, path) {
  return (
    <Route
      element={route.component}
      exact={route.exact || null}
      path={`${path}`}
    />
  )
}
