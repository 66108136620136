import React            from 'react';
import { NavLink }      from 'react-router-dom';
import { getRoute }     from 'routes/utilities';
import * as DISH        from 'api/dilatedShop';
import Button           from 'components/Utilities/Button';
import useRedirect      from 'routes/useRedirect';
import { useFramework } from 'providers/FrameworkProvider';

const INVOICE_LABEL = 'Confirm & Invoice';
const CHECKOUT_LABEL = 'Checkout';

export default function CartCheckoutButton(props)
{
  const { amountItems, checkoutLabel, classBase, invoiceLabel } = props;
  const { getSettings } = useFramework();
  const redirect = useRedirect();

  const doSubmit = () => {
    DISH.cart.submitOrder()
      .then(orderToken => {
        console.log('orderToken >>', orderToken);
        redirect('orders.order.thanks', {
          orderToken: orderToken,
        });
      })
  }

  if (getSettings('checkout.invoicePurchase') === true) {
    return (
      <div className={`${classBase}--checkout`}>
        <Button
          className="btn--large"
          disabled={amountItems === 0}
          onClick={doSubmit}
          value={invoiceLabel || INVOICE_LABEL}
        />
      </div>
    )
  }

  return (
    <div className={classBase+'--checkout'}>
      <NavLink
        className="btn--large"
        disabled={!amountItems}
        to={getRoute('cart.checkout')}
      >
        {checkoutLabel || CHECKOUT_LABEL}
      </NavLink>
    </div>
  )
}
