import React       from 'react';
import CartListing from 'components/Cart/CartListing';
import CartSummary from 'components/Cart/CartSummary';
import Page        from 'components/Layouts/Page';
import { Header }  from 'components/UI/Typography';

export default function Front(props)
{
  return (
    <Page>
      <div className="columns is-multiline">
        <div className="column is-12">
          <Header>Your Shopping Cart</Header>
          <CartListing />
        </div>
        <div className="column is-3 is-offset-9">
          <CartSummary />
        </div>
      </div>
    </Page>
  )
}
