import React           from 'react';
import { useSelector }     from 'react-redux';
import CartListing     from 'components/Cart/CartListing';
import CartSummary     from 'components/Cart/CartSummary';
import BillingDetails  from 'components/Checkout/BillingDetails';
import ShippingDetails from 'components/Checkout/ShippingDetails';
import PaymentDetails  from 'components/Checkout/PaymentDetails';
import Button          from 'components/Utilities/Button';
import * as DISH       from 'api/dilatedShop';
import history         from 'historyX';
import { getRoute }    from 'routes/utilities';
import Page                 from 'components/Layouts/Page';
import { Header, Text } from 'components/UI/Typography';
import { useCart, STEP_CONFIRMATION } from 'providers/CartProvider';
import useRedirect     from 'routes/useRedirect';

export default function Confirmation(props)
{
  const _checkout  = useSelector(state => state.cartState.checkout);
  const _cart      = useSelector(state => state.cartState);
  const _cartItems = useSelector(state => state.cartState.items);
  const _stage     = useSelector(state => state.cartState.checkout.stage);
  const redirector = useRedirect();

  const { activeStep } = useCart();

  if (activeStep !== STEP_CONFIRMATION) {
    redirector('cart.checkout');
  }

  return (
    <Page>

      <div className="columns is-multiline">
        <div className="column is-6">
          <BillingDetails confirmation={true} />
        </div>

        <div className="column is-6">
          <ShippingDetails confirmation={true} />
        </div>

        <div className="column is-12">
          <Header>Confirm Your Order</Header>

          <Text>We're about to submit your order, please review your purchase.</Text>

          <CartListing confirmation={true} />
        </div>
        <div className="column is-4 is-offset-8">
          <CartSummary confirmation={true} onClick={() => {}} />
          <PaymentDetails confirmation={true} />
        </div>
      </div>
    </Page>
  )
}
