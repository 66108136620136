import React, { useState } from 'react';
import Encrypter            from 'object-encrypter';
import * as DISH            from 'api/dilatedShop';
import useRedirect          from 'routes/useRedirect';

const AuthContext = React.createContext();

const SESSION_KEY    = 'user'
const SESSION_LENGTH = 180;         // Expressed in Mins
const TTL_UNITS      = (60 * 1000); // Expressed in Milliseconds (Set to: 1-min in ms).
const KEY            = 'ewfWE0$0$tJrFQzZXL5Rr7SvpDfsz';
const sessionEncrypt = Encrypter( KEY, { ttl: true } );

export default function AuthProvider(props)
{
  const redirector = useRedirect();

  const doAuthInit = () => {
    return sessionInit();
  }

  const doLogin = (email, password, token) => {
    let data = {
      data: {
        email: email,
        password: password,
        cartToken: token,
      }
    };

    return DISH.requests.POST('/channels/auth/login', data)
      .then(response => {
        if (response.data === false) {
          console.error(response.data.message || defaultMessage);
          return false;
        }

        return sessionStore(response.data);
      });
      // .then(response => {
      //   if (typeof response.cartToken !== 'undefined' &&
      //     response.cartToken !== ''
      //   ) {
      //     return DISH.cart.token(response.cartToken);
      //   }
      // })
      // .then(response => {
      //   // Successful Login, initialize calls for store data.
      //   DISH.framework.initialize();

      //   return new Promise(resolve => {
      //     resolve(response);
      //   });
      // });
  }

  const doLogout = () => {
    sessionRemove();

    redirector('home');
  }

  /**
   * Re-stores the current session to extend the TTL of the encryption. This should
   * be called whenever there's a "browsing" action by the user.
   */
  const doTouch = (key = SESSION_KEY) => {
    return sessionStore(sessionGet(null, key));
  }

  const getAuthData = (item = null, key = SESSION_KEY) => {
    return sessionGet(item, key);
  }

  const isLoggedIn = !!sessionGet();

  return (
    <AuthContext.Provider
      value={{
        doAuthInit,
        doLogin,
        doLogout,
        doTouch,
        getAuthData,
        isLoggedIn,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  )
}

export function useAuth() {
  const context = React.useContext(AuthContext)

  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider')
  }

  return context
}

export function reloadUserThen(callback)
{
  const id    = sessionGet('id');
  const email = sessionGet('email');
  const token = sessionGet('token');

  if (id === false || email === false || token === false) {
    return new Promise((resolve, reject) => {
      reject('No session to reload');
    })
  }

  let data = {
    data: {
      id:    id,
      email: email,
      token: token,
    },
  };

  return DISH.requests.POST('/channels/auth/reload', data)
    .then(response => {
      return sessionStore(response.data.data);
    })
    .catch(error => {
      throw `${error.response.data.data[0]} [${error.response.data.code}]`;
    })
    .finally(response => callback());
}

function sessionInit()
{
  let user = sessionGet();

  if (user === false) {
    sessionRemove();
  }

  // // If there IS a user, start the sessionChecker.
  // if (user !== false) {
  //   sessionCheck();
  // }

  return user;
}

function sessionRemove(key = SESSION_KEY)
{
    localStorage.removeItem(key);

    // clearInterval(window.sessionTimer);
    // window.sessionTimer = null;

    return new Promise(resolve => resolve(true));
}

function sessionStore(data, key = SESSION_KEY, ttl = SESSION_LENGTH)
{
  return new Promise(resolve => {
    // If no user data provided, DONT encrypt and/or store.
    if (data === false) {
      return resolve(false);
    }

    // Defaulting the timezone value to 'America/Toronto' for now. Ultimately,
    // this should come from the user record in the backend, but that's for later.
    data = Object.assign(
      {},
      data,
      { timezone: 'America/Toronto' }
    );

    // Encrypt data
    let item = sessionEncrypt.encrypt(data, (ttl * TTL_UNITS));

    // Store User in Session.
    localStorage.setItem(key, item);

    resolve(data);
  });
}

function sessionGet(item = null, key = SESSION_KEY)
{
  let data = null;

  // Try to get the key asked for out of localStorage. We're expecting the data
  // to be encrypted, so if we can't decrypt it (the way we know how - KEY), we
  // don't have a valid session (someone tried to re-create it?).
  try {
    data = sessionEncrypt.decrypt(localStorage.getItem(key));
  } catch (error) {
    return false;
  }

  // If the key supplied doesn't exist in localStorage, there isn't anything
  // more we can do here.
  if (data === null) {
    return false;
  }

  // If we've been asked for a particular item out of the data we got from
  // localStorage, try to send that back, false otherwise.
  if (item !== null) {
    return data[item] || false;
  }

  // Down here means we've been asked for the whole key, easy.
  return data;
}
