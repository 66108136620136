'use strict';

import React     from 'react';
import * as DISH from 'api/dilatedShop';

class ControlLabel extends React.Component {
    render()
    {
        // If our props say that we don't want to show a label, get outta here.
        if (this.props.hasLabel === false ||
            typeof this.props.label === 'undefined'
        ) {
            return false;
        }

        let label = this.props.label;

        if (this.props.required === true) {
            label += ' <span class="required-marker">*</span>';
        }

        if (typeof this.props.preText !== 'undefined') {
            label += '<span class="description pre-text">' + this.props.preText + '</span>';
        }

        return (
            <label
                htmlFor={DISH.form.getId(this.props)}
                dangerouslySetInnerHTML={{__html: label}}
            />
        )
    }
};

export default ControlLabel;
