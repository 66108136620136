import React from 'react';

export default function SectionHeader(props)
{
    let extraClasses = [];

    if ((props.noUnderline || false) === true) {
        extraClasses.push('no-underline');
    }

    extraClasses = extraClasses.length > 0 ?
        ' ' + extraClasses.join(' ') :
        '';

    return (
        <div className={`sectionheader${extraClasses}`}>
            {props.children || props.title}
        </div>
    )
}
