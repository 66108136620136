import React  from 'react';
import Button from 'components/Utilities/Button';

export default function ButtonPrevious(props)
{
  const { children, onClick, withIcon } = props;

  const icons = withIcon ?
    (
      <>
        <span className="ion-chevron-left"></span>
        <span className="ion-chevron-left"></span>
      </>
    ) :
    null

  const label = children || 'Previous';

  return (
    <Button onClick={onClick}>
      {icons}{label}
    </Button>
  )
}
