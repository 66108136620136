import React  from 'react';

export default function OrderIdentification(props)
{
    const { order } = props;

    const orderCreated = () => {
        if (typeof order.created === 'undefined' || order.created === '') {
            return '';
        }

        return new Date(order.created).toLocaleString(
          'en-us',
          {
            month:'long',
            day: 'numeric',
            year: 'numeric'
          }
        );
    };

    const orderId = () => {
        return order.id || '';
    }

    const orderReference = () => {
        return order.reference || '';
    }

    return (
        <section className="container-order--identification">
            <div className="order-date">Ordered placed: {orderCreated()}</div>
            <div className="order-number">Order #: {orderId()}</div>
            <div className="order-reference textalign-right">Reference #: {orderReference()}</div>
        </section>
    )
}
