import React from 'react';

export default function CategoryListingHeader(props)
{
  if ((props.title || '') === '') {
    return null;
  }

  return (
    <h1 className={`${props.classBase}--header`}>{props.title}</h1>
  );
}
