import React                            from 'react';
import ReactDOM                         from 'react-dom';
import { Provider }                     from 'react-redux';
import { BrowserRouter }                from 'react-router-dom';
import history                          from 'BASE/historyX';
import AppContainer                     from 'components/AppContainer';
import AuthProvider, { reloadUserThen } from 'providers/AuthProvider';
import CartProvider                     from 'providers/CartProvider';
import FormProvider                     from 'providers/FormProvider';
import FrameworkProvider                from 'providers/FrameworkProvider';
import LayoutProvider                   from 'providers/LayoutProvider';
import SearchProvider                   from 'providers/SearchProvider';
import ShopProvider                     from 'providers/ShopProvider';

// We're using Webpack's resolve.modules configuration to first look into the
// LABEL's path for what we're asking, then looking into BASE's path, then finally
// node_modules (so all the npm packages will still load). This is true for all
// imports, not just in this object.
import createStore   from 'store/createStore';

const store = createStore();

const doRender = () => {
  ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter history={history}>
        <AuthProvider>
          <CartProvider>
            <FrameworkProvider>
              <FormProvider>
                <SearchProvider>
                  <ShopProvider>
                    <LayoutProvider>
                      <AppContainer />
                    </LayoutProvider>
                  </ShopProvider>
                </SearchProvider>
              </FormProvider>
            </FrameworkProvider>
          </CartProvider>
        </AuthProvider>
      </BrowserRouter>
    </Provider>,
    document.getElementById('root')
  );
};

reloadUserThen(doRender)
  .catch(error => {
    console.error(error);
    doRender();
  });
