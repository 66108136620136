import React from 'react';

export default function AlbumArt(props)
{
    if ((props.item || '') === '') {
        return null;
    }

    const { item } = props;
    let image      = '';

    switch (props.size) {
        case 'original':
        case 'orig':
        case 'o':
            image = item.images.orig; break;
        case 'cover':
        case 'c':
            image = item.images.cover; break;
        case 'xl':
            image = item.images.xl; break;
        case 'large':
        case 'l':
        default:
            image = item.images.l; break;
        case 'medium':
        case 'm':
            image = item.images.m; break;
        case 'small':
        case 's':
            image = item.images.s; break;
    }

    return (
        <div
            className={`${props.classBase}--image`}
            style={{
              backgroundImage: `url(//${image.url})`,
              backgroundRepeat: 'no-repeat',
            }}
        >
            <img
                src={`//${image.url}`}
                alt={`${item.contributors[0].name} - ${item.name}`}
            />
        </div>
    )
}
