import React, { useCallback, useState, useEffect } from 'react'
import { useForm as useReactHookForm } from 'react-hook-form';
import * as DISH           from 'api/dilatedShop';
import Checkbox            from 'components/Form/Checkbox';
import ControlErrorMessage from 'components/Form/ControlErrorMessage';
import FormErrorMessage    from 'components/Form/FormErrorMessage';
import Input               from 'components/Form/Input';
import Password            from 'components/Form/Password';
import Radio               from 'components/Form/Radio';
import Select              from 'components/Form/Select';
import StripeInput         from 'components/Form/StripeInput';
// import Textarea     from './Textarea';
// import * as helpers from './helpers';

export {
    Checkbox,
    ControlErrorMessage,
    FormErrorMessage,
    Input,
    Password,
    Radio,
    Select,
    StripeInput,
    // Textarea,
    // helpers,
};

const FormContext = React.createContext('derp');

export default function FormProvider(props)
{
  const [currentForm, setCurrentForm] = useState();
  const [forms, setForms] = useState();
  const [formErrors, setFormErrors] = useState();
  const [formValidations, setFormValidations] = useState();

  // Proxying react-hook-form methods out of our own FormProvider. We supplement
  // some functionality on top of react-hook-forms and wanted to keep things
  // cohesive, ergo... proxying.
  const {
    clearErrors,
    formState: { errors },
    handleSubmit,
    register,
    setError,
    watch,
  } = useReactHookForm();

  const formInit = (formId = 'default', validations = {}, initialValues = null) => {
    return new Promise(resolve => {
      // If there are form values for the incoming formId, we'll re-set those.
      let values = initialValues;
      if (values === null) {
        values = (forms && forms[formId]) || {};
      }
      setForms(Object.assign({}, forms, {[formId]: values}));
      // setForms({...forms, [formId]: values});

      setFormErrors(Object.assign({}, formErrors, {[formId]: {}}));
      setFormValidations(Object.assign({}, formValidations, {[formId]: validations}));

      setCurrentForm(formId);

      resolve();
    });
  }

  const formChangeHandler = (event, formId = null) => {
    const theForm = formId || currentForm;

    const id = event.target.id;
    const value = event.target.value;

    let existingForm = forms[theForm];
    existingForm = Object.assign({}, existingForm, {[id]: value});

    setForms(Object.assign({}, forms, {[theForm]: existingForm}));
  }

  const formToggleHandler = (event, formId = null) => {
    const theForm = formId || currentForm;

    const id = event.target.id;
    const value = event.target.checked;

    let existingForm = forms[theForm];
    existingForm = Object.assign({}, existingForm, {[id]: value});

    setForms(Object.assign({}, forms, {[theForm]: existingForm}));
  }

  const formValue = (field, formId = null, defaultValue = null) => {
    const theForm = formId || currentForm;
    const emptyValue = defaultValue || '';

    if (!forms || !forms[theForm]) {
      return emptyValue;
    }

    return forms[theForm][field] || emptyValue;
  }

  const formValues = (formId = null) => {
    const theForm = formId || currentForm;

    if (!forms || !forms[theForm]) {
      return {};
    }

    return forms[theForm];
  }

  const formSetValues = (values = {}, formId = null) => {
    const theForm = formId || currentForm;

    setForms(Object.assign({}, forms, {[theForm]: values}));
  }

  const formValidate = (validations = null, formId = null) => {
    const theForm = formId || currentForm;
    const theValidations = validations || formValidations[theForm] || {};

    const errors = DISH.validate(forms[theForm], theValidations);

    if (!errors) {
      return true;
    }

    setFormErrors(Object.assign({}, formErrors, {[theForm]: errors}));

    return false;
  }

  const formError = (field, formId = null) => {
    const theForm = formId || currentForm;

    if (!forms || !forms[theForm]) {
      return null;
    }

    const theField = formErrors[theForm][field] || null;

    return theField ? theField[0] : null;
  }

  const formSubmit = (callback) => {
    useCallback(() => {
      callback();
    }, [callback]);
  };




  /**
   * Handler of API responses to add error messages into the react-hook-form
   * scope.
   *
   * @param {*} setError
   * @param {*} errorData
   */
  const responseErrors = (setError, errorData, generalField = 'message') => {
    if (errorData[generalField]) {
      setError(
        generalField,
        { type: 'custom', message: errorData[generalField] }
      );
    }

    if (!errorData.errors) {
      return;
    }

    Object.keys(errorData.errors).map(field => {
      setError(field, { type: 'custom', message: errorData.errors[field][0] });
    });
  }

  return (
    <FormContext.Provider
      value={{
        currentForm,
        formChangeHandler,
        formError,
        formErrors,
        formInit,
        forms,
        formSetValues,
        formSubmit,
        formToggleHandler,
        formValidate,
        formValidations,
        formValue,
        formValues,
        setCurrentForm,

        responseErrors,

        errors,
        clearErrors,
        handleSubmit,
        register,
        setError,
        watch,
      }}
    >
      {props.children}
    </FormContext.Provider>
  )
}

export function useForm() {
  const context = React.useContext(FormContext)

  if (context === undefined) {
    throw new Error('useForm must be used within a FormProvider')
  }

  return context
}
