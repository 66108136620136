import React, { useEffect }   from 'react';
import * as DISH              from 'api/dilatedShop';
import DetailsForm            from 'components/Checkout/DetailsForm';
import {
  useCart,
  FORM_BILLING,
  STEP_BILLING
}                             from 'providers/CartProvider'
import { useForm }            from 'providers/FormProvider'
import { billingConstraints } from 'validators/checkout';

export default function BillingDetailsForm(props)
{
  const { nextCheckoutStep } = useCart();
  const { formInit, formValidate } = useForm();
  const { activeStep } = useCart();

  // useEffect(() => {
  //   formInit(
  //     FORM_BILLING,
  //     billingConstraints,
  //     DISH.framework.getSettings('customer')
  //   );
  // }, []);

  // If we're not on the Billing Information gathering step, we'll render nothing.
  if (activeStep !== STEP_BILLING) {
    return null;
  }

  const handleNextStep = () => {
    if (formValidate() === false) {
      DISH.layout.toTop();

      return;
    }

    nextCheckoutStep();
  }

  return (
    <div className="columns is-multiline is-variable is-2">
      <DetailsForm
        formId={FORM_BILLING}
        validations={billingConstraints}
        nextClick={handleNextStep}
      />
    </div>
  )
}
