import React               from 'react';
import { NavLink }         from 'react-router-dom';
import ShopAddToCartButton from 'components/Shop/ShopAddToCartButton';
import { getRoute }        from 'routes/utilities';

export default function CategoryListingItem(props)
{
  const classBase   = 'container-category-list-item';
  const routeName   = props.routeName || 'shop.album';
  const routeParams = props.routeParams || {
    category: props.categoryId,
    genre:    props.item.genre.main.slug,
    artist:   props.item.contributors[0].slug,
    album:    props.item.album.slug,
  };

  return (
    <section className={classBase}>
      <div className="columns">
        <div className="column is-2">
          <Image
            classBase={classBase}
            item={props.item}
            routePath={getRoute(routeName, routeParams)}
          />
        </div>
        <div className="column is-7">
          <Details
            classBase={classBase}
            item={props.item}
            routePath={getRoute(routeName, routeParams)}
          />
        </div>
        <div className="column is-3">
          <div className={`${classBase}--add-button`}>
            <ShopAddToCartButton
              inventoryId={props.item.id}
              size="small"
            />
          </div>
        </div>
      </div>
    </section>
  )
}

function Image(props)
{
  return (
    <div className={`${props.classBase}--image`}>
      <NavLink to={props.routePath}>
        <img src={`//${props.item.images.s.url}`} />
      </NavLink>
    </div>
  )
}

function Details(props)
{
  const { item, item: { album }} = props;

  const publicationYear = album.publishDate?.toString().substring(0, 4);
  const artist    = item.contributors[0]?.name || 'N/A';
  const title     = `${album.title} [${publicationYear}]`;
  const publisher = album.publisher;

  return (
    <div className={`${props.classBase}--details`}>
      <NavLink to={props.routePath}>
        <div className="artist">{artist}</div>
        <div className="title">{title}</div>
        <div className="publisher">{publisher}</div>
      </NavLink>
    </div>
  )
}
