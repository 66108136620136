import React                     from 'react';
import BillingDetailsForm        from 'components/Checkout/BillingDetailsForm';
import DetailsDisplay            from 'components/Checkout/DetailsDisplay';
import DetailsHeader             from 'components/Checkout/DetailsHeader';
import { useCart, FORM_BILLING, STEP_BILLING } from 'providers/CartProvider';

export default function BillingDetails(props)
{
  const { classBase, confirmation, isCompleted } = props;
  const { activeStep, goToStep } = useCart();
  const active = activeStep === STEP_BILLING;

  const classNames = [`${classBase}--billing-details`];

  if (active === true) {
      classNames.push('active');
  }

  if (active === false && isCompleted === false) {
      classNames.push('disabled');
  }

  if (isCompleted === true) {
      classNames.push('completed');
  }

  const editStep = () => goToStep(STEP_BILLING);

  return (
    <article className={classNames.join(' ')}>
      <DetailsHeader
        active={active || confirmation}
        editStep={editStep}
        section="Billing"
        {...props}
      />

      <BillingDetailsForm />

      <DetailsDisplay formId={FORM_BILLING} />
    </article>
  )
}
