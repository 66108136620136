import React from 'react';

export default function LabelValue(props)
{
    const labelClassName = props.labelClassName || 'label';
    const valueClassName = props.valueClassName || 'value';
    const label          = props.label || '';
    const value          = (props.children || props.value) || '';

    return (
        <div className={props.className}>
            <div className={labelClassName}>{label}</div>
            <div className={valueClassName}>{value}</div>
        </div>
    )
}
