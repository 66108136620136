import React         from 'react';
import * as Form     from 'components/Form';
import { useSearch } from 'providers/SearchProvider';

export default function SearchOptions(props)
{
  const { classBase } = props;
  const { doSetOption, doToggleOption, options } = useSearch();

  return (
    <div className="is-flex is-justify-content-space-between">
      <div className="is-flex">
        <div className="columns">
          <div className="column is-narrow">
            <Form.Checkbox
              label="Artist"
              checked={options.artist}
              onChange={() => doToggleOption('artist', true, true)}
            />
          </div>
          <div className="column is-narrow">
            <Form.Checkbox
              label="Album"
              checked={options.album}
              onChange={() => doToggleOption('album', true, true)}
            />
          </div>
          <div className="column is-narrow">
            <Form.Checkbox
              label="EAN / Barcode"
              checked={options.ean}
              onChange={() => doToggleOption('ean', true, true)}
            />
          </div>
          <div className="column is-narrow">
            <Form.Checkbox
              className="spacer"
              label="Label"
              checked={options.label}
              onChange={() => doToggleOption('label', true, true)}
            />
          </div>
        </div>
      </div>
      <Form.Checkbox
        className="spacer"
        label="Exact Word Search"
        checked={options.exact}
        onChange={() => doToggleOption('exact', true, true)}
      />

      <div className="is-flex">
        <div className="columns">
          <div className="column is-narrow is-flex">
            Sort By:
            <Form.Select
              className="ml-2"
              onChange={(event) => doSetOption('orderBy', event.target.value, true, true)}
              options={[
                {value: 'artist', label: 'Artist'},
                {value: 'album', label: 'Album'},
                {value: 'barcode', label: 'EAN / Barcode'},
                {value: 'label', label: 'Label'},
              ]}
              size="small"
              value={options.orderBy}
            />
          </div>
          <div className="column is-narrow is-flex">
            Results Per Page:
            <Form.Select
              className="ml-2"
              onChange={(event) => doSetOption('perPage', event.target.value, true, true)}
              options={[
                {value: 5, label: 5},
                {value: 10, label: 10},
                {value: 25, label: 25},
                {value: 40, label: 40},
              ]}
              size="small"
              value={options.perPage}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
