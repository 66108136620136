import React            from 'react';
import QuantityDropdown from 'components/Utilities/AddToCart/QuantityDropdown';
import Button           from 'components/Utilities/AddToCart/Button';
import IconButton       from 'components/Utilities/AddToCart/IconButton';
import * as DISH        from 'api/dilatedShop';

class AddToCart extends React.Component {
    constructor(props)
    {
        super(props);

        this.state = {
            quantity: 1,
        };
    }

    render()
    {
        return (
            <article className={this._getContainerClasses()}>
                <QuantityDropdown
                    onChange={(event) => { this._setQuantity(event.target.value); }}
                    {...this.props}
                    value={this.state.quantity}
                />
                <Button
                    onClick={() => { this._addToCart(); }}
                    {...this.props}
                />
                <IconButton
                    onClick={() => { this._addToCart(); }}
                    {...this.props}
                />
            </article>
        )
    }

    _getContainerClasses()
    {
        let classNames = ['container-shopaddtocartbutton'];

        if (typeof this.props.fullWidth !== 'undefined') {
            classNames.push('full-width');
        }

        if (typeof this.props.withQuantityDropdown !== 'undefined') {
            classNames.push('with-quantity');
        }

        if (typeof this.props.iconOnly !== 'undefined') {
            classNames.push('icon-only');
        }

        switch (this.props.size) {
            case 'small':
            case 'large':
                classNames.push('small');
                break;
        }

        return classNames.join(' ');
    }

    _setQuantity(quantity) {
        this.setState({
            quantity: quantity
        });
    }

    _addToCart()
    {
        console.log('ADDING OT CART"', this.props.inventoryId, this.state.quantity);
        DISH.cart.add(
            this.props.inventoryId,
            this.state.quantity
        ).then(response => {
            this._setQuantity(1);
        });
    }
}

export default AddToCart;
