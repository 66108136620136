/**
 * Given an array of options used in a Select form element and a label, return
 * the assosciated value. There is an assumption made that the array of options
 * elements are { value: 'blah', label: 'marklar' }
 *
 * @param  {array} options
 * @param  {string} label
 * @return {string}
 */
export const valueFromOptions = (options, label) => {
  // Loop the options given and check each label to see if it matches what we've
  // been told to find. Return that if there's a match.
  return options.map((item, key) => {
    if (item.label === label) {
      return item.value;
    }
  });
}

/**
 * Given an array of options used in a Select form element and a value, return
 * the assosciated label. There is an assumption made that the array of options
 * elements are { value: 'blah', label: 'marklar' }
 *
 * @param  {array} options
 * @param  {string} value
 * @return {string}
 */
export const labelFromOptions = (options, value) => {
  // Loop the options given and check each value to see if it matches what we've
  // been told to find. Return that if there's a match.
  return options.map((item, key) => {
    if (item.value === value) {
      return item.label;
    }
  });
}
