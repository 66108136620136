import React           from 'react';
import CartListingItem from 'components/Cart/CartListingItem';
import { useCart }     from 'providers/CartProvider';

const classBase       = 'container-cartlisting';
const headerClassName = `${classBase}--list-header`;

export default function CartListing(props)
{
  const { confirmation } = props;
  const { items: cartItems } = useCart();

  const doRenderCartItems = () => {
    if (cartItems.length === 0) {
      return <div className="textalign-center margin-decent--ends">
        There are no items in your Shopping Cart
      </div>;
    }

    return cartItems.map((item, key) => {
      return (
        <CartListingItem
          key={key}
          item={item}
          confirmation={confirmation}
        />
      )
    });
  }

    return (
      <article className={classBase}>
        <section className={`${classBase}items`}>
          <article className={headerClassName}>
            <div className={headerClassName+'--product'}>Product</div>
            <div className={headerClassName+'--quantity'}>Quantity</div>
            <div className={headerClassName+'--remove'}></div>
            <div className={headerClassName+'--price'}>Price</div>
            <div className={headerClassName+'--total'}>Total</div>
          </article>

          {doRenderCartItems()}
        </section>
      </article>
    )
}
