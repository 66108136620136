import React                from 'react';
import CategoryListingItem  from 'components/Shop/CategoryListing/CategoryListingItem';

export default function CategoryListingItems(props)
{
  if ((props.items || []).length === 0) {
    return null;
  }

  const { categoryId, classBase, items } = props;

  return (
    <article className={`${classBase}--items`}>
      {items.map((item, key) => {
        return (
          <CategoryListingItem
            categoryId={categoryId}
            item={item}
            key={key}
          />
        )
      })}
    </article>
  )
}
