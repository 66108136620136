import React       from 'react';
import { NavLink } from 'react-router-dom';

export default function PageFooter(props)
{
  const { footerLinks } = props;

  return (
    <footer className="footer-page">
      <section className="footer-page--content">
        <FooterLinks footerLinks={footerLinks} />

        <div className="footer-page--content-copyright">
          Copyright &copy; 2022 Isotope Music Inc., All rights reserved.
        </div>
      </section>
    </footer>
  )
}

function FooterLinks(props)
{
  const { footerLinks } = props;

  if (footerLinks !== undefined && footerLinks === false) {
    return null;
  }

  return(
    <>
      <div className="footer-page--content-shipping">
        <NavLink
          to="/shipping-returns"
        >Shipping & Returns</NavLink>
      </div>
      <div className="footer-page--content-about">
        <a
          href="https://isotopemusic.com/about.html"
          target="_blank"
        >About Us</a>
      </div>
      <div className="footer-page--content-privacy">
        <NavLink
          to="/privacy-policy"
        >Privacy Policy</NavLink>
      </div>
      <div className="footer-page--content-cart">
        <NavLink
          to="/cart"
        >Shopping Cart</NavLink>
      </div>
      <div className="footer-page--content-contact">
        <a
          href="https://isotopemusic.com/contact.html"
          target="_blank"
        >Contact Us</a>
      </div>
    </>
  )
}
