'use strict';

import { storeDispatch, storeTypes }
                   from 'store/createStore';
import * as DISH   from 'api/dilatedShop';
import * as stripe from 'api/paymentsStripe';
import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';

const paymentObject = stripe;

export function initialize()
{
    return paymentObject.initialize();
}

export function processPaymentFields(CardElement)
{
    var stripe   = Stripe(process.env.STRIPE_KEY);
    var elements = stripe.elements();

    if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        console.log('SHORT CIRCUIT');
        return;
    }

    const result = stripe.confirmCardPayment('{CLIENT_SECRET}', {
        payment_method: {
            card: elements.getElement(CardElement),
                billing_details: {
                name: 'Jenny Rosen',
            },
        }
    });

    return paymentObject.processPaymentFields();
}

export function storePaymentIdentifiers(identifiers = null)
{
    return paymentObject.storePaymentIdentifiers(identifiers);
}

export function isEmpty()
{
    return paymentObject.isEmpty();
}

const typeFieldMapping = {
    incomplete_number:         'cc_cardnumber',
    invalid_number:            'cc_cardnumber',
    incomplete_expiry:         'cc_expiry',
    invalid_expiry_year:       'cc_expiry',
    invalid_expiry_month_past: 'cc_expiry',
    incomplete_cvc:            'cc_cvc',
}

/**
 * Maps a Stripe-related error, based on the err.error.code to a Stipe field on
 * the checkout form. This is to get any Stripe errors into ControlErrorMessage
 * component for the proper field, by way of updateCheckoutErrorFields().
 *
 * @param {Object} err
 */
export function displayError(err)
{
    let code    = err.error.code;
    let message = err.message;
    let field   = typeFieldMapping[code];

    // This should really only be in place for debugging. The errors that could come
    // back from generating a token should be displayed inside the respective field,
    // just as any form element is done. This is currently being used to "catch-all"
    // that have not been handled in the field mapping to get the error from Stripe
    // into the proper field.
    let displayError = document.getElementById('stripeError');
    displayError.innerHTML = err.message+' : '+err.error.code;

    DISH.cart.updateCheckoutErrorFields({
        [field]: [ message ],
    });
}
