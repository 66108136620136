import React        from 'react';
import Page         from 'components/Layouts/Page'
import {
  FormErrorMessage,
  Input,
  Password,
  useForm
} from 'providers/FormProvider';
import useRedirect  from 'routes/useRedirect';
import { useAuth }  from 'providers/AuthProvider';
import { useToken } from 'providers/CartProvider';

export default function Login(props)
{
  const redirect = useRedirect();
  const {
    errors,
    handleSubmit,
    register,
    responseErrors,
    setError,
  } = useForm();
  const { doLogin, doLogout } = useAuth();
  const { logout } = props;

  if (logout) {
    doLogout();
    return null;
  }

  const onSubmit = (loginData) => {
    const token = useToken();

    doLogin(loginData.email, loginData.password, token)
      .then(() => redirect('home'))
      .catch(error => responseErrors(setError, error.response.data))
  }

  return (
    <Page footerLinks={false}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="columns is-multiline mt-6">
          <div className="column is-4 is-offset-4">
            <div className="columns is-multiline">
              <div className="column is-12">
                <Input
                  label="Email"
                  name="email"
                  register={register}
                  error={errors.email}
                />
              </div>
              <div className="column is-12">
                <Password
                  label="Password"
                  name="password"
                  register={register}
                  error={errors.password}
                />
              </div>
              <div className="column is-12 is-flex is-justify-content-center">
                <button
                  className="btn btn--large"
                  type="submit"
                >Login</button>
              </div>
              <FormErrorMessage register={register} error={errors} />
            </div>
          </div>
        </div>
      </form>
    </Page>
  )
}
