import { createStore, applyMiddleware, combineReducers }
                             from 'redux';
import { createLogger }      from 'redux-logger';
import reducers              from 'store/reducers';
import storeTypes            from 'store/reducers/storeTypes';

export default function configureStore(initialState = {}) {
    const createStoreWithMiddleware = process.env.NODE_ENV == 'development' ?
        applyMiddleware(
            createLogger()
        )(createStore) :
        applyMiddleware(
        )(createStore);

    const store = createStoreWithMiddleware(
        combineReducers(reducers),
        initialState
    );

    window.store = store;

    return store;
}

export function storeDispatch(type, data, callback)
{
    if (typeof callback !== 'undefined') {
        var callbackHolder = window.store.subscribe(callback);
    }

    window.store.dispatch({type: type, data: data});

    if (typeof callback !== 'undefined') {
        callbackHolder();
    }
}

function myGet(obj, path, def) {
	var fullPath = path
		.replace(/\[/g, '.')
		.replace(/]/g, '')
		.split('.')
		.filter(Boolean);

	return fullPath.every(everyFunc) ? obj : def;

	function everyFunc(step) {
		return !(step && (obj = obj[step]) === undefined);
	}
}

export function storeGet(property)
{
    if (typeof property === 'undefined') {
        return window.store;
    }

    return myGet(window.store.getState(), property);
}

export function storeReady(property, zeroLengthOk = false)
{
    let thing = storeGet(property);

    if (typeof thing === 'undefined') {
        return false;
    }

    if (
        zeroLengthOk === false &&
        thing.length === 0
    ) {
        return false;
    }

    return true;
}

export {
    storeTypes,
}
