'use strict';

import React                  from 'react';
import PropTypes              from 'prop-types';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions }            from 'redux-router5';
import { storeReady, storeGet }         from 'store/createStore';
import * as DISH              from 'api/dilatedShop';
import Link                   from 'components/Utilities/Link';

const classBase = 'container-genres';

class ShopGenres extends React.Component {
    constructor(props, context)
    {
        super(props);
        this.router = context.router;
    }

    render()
    {
        return (
            <article className={classBase}>
                <h1 className={classBase+'--header'}>Genres</h1>
                {this._displayGenres()}
            </article>
        )
    }

    _displayGenres()
    {
        // This component relies on a categorySlug and the genres to be present
        // in the store before it can paint anything to the page.
        if (
            storeReady('shopState.categorySlug') === false ||
            storeReady('shopState.genres') === false
        ) {
            return null;
        }

        const { navigateTo } = this.props;

        return this.props._genres.map((item, key) => {
            return (
                <div
                    key={key}
                    className={classBase+'--genre'}
                >
                    <Link
                        className={this._getClasses(item.slug)}
                        router={this.router}
                        navigateTo={navigateTo}
                        routeName='shop.genre'
                        routeParams={{
                            category: this.props._categorySlug,
                            genre: item.slug
                        }}
                        routeOptions={{ replace: true, reload: true }}
                    >{item.genre}</Link>
                </div>
            )
        });
    }

    _getClasses(genreSlug)
    {
        // let classNames = [classBase+'--genre'];
        let classNames = ['btn--large-heavy plain flat'];

        if (genreSlug === this.props._genreSlug) {
            classNames.push('active');
        }

        return classNames.join(' ');
    }
};

ShopGenres.contextTypes = {
    router: PropTypes.object.isRequired
};

const mapStateToProps = function(store)
{
    return {
        _categorySlug: store.shopState.categorySlug,
        _genres:       store.shopState.genres,
        _genreSlug:    store.shopState.genreSlug,
    };
}

export default connect(
    mapStateToProps,
    dispatch => bindActionCreators({ navigateTo: actions.navigateTo }, dispatch)
)(ShopGenres);
