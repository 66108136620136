'use strict';

export const resolveActiveCategoryId = (category, state) => {
    let categoryId   = null;
    let categoryName = '';
    let categorySlug = '';

    state.categories.map((item, key) => {
        if (item.slug === category) {
            categoryId   = item.id;
            categoryName = item.category;
            categorySlug = item.slug;
        }
    });

    return {
        categoryId:   categoryId,
        categoryName: categoryName,
        categorySlug: categorySlug
    };
};

export const resolveActiveGenreId = (genre, state) => {
    let genreId   = null;
    let genreName = '';
    let genreSlug = '';

    state.genres.map((item, key) => {
        if (item.slug === genre) {
            genreId   = item.id;
            genreName = item.genre;
            genreSlug = item.slug;
        }
    });

    return {
        genreId:   genreId,
        genreName: genreName,
        genreSlug: genreSlug
    };
};

// export const resolveAlbum = (data, state) => {
//     console.log('RESOLVE ALBUM: ', data);
//     return {
//         artist: {
//             id:   data.contributors[0].id,
//             name: data.contributors[0].name,
//             slug: data.contributors[0].slug,
//         },
//         // categories: data.categories,
//         genre: {
//             id:   data.genres.main.main.id,
//             name: data.genres.main.main.genre,
//             slug: data.genres.main.main.slug,
//         },
//         // media:             data.media,
//         id:                data.id,
//         name:              data.title,
//         slug:              data.slug,
//         label:             data.publisher,
//         images:            data.images,
//         short_description: data.short_description || '',
//         full_description:  data.full_description || '',
//     };
// };

export const cgiIdentifier = (identifier, state) => {
    let data = state.categoryGenreIdentifier;

    data.identifier = identifier;

    return data;
};

export const cgiToggle = (state) => {
    let data = state.categoryGenreIdentifier;

    data.open = !data.open;
    return data;
};
