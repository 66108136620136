import React, { useEffect, useState } from 'react'
import * as DISH                      from 'api/dilatedShop';

const OrderContext = React.createContext(undefined);

export default function OrderProvider(props)
{
  const [orderToken, setOrderToken] = useState();
  const [order, setOrder] = useState();

  const getOrder = (orderToken) => {
    if (!orderToken) {
      return null;
    }

    setOrderToken(orderToken);

    console.log('orderToken >>', orderToken);
    return DISH.requests.GET(`/orders/${orderToken}`)
      .catch(error => {
        return new Promise(resolve => {
          console.error(error);
          resolve()
        });
      })
      .then(response => {
        return new Promise(resolve => {
          setOrder(response.data.data);
          resolve()
        });
      });
  }









  return (
    <OrderContext.Provider
      value={{
        getOrder,
        order,
        orderToken,
      }}
    >
      {props.children}
    </OrderContext.Provider>
  )
}

export function useOrder() {
  const context = React.useContext(OrderContext)

  if (context === undefined) {
    throw new Error('useOrder must be used within a OrderProvider')
  }

  return context
}
