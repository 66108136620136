import React from 'react';

export default function Button(props)
{
    if (typeof props.iconOnly !== 'undefined') {
        return null;
    }

    return (
        <button
            className={getButtonClasses(props)}
            onClick={props.onClick}
        >
            {props.buttonLabel || 'Add To Cart'}
        </button>
    )
}


function getButtonClasses(props)
{
    let classNames = ['btn'];

    switch (props.size) {
        case 'small':
        case 'large':
            classNames.push(`btn--${props.size}`);
            break;
    }

    return classNames.join(' ');
}
