import React               from 'react';
import ControlErrorMessage from 'components/Form/ControlErrorMessage';
import ControlLabel        from 'components/Form/ControlLabel';
import * as DISH           from 'api/dilatedShop';

class Radio extends React.Component {
    render()
    {
        let id   = DISH.form.getId(this.props),
            name = DISH.form.getName(this.props);

        if (typeof id === 'undefined') {
            id = DISH.form.getRandomId();
        }

        return (
            <article className={DISH.form.getClassNames(this.props)}>
                <input
                    checked={this.props.checked || false}
                    id={id}
                    name={name}
                    onChange={this.props.onChange || null}
                    type="radio"
                    value={this.props.value || ''}
                />
                <ControlLabel id={id} name={name} {...this.props} />
                <ControlErrorMessage {...this.props} />
            </article>
        )
    }
};

export default Radio;
