'use strict';

import React          from 'react';
import PropTypes      from 'prop-types';
import { connect }    from 'react-redux';
import ShopSidebar    from 'components/Shop/ShopSidebar';
import ShopListing    from 'components/Shop/ShopListing';
import * as DISH      from 'api/dilatedShop';

class Category extends React.Component {
    constructor(props, context)
    {
        super(props);
        this.router = context.router;
    }

    componentDidMount()
    {
        // When mounting the component, we'll want to ensure we end up with the
        // data from the backend proper, so send the flag to force the grab of
        // the important bits.
        DISH.shop.initialize(true);
    }

    componentWillReceiveProps(nextProps)
    {
        // If the incoming prop for the categoryId is 0, this means that when
        // loading all the categories for the shop is done, the requested
        // category could not be found (someone is asking for a category that
        // doesn't exist...), so we'll redirect to the Shop's front page.
        if (
            nextProps._categoryId !== this.props._categoryId &&
            nextProps._categoryId === 0
        ) {
            DISH.framework.redirect(this.router, 'shop');
        }

        // If we got new props and the route is differnet than it was before,
        // we likely need to re-initialize the shop to set the active category.
        // We don't need to re-grab the categories from the backend however, just
        // to initialize the category change.
        if (nextProps.route !== this.props.route) {
            DISH.shop.initialize();
        }
    }

    render()
    {
        return (
            <div className="grid">
                <section className="column-12 column-sm-3">
                    <ShopSidebar
                        categorySlug={this.props._categorySlug}
                    />
                </section>
                <section className="column-12 column-sm-9">
                    <ShopListing
                        categoryId={this.props._categoryId}
                        origin="category"
                    />
                </section>
            </div>
        )
    }
};

Category.contextTypes = {
    router: PropTypes.object.isRequired,
};

const mapStateToProps = function(store)
{
    return {
        route:         store.router.route,
        _categoryId:   store.shopState.categoryId,
        _categorySlug: store.shopState.categorySlug,
        _genreId:      store.shopState.genreId,
    };
}

export default connect(mapStateToProps)(Category);
