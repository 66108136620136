import React        from 'react';
import Details      from 'components/Shop/Album/Details';
import AlbumArt     from 'components/Shop/Album/AlbumArt';
import MoreFromLink from 'components/Shop/Album/MoreFromLink';

const classBase = 'container-shop-album';

export default function Album(props)
{
  const { item } = props;

  if (!item) {
    return null;
  }

  return (
    <div className="columns">
      <div className="column is-8">
        <Details item={item} />
      </div>
      <div className="column is-4">
        <div className="columns is-multiline">
          <div className="column is-12 is-flex is-justify-content-center">
            <AlbumArt
              classBase={classBase}
              item={item}
            />
          </div>
          <div className="column is-12 is-flex is-justify-content-center">
            <MoreFromLink
              artist={item.contributors[0].name}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

// _displayAvailableFormats(item)
// {
//     let formats = [];
//     let basePrice = item.media[this.state.selectedMedia].price;

//     item.media.map((media, key) => {
//         formats.push({
//             type:  media.mediaType,
//             price: media.price,
//         });
//     });

//     return (
//         <div className={classBase+'--formats margin-decent--bottom'}>
//             <select
//                 onChange={this.clickFormat.bind(this)}
//             >
//                 {formats.map((format, key) => {
//                     let priceDiff = basePrice - format.price;
//                     if (priceDiff < 0) {
//                         priceDiff = '[ add $' + DISH.utilities.formatPrice(priceDiff * -1) + ']';
//                     }
//                     if (priceDiff === 0) {
//                         priceDiff = '';
//                     }
//                     if (priceDiff > 0) {
//                         priceDiff = '[ sub $' + DISH.utilities.formatPrice(priceDiff) + ']';
//                     }

//                     return (
//                         <option
//                             key={key}
//                             value={key}
//                         >{key}: {format.type} {priceDiff}</option>
//                     )
//                 })}
//             </select>
//         </div>
//     )
// }
