import React        from 'react';
import { NavLink }  from 'react-router-dom';
import { getRoute } from 'routes/utilities';

export default function AlbumCoverLink(props)
{
  const {artistSlug, albumSlug, images} = props;

  let size = '';

  if (size && props.small) {
    size = 's';
  }
  if (size && props.medium) {
    size = 'm';
  }
  if (size && props.large) {
    size = 'l';
  }
  if (size && props.xlarge) {
    size = 'xl';
  }
  if (size && props.cover) {
    size = 'cover';
  }
  if (size && props.original) {
    size = 'orig';
  }

  // Default size if none is resolved out of props
  if (!size) {
    size = 'm';
  }

  return (
    <NavLink
      to={getRoute('shop.album', {
          artist: artistSlug,
          album:  albumSlug,
      })}
    >
      <AlbumImage images={images} size={size} />
    </NavLink>
  )
}

function AlbumImage(props)
{
  const {images, size} = props;

  if (!images || !images[size]) {
    return 'No Image';
  }

  return <img src={`//${images[size].url}`} />
}
