import React, { useEffect, useState }
                         from 'react';
import config            from 'config/config';
import FeaturedItemPanel from 'components/Shop/FeaturedItemPanel';
import {
  useShop,
  KEY_FEATURED_ITEMS,
}                        from 'providers/ShopProvider';

const classBase = 'container-featured-items';

export default function FeaturedItems(props)
{
  const [activePanel, setActivePanel] = useState(0);
  const { categoryInventory, inventory } = useShop();

  useEffect(() => {
    categoryInventory(
      config.categories.featured,
      { perPage: 8, inventoryKey: KEY_FEATURED_ITEMS }
    );
  }, []);

  return (
    <section className={classBase}>
      <h1 className={`${classBase}--header`}>Featured Items</h1>

      <Items
        activePanel={activePanel}
        featuredItems={inventory[KEY_FEATURED_ITEMS]}
      />
      <Indicators
        activePanel={activePanel}
        featuredItems={inventory[KEY_FEATURED_ITEMS]}
        setActivePanel={setActivePanel}
      />
    </section>

  )
}

function Items(props) {
  const { activePanel, featuredItems } = props;

  if (!featuredItems || featuredItems.length === 0) {
    return null;
  }

  return (
    <article className={`${classBase}--panels`}>
      {featuredItems.map((item, key) => {
        return (
          <FeaturedItemPanel
            key={key}
            item={item}
            active={key === activePanel}
          />
        )
      })}
    </article>
  )
}

function Indicators(props) {
  const { activePanel, featuredItems, setActivePanel } = props;

  if (!featuredItems || featuredItems.length === 0) {
    return null;
  }

  return (
    <article className={`${classBase}--indicators grid`}>
      <div className="column-12 justify-center">
      {featuredItems.map((item, key) => {
        return (
          <div
            className={'indicator' + (key === activePanel ? ' active' : '')}
            key={key}
            onMouseEnter={() => setActivePanel(key)}
          />
        )
      })}
      </div>
    </article>
  );
}
