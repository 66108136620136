import React       from 'react';
import { NavLink } from 'react-router-dom';
import { useAuth } from 'providers/AuthProvider';
import { useCart } from 'providers/CartProvider';

export default function HeaderNavigation(props)
{
  const { doLogout, isLoggedIn } = useAuth();
  const { count: itemsCount } = useCart();

  // No session? No navigation to render.
  if (!isLoggedIn) {
    return null;
  }

  const handleLogout = () => doLogout();

  const getCartClassName = () => {
    let classNames = ['ion-ios-cart', 'header-cart-link'];

    if (itemsCount > 0) {
      classNames.push('full');
    }

    return classNames.join(' ');
  };

  return (
    <nav className="header-page--nav">
      <ul>
        <li>
          <NavLink to="/cart">
            <i className={getCartClassName()} /> Cart ({itemsCount || 0})
          </NavLink>
        </li>
        <li className="header-logout-link">
          <span className="is-clickable" onClick={handleLogout}>
            Logout <i className="ion-ios-log-out" />
          </span>
        </li>
      </ul>
    </nav>
  )
}
