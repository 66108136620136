import React     from 'react';
import PropTypes from 'prop-types';

function Link(props) {
    const {
        routeName,
        routeParams,
        routeOptions,
        routeCallback,
        router,
        navigateTo,
        anchor,
        innerHTML
     } = props;


    let href = router.buildUrl(routeName, routeParams);

    if (typeof anchor !== 'undefined') {
        href = href + '#' + anchor;
    }

    let handleClick = (event) => {
        event.preventDefault();
        navigateTo(routeName, routeParams, routeOptions, routeCallback);

        if (typeof props.onClick !== 'undefined') {
            props.onClick();
        }
    };
    let onClick = handleClick;

    const className = router.isActive(routeName, routeParams, true, false) ?
        'active '+props.className :
        props.className;

    if (typeof innerHTML !== 'undefined') {
        return <a
            { ...{ href, onClick, className } }
            dangerouslySetInnerHTML={{__html: innerHTML }}
        />
    }

    return <a { ...{ href, onClick, className } }>{ props.children }</a>
}

Link.propTypes = {
    routeName:    PropTypes.string.isRequired,
    routeParams:  PropTypes.object,
    routeOptions: PropTypes.object,
    navigateTo:   PropTypes.func.isRequired
};

Link.defaultProps = {
    routeParams:   {},
    routeOptions:  {},
    routeCallback: {},
    classname:     null
};

export default Link;
