'use strict';

import React, { createElement } from 'react';
import { connect }              from 'react-redux';
import * as DISH                from 'api/dilatedShop';
import * as Pages               from 'components/Pages/Shop/index';

const routeComponents = {
    'shop':          { component: Pages.Front },
    'shop.album':    { component: Pages.Album },
    'shop.category': { component: Pages.Category },
    'shop.genre':    { component: Pages.Genre },
};

class Shop extends React.Component {
    render()
    {
        const { route } = this.props;
        const component = DISH.framework.resolveRouteComponent(
            route,
            routeComponents
        );

        return (
            <section className="container-shop">
                {createElement(
                    component.component,
                    component.props
                )}
            </section>
        )
    }
};

const mapStateToProps = function(store)
{
    return {
        route: store.router.route,
    };
}

export default connect(mapStateToProps)(Shop);
