import React                     from 'react';
import { NavLink }               from 'react-router-dom';
import ShopAddToCartButton       from 'components/Shop/ShopAddToCartButton';
import { getRoute, searchRoute } from 'routes/utilities';
import AlbumCoverLink            from 'components/Utilities/AlbumCoverLink';
import NewlineText               from 'components/Utilities/NewlineText';

const classBase = 'container-featured-item-panel';

export default function FeaturedItemPanel(props)
{
  const { active, item } = props;
  const classNames = [classBase];

  if (active === true) {
    classNames.push('active');
  }

  return (
    <section className={classNames.join(' ')}>
      <div className={`${classBase}--image`}>
        <AlbumCoverLink
          artistSlug={item.contributors[0].slug}
          albumSlug={item.album.slug}
          images={item.images}
        />
      </div>
      <div className={`${classBase}--details`}>
        <h2>
          <NavLink
            to={searchRoute(item.contributors[0].name)}
          >{item.contributors[0].name}</NavLink> - <NavLink
            to={getRoute('shop.album', {
                artist: item.contributors[0].slug,
                album:  item.album.slug,
            })}
          >{item.album.title}</NavLink>
        </h2>
        <div className={`${classBase}--description`}>
          <NewlineText>
            {item.album.description || ''}
          </NewlineText>
        </div>
        <ShopAddToCartButton inventoryId={item.id} />
      </div>
    </section>
  )
}
