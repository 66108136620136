import * as DISH  from 'api/dilatedShop';
import { storeDispatch, storeGet, storeReady, storeTypes }
                  from 'store/createStore';
import config     from 'config/config';
import qs         from 'qs';

import history    from "BASE/historyX";

export function initialize(force = false, options = {}, location = {})
{
    // let route          = storeGet('router.route');
    let activeCategory = false;
    let activeGenre    = false;
    let artistSlug     = false;
    let albumSlug      = false;

    // switch (route.name) {
    switch (location.pathname) {
        // case 'shop':
        case '/':
            activeCategory = '';
            activeGenre    = '';
            break;
        case 'shop.category':
            activeCategory = route.params.category;
            activeGenre    = '';
            break;
        case 'shop.genre':
            activeCategory = route.params.category;
            activeGenre    = route.params.genre;
            break;
        case 'shop.album':
            activeCategory = route.params.category;
            activeGenre    = route.params.genre;
            artistSlug     = route.params.artist;
            albumSlug      = route.params.album;
            break;
    }

    if (activeCategory === false &&
        typeof options.activeCategory !== 'undefined'
    ) {
        activeCategory = options.activeCategory;
    }

    // Get the categories out of the SHOP api, passing the "active" category.
    // This is a string at this point, so some translation happens in the api
    // method to turn it into an ID that is saved in the store.
    this.getCategories(
        activeCategory,
        force
    );

    // Get the genres out of the SHOP api, passing the "active" category.
    // This is a string at this point, so some translation happens in the api
    // method to turn it into an ID that is saved in the store.
    this.getGenres(
        activeGenre,
        force
    );

    this.getAlbum(
        artistSlug,
        albumSlug,
        force
    );
}

// /**
//  * Clears the "searching" mode.
//  */
// export function searchClear()
// {
//     storeDispatch(storeTypes.SHOP.UPDATE_SEARCH, {
//         searching: false,
//         string:    '',
//         results:   [],
//     });

//     DISH.layout.curtainClose();
// }

// export function searchStart(searchString)
// {
//     return new Promise((resolve, reject) => {
//         storeDispatch(
//             storeTypes.SHOP.UPDATE_SEARCH,
//             {
//                 searching: true,
//                 string:    searchString,
//                 results:   []
//             },
//             () => { resolve(); }
//         );
//     });
// }
// /**
//  * Mean and potatoes of the searching. Exporting a constant so it can be used in
//  * a debouncing strategy. Don't want the browser to fire off network calls on
//  * every key press.
//  *
//  * @param {string} searchString
//  */
// export const doSearch = (searchString = null) => {
//     if (searchString === null || searchString === '') {
//         searchClear();
//         return null;
//     }

//     let options       = storeGet('shopState.search.options'),
//         searchOptions = qs.stringify(options)
//             .replace(/true/g, '1')
//             .replace(/false/g, '0'),
//         currentPage   = storeGet('shopState.pagination.currentPage'),
//         perPage       = options.perPage,
//         orderBy       = `${options.orderBy}|${options.direction}`;

//     let searchParams = {
//         s: searchString,
//         size: perPage,
//         page: currentPage,
//         orderBy: orderBy,
//         options: searchOptions,
//     };

//     // if (router !== null && storeGet('router.route').name !== 'search') {
//     if (history.location.pathname !== '/search') {

//         history.push(`/search?${qs.stringify(searchParams)}`);
//     // if (router !== null && router.location.pathname == '/search') {
//     //     router.push(`/search?${qs.stringify(searchParams)}`);
//         // DISH.framework.redirect(
//         //     router,
//         //     'search',
//         //     searchParams
//         // );
//     }

//     // Replace the history state with our search params for deep linking.
//     // if (router !== null) {
// //     if (history.location.pathname === '/search') {
// // console.log('IS /search, DEEP LINK ENABLING');
// //         // router.replaceHistoryState('search', searchParams);
// //         history.replace(`/search?${qs.stringify(searchParams)}`);
// //     }

//     return searchStart(searchString)
//         .then(response => {
//             console.log('HERE WE ARE BLEIMY');
//             return DISH.requests.GET('/search', {
//                 params: searchParams
//             });
//         })
//         .then(response => {
//             return searchPagination(response);
//         })
//         .then(response => {
//             return searchResults(response);
//         });
// };

// /**
//  * Defining the constant that is called by the application. This is where the
//  * debouncing happens. Has to be AFTER the doSearch method, so that it exists
//  * before it's referenced.
//  */
// export const search = debounce(doSearch, 150);

// /**
//  * Takes a response from a search request and provides required data to update
//  * pagination data in the shop store.
//  *
//  * @param {object} response
//  */
// export function searchPagination(response)
// {
//     return new Promise((resolve, reject) => {
//         storeDispatch(
//             storeTypes.SHOP.UPDATE_PAGINATION,
//             {
//                 currentPage:  response.data._meta.page,
//                 totalPages:   response.data._meta.totalPages,
//                 totalResults: response.data._meta.total,
//                 perPage:      response.data._meta.size,
//                 nextLink:     response.data._links.next,
//                 prevLink:     response.data._links.prev
//             },
//             () => { resolve(response); }
//         );
//     });
// }

// export function searchResults(response)
// {
//     return new Promise((resolve, reject) => {
//         storeDispatch(
//             storeTypes.SHOP.UPDATE_SEARCH_RESULTS,
//             response.data.data,
//             () => { resolve(response); }
//         );
//     });
// }

// export function searchOptionsFromParams(params, doSearchFlag = true)
// {
//     // If we don't have any search parameters coming in, we're done.
//     if (Object.keys(params).length === 0 || params.search === '') {
//         return null;
//     }

//     params = qs.parse(params.search, { ignoreQueryPrefix: true });

// console.log('HERE WE ARE DERP', Object.keys(params));
//     let stateSearchData     = storeGet('shopState.search');
//     let statePaginationData = storeGet('shopState.pagination');

// // https://api.dilatednetwork.com/search?s=nas&size=10&page=1&orderBy=album%7Cdesc&options=artist%3D0%26album%3D0%26ean%3D1%26label%3D0%26genre%3D0%26exact%3D0%26perPage%3D10%26orderBy%3Dalbum%26direction%3Ddesc

//     let searchString = params.s || '';
//     let orderByParts = typeof params.orderBy !== 'undefined' ?
//         params.orderBy.split('|') :
//         ['artist', stateSearchData.options.direction];
//     let optionParts = typeof params.options !== 'undefined' ?
//         params.options.split('&') :
//         [];

//     let options = {};
//     optionParts.map((item, key) => {
//         let temp = item.split('=');
//         options[temp[0]] = Boolean(Number(temp[1]));
//     });

// console.log('ding: ', options);
//     let searchData = {
//         string: searchString,
//         options: {
//             artist:    Boolean(Number(options.artist || 0)),
//             album:     Boolean(Number(options.album || 0)),
//             ean:       Boolean(Number(options.ean || 0)),
//             label:     Boolean(Number(options.label || 0)),
//             genre:     Boolean(Number(options.genre || 0)),
//             exact:     Boolean(Number(options.exact || 0)),
//             direction: orderByParts[1],
//             orderBy: orderByParts[0] || stateSearchData.orderBy,
//             perPage: params.size || stateSearchData.options.perPage,
//         }
//     };

//     let paginationData = {
//         currentPage: params.page || statePaginationData.currentPage,
//     }

//     console.log('params >>', searchData);
//     console.log('params >>', paginationData);
//     searchData     = merge({}, stateSearchData, searchData);
//     paginationData = merge({}, statePaginationData, paginationData);
//     console.log('post params >>', searchData);
//     console.log('post params >>', paginationData);

//     return new Promise((resolve, reject) => {
//             storeDispatch(
//                 storeTypes.SHOP.UPDATE_SEARCH,
//                 searchData,
//                 () => { resolve(); }
//             );
//         })
//         .then(() => {
//             return new Promise((resolve, reject) => {
//                 storeDispatch(
//                     storeTypes.SHOP.UPDATE_PAGINATION,
//                     paginationData,
//                     () => { resolve(); }
//                 );
//             });
//         })
//         .then(() => {
//             return doSearchFlag === true ?
//                 doSearch(searchString) :
//                 new Promise((resolve, reject) => {});
//         });
// }

// /**
//  * Writes the sting being searched into the shopState. We do this before calling
//  * the main search method because the search is being debounced. If we don't do
//  * this beforehand, there is a stutter in the characters in the input field.
//  *
//  * @param {string} searchString
//  */
// export function searchString(searchString)
// {
//     console.log('SHOP searchString >>', searchString);
//     // Return a promise so following actions only happen when it's time.
//     return new Promise((resolve, reject) => {
//         storeDispatch(
//             storeTypes.SHOP.UPDATE_SEARCH,
//             { string: searchString },
//             () => { resolve(searchString); }
//         );
//     });
// }

// export function searchOptions(options)
// {
//     return new Promise((resolve, reject) => {
//         storeDispatch(
//             storeTypes.SHOP.UPDATE_SEARCH_OPTIONS,
//             options,
//             () => { resolve(); }
//         );
//     });
// }

// export function searchOptionsToggle(which, doSearchFlag = true)
// {
//     let options = storeGet('shopState.search.options');

//     return searchOptions({
//         [which]: !options[which],
//     }).then(response => {
//         // Reset the current search page to the first page when an option changes,
//         // because a changed option could/should change the results to display.
//         return searchPage(1, false);
//     }).then(response => {
//         return doSearchFlag === true ?
//             doSearch(storeGet('shopState.search.string')) :
//             new Promise((resolve, reject) => {});
//     });
// }

// export function searchOptionsSet(which, value, doSearchFlag = true)
// {
//     return searchOptions({
//         [which]: value,
//     }).then(response => {
//         // Reset the current search page to the first page when an option changes,
//         // because a changed option could/should change the results to display.
//         return searchPage(1, false);
//     }).then(response => {
//         return doSearchFlag === true ?
//             doSearch(storeGet('shopState.search.string')) :
//             new Promise((resolve, reject) => {});
//     });
// }

// export function searchOptionsOrderBy(which)
// {
//     let options = storeGet('shopState.search.options'),
//         orderBy = which;

//     // If current orderBy IS equal to new orderBy, we toggle the current direction,
//     // it's the same orderBy field, just changing the direction.
//     let direction = options.direction === 'asc' ?
//         'desc' :
//         'asc';

//     // If current orderBy is NOT equal to new orderBy, we set the direction to
//     // ascending, it's a new orderBy field.
//     direction = options.orderBy !== which ?
//         'asc' :
//         direction;

//     return searchOptions({
//         orderBy:   orderBy,
//         direction: direction,
//     }).then(response => {
//         return doSearch(storeGet('shopState.search.string'));
//     });
// }

// export function searchPage(page, doSearchFlag = true, router = null)
// {
//     console.log('searchPage: page:', page);
//     // let searchString = storeGet('shopState.search.string');

//     new Promise((resolve, reject) => {
//         storeDispatch(
//             storeTypes.SHOP.UPDATE_PAGINATION,
//             { currentPage: page },
//             () => { resolve(); }
//         )
//     })
//     .then(() => {
//         if (doSearchFlag === true) {
//             return doSearch(storeGet('shopState.search.string'), router);
//         }

//         return page;
//         // let page = storeGet('shopState.pagination.currentPage');

//         // doSearch(searchString);
//     });
// }

// export function searchResultsClose()
// {
//     storeDispatch(storeTypes.SHOP.SEARCH_RESULTS_CLOSE);
// }

/**
 * Gets the categories out of storage and sets them to the store. An optional
 * parameter for the active cateogry (retrieved from route params) is passed and
 * if supplied, will set to the store what has been requested in the URL. An
 * optional flag to force the storage retrieval of categories is available.
 *
 * @param   {string} activeCategory
 * @param   {boolean} force
 * @returns {Promise}
 */
export function getCategories(activeCategory = false, force = false)
{
    let categories = storeGet('shopState.categories');

    // If we already have categories in the store, the length of the array is
    // greater than 0 and we haven't been told to force the retrieval of the
    // categories, return out of here.
    if (
        typeof categories !== 'undefined' &&
        categories.length > 0 &&
        force === false
    ) {
        // Before we split, have we been given an active category to set to the
        // store?
        if (activeCategory !== false) {
            return this.setCategory(activeCategory);
        }

        return;
    }

    // Otherwise, get the categories out of the backend.
    return DISH.requests.GET('/categories')
    .then(response => {
        // Return a Promise in case we've been told to save the active category,
        // we can't loop through the categories until they've been returned from
        // this request.
        return new Promise((resolve, reject) => {
            storeDispatch(
                storeTypes.SHOP.UPDATE_CATEGORIES,
                response.data.data,
                () => { resolve(); }
            );
        });
    })
    .then(() => {
        // If we've been passed an activeCategory, save that to the store so the rest
        // of the application can use that information accordingly.
        if (activeCategory !== false) {
            return this.setCategory(activeCategory);
        }
    });
}

/**
 * Sets the active category supplied into the store. Returns a Promise for ease
 * of chaining.
 *
 * @param   {string} activeCategory
 * @returns {Promise}
 */
export function setCategory(activeCategory)
{
    return new Promise((resolve, reject) => {
        storeDispatch(
            storeTypes.SHOP.SET_ACTIVE_CATEGORY,
            activeCategory,
            () => { resolve(); }
        );
    });
}

/**
 * Gets the genres out of storage and sets them to the store. An optional
 * parameter for the active genre (retrieved from route params) is passed and
 * if supplied, will set to the store what has been requested in the URL. An
 * optional flag to force the storage retrieval of genres is available.
 *
 * @param   {string} activeGenre
 * @param   {boolean} force
 * @returns {Promise}
 */
export function getGenres(activeGenre = false, force = false)
{
    let genres = storeGet('shopState.genres');

    // If we already have genres in the store, the length of the array is greater
    // than 0 and we haven't been told to force the retrieval of the genres,
    // return out of here.
    if (
        typeof genres !== 'undefined' &&
        genres.length > 0 &&
        force === false
    ) {
        // Before we split, have we been given an active genre to set to the
        // store?
        if (activeGenre !== false) {
            return this.setGenre(activeGenre);
        }

        return;
    }

    // Otherwise, get the genres out of the backend.
    return DISH.requests.GET('/genres')
    .then(response => {
        // Return a Promise in case we've been told to save the active genre, we
        // can't loop through the genres until they've been returned from this
        // request.
        return new Promise((resolve, reject) => {
            storeDispatch(
                storeTypes.SHOP.UPDATE_GENRES,
                response.data.data,
                () => { resolve(); }
            );
        });
    })
    .then(() => {
        // If we've been passed an activeGenre, save that to the store so the rest
        // of the application can use that information accordingly.
        if (activeGenre !== false) {
            return this.setGenre(activeGenre);
        }
    });
}

/**
 * Sets the active genre supplied into the store. Returns a Promise for ease of
 * chaining.
 *
 * @param   {string} activeGenre
 * @returns {Promise}
 */
export function setGenre(activeGenre)
{
    return new Promise((resolve, reject) => {
        storeDispatch(
            storeTypes.SHOP.SET_ACTIVE_GENRE,
            activeGenre,
            () => { resolve(); }
        );
    })
}

// export function getInventory(
//     origin,
//     categoryId,
//     genreId = null,
//     page = 1,
//     perPage = null,
//     inventoryKey = null
// ) {
//     perPage = perPage === null ?
//         config.inventory.defaultPerPage :
//         perPage;

//     // If the origin is the category page, we need to make sure that there is a
//     // categoryId passed in so we can display the right content and not have an
//     // FoC (Flash of Content).
//     if (origin == 'category' && categoryId === null) {
//         return null;
//     }

//     // If the origin is the genre page, we need to make sure that there is a
//     // genreId passed in so we can display the right content and not have an FoC
//     // (Flash of Content).
//     if (origin === 'genre' && genreId === null) {
//         return null;
//     }

//     return DISH.requests.GET('/inventory', {
//         params: {
//             page:       page,
//             size:       perPage,
//             categoryId: categoryId,
//             genreId:    genreId,
//         }
//     })
//     .then(response => {
//         return new Promise((resolve, reject) => {
//             storeDispatch(
//                 storeTypes.SHOP.UPDATE_INVENTORY,
//                 {
//                     inventoryKey: inventoryKey,
//                     data: response.data.data,
//                 },
//                 () => { resolve(response); }
//             );
//         })
//     })
//     .then(response => {
//         return new Promise((resolve, reject) => {
//             storeDispatch(
//                 storeTypes.SHOP.UPDATE_PAGE_META,
//                 {
//                     pagination: response.data._meta,
//                     links:      response.data._links,
//                 },
//                 () => { resolve(response); }
//             );
//         });
//     });
// }

export function getAlbum(artistSlug, albumSlug, force = false)
{
    let artist = storeGet('shopState.artistSlug');
    let album  = storeGet('shopState.albumSlug');

    // If what we've been passed for an artistSlug or an albumSlug are false, we
    // don't need to get anything from storage, we'll get out.
    if (artistSlug === false || albumSlug === false) {
        return null;
    }

    // If we already have genres in the store, the length of the array is greater
    // than 0 and we haven't been told to force the retrieval of the genres,
    // return out of here.
    if (
        typeof artist === 'undefined' ||
        typeof album === 'undefined' ||
        artist !== artistSlug ||
        album !== albumSlug ||
        force === true
    ) {
        return new Promise((resolve, reject) => {
            storeDispatch(
                storeTypes.SHOP.CLEAR_ALBUM_DATA,
                {},
                () => { resolve(); }
            );
        })
        .then(() => {
            return DISH.requests.GET('/artists/'+artistSlug+'/albums/'+albumSlug)
        })
        .then(response => {
            return new Promise((resolve, reject) => {
                storeDispatch(
                    storeTypes.SHOP.SET_ACTIVE_ALBUM,
                    {
                        artistSlug: artistSlug,
                        albumSlug:  albumSlug,
                    },
                    () => { resolve(response); }
                )
            });
        })
        .then(response => {
            return new Promise((resolve, reject) => {
                storeDispatch(
                    storeTypes.SHOP.UPDATE_ALBUM,
                    response.data.data[0]
                );
            });
        });
    }
}

/**
 * Removes from the store, the current artistSlug, albumSlug and album data.
*/
export function clearAlbum()
{
    storeDispatch(storeTypes.SHOP.CLEAR_ALBUM_DATA);
}

export function cgiClose()
{
    storeDispatch(storeTypes.SHOP.CGI_CLOSE);
}

export function cgiIdentifier(identifier = '&nbsp')
{
    storeDispatch(
        storeTypes.SHOP.CGI_IDENTIFIER,
        identifier
    );
}

export function cgiToggle()
{
    storeDispatch(storeTypes.SHOP.CGI_TOGGLE);
}
