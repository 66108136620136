export const billingConstraints = {
    firstName: {
        presence: { message: "^Your First Name can't be empty" },
        alpha:    {
            dash: true,
            spaces: true,
            message: "^Your First Name has invalid characters",
        }
    },
    lastName: {
        presence: { message: "^Your Last Name can't be empty" },
        alpha:    {
            dash:    true,
            spaces:  true,
            message: "^Your Last Name has invalid characters",
        }
    },
    country: {
        presence: { message: "^Your Country can't be empty" },
    },
    province: {
        presence: { message: "^Your Province can't be empty" },
    },
    city: {
        presence: { message: "^Your Town / City can't be empty" },
    },
    postalCode: {
        presence: { message: "^Your Postcode / Zip can't be empty" },
        format: {
            pattern: "(\\d{5}|[a-z][0-9][a-z] ?[0-9][a-z][0-9])",
            flags:   "i",
            message: "^Your Postcode / Zip seems to be invalid",
        },
    },
    streetAddress1: {
        presence: { message: "^Your Street Address can't be empty" },
    },
    phone: {
        presence: { message: "^Your Phone Number can't be empty" },
    },
    email: {
        presence: { message: "^Your Email can't be empty" },
        email:    { message: "^Your Email seems to be invalid" },
    },
    acceptTC: {
        boolean: {
            state:   true,
            message: "^You must accept the Terms & Conditions before proceeding with your order",
        },
    }
};

export const shippingConstraints = {
    firstName: {
        presence: { message: "^Your First Name can't be empty" },
        alpha:    {
            dash: true,
            spaces: true,
            message: "^Your First Name has invalid characters",
        }
    },
    lastName: {
        presence: { message: "^Your Last Name can't be empty" },
        alpha:    {
            dash:    true,
            spaces:  true,
            message: "^Your Last Name has invalid characters",
        }
    },
    country: {
        presence: { message: "^Your Country can't be empty" },
    },
    province: {
        presence: { message: "^Your Province can't be empty" },
    },
    city: {
        presence: { message: "^Your Town / City can't be empty" },
    },
    postalCode: {
        presence: { message: "^Your Postcode / Zip can't be empty" },
        format: {
            pattern: "(\\d{5}|[a-z][0-9][a-z] ?[0-9][a-z][0-9])",
            flags:   "i",
            message: "^Your Postcode / Zip seems to be invalid",
        },
    },
    streetAddress1: {
        presence: { message: "^Your Street Address can't be empty" },
    },
    phone: {
        presence: { message: "^Your Pphone Number can't be empty" },
    },
    email: {
        presence: { message: "^Your Email can't be empty" },
        email:    { message: "^Your Email seems to be invalid" },
    },
};
