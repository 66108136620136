import React     from 'react';
import * as Form from 'components/Form';
import { useCart } from 'providers/CartProvider';

// const quantityOptions = [
//     {value: 1,  label: '1'},
//     {value: 2,  label: '2'},
//     {value: 3,  label: '3'},
//     {value: 4,  label: '4'},
//     {value: 5,  label: '5'},
//     {value: 6,  label: '6'},
//     {value: 7,  label: '7'},
//     {value: 8,  label: '8'},
//     {value: 9,  label: '9'},
//     {value: 10, label: '10'},
// ];

export default function ShopAddToCartButton(props)
{
  const { iconOnly, inventoryId, size, withQuantityDropdown } = props;
  const { doAddToCart } = useCart();

  const getContainerClassNames = (props) => {
    const classNames = ['container-shopaddtocartbutton'];

    if (withQuantityDropdown) {
      classNames.push('with-quantity');
    }

    if (iconOnly) {
      classNames.push('icon-only');
    }

    switch (size) {
      case 'small':
      case 'large':
        classNames.push('small');
        break;
    }

    return classNames.join(' ');
  }

  const handleAdd = () => {
    doAddToCart(inventoryId);
  }

  return (
    <article className={getContainerClassNames(props)}>
      <QuantityDropdown {...props} />
      <Button {...props} addToCart={handleAdd} />
      <IconButton {...props} addToCart={handleAdd} />
    </article>
  )
}

function QuantityDropdown(props)
{
  const { size, withQuantityDropdown } = props;

  if (!withQuantityDropdown) {
    return null;
  }

  const quantityOptions = Array.from({length: 10}, (_, i) => i + 1).map((qty) => {
    return {value: qty, label: qty};
  })

  return (
    <Form.Select
      firstEmptyOption={false}
      options={quantityOptions}
      onChange={() => {
        //this.setQuantity
      }}
      noPadding
      size={size}
      value={1}
    />
  )
}

function Button(props)
{
  const { addToCart, buttonLabel, iconOnly, size } = props;

  const classNames = ['btn'];

  switch (size) {
    case 'small':
    case 'large':
      classNames.push(`btn--${size}`);
      break;
  }

  if (typeof iconOnly !== 'undefined') {
      return null;
  }

  return (
    <button
      className={classNames.join(' ')}
      onClick={addToCart}
    >
      {buttonLabel || 'Add To Cart'}
    </button>
  )
}

function IconButton(props)
{
  const { addToCart, iconOnly } = props;

  if (typeof iconOnly === 'undefined') {
    return null;
  }

  return (
    <div
      className="container-icon"
      onClick={addToCart}
    >
      <i className="ion ion-ios-cart cart-icon" />
      <i className="ion ion-ios-add add-icon" />
    </div>
  )
}
