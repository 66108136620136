import React         from 'react';
import { NavLink }   from 'react-router-dom';
import qs            from 'qs';
import { useSearch } from 'providers/SearchProvider';
import { getRoute }  from 'routes/utilities';

const DEFAULT_MAX_GROUPED_BUTTONS = 5;
const DEFAULT_MAX_INLINE_BUTTONS = 10;

export default function Pagination(props)
{
  const className = props.className ? ` ${props.className}` : '';

  return (
    <nav className={`pagination is-small${className}`} role="navigation">
      <PreviousButton />
      <NextButton />

      <ButtonSet {...props} />
    </nav>
  )
}

function ButtonSet(props)
{
  const maxGroupedButtons = props.maxGroupedButtons || DEFAULT_MAX_GROUPED_BUTTONS;
  const maxInlineButtons = props.maxInlineButtons || DEFAULT_MAX_INLINE_BUTTONS;
  const { pagination } = useSearch();

  if (pagination.totalPages <= maxInlineButtons) {
    return (
      <ul className="pagination-list">
        <RenderedGroup buttonSet={[...Array(pagination.totalPages).keys()]} />
        <Details />
      </ul>
    )
  }

  const [firstSet, secondSet, thirdSet] = resolveButtonSets(
    pagination,
    maxGroupedButtons
  );

  return (
    <ul className="pagination-list">
      <RenderedGroup buttonSet={firstSet} />
      <Ellipsis maxGroupedButtons={maxGroupedButtons} side="left" />
      <RenderedGroup buttonSet={secondSet} />
      <Ellipsis maxGroupedButtons={maxGroupedButtons} side="right" />
      <RenderedGroup buttonSet={thirdSet} />
      <Details />
    </ul>
  )
}

const resolveButtonSets = (pagination, maxGroupedButtons) => {
  const { currentPage, totalPages } = pagination;

  if (currentPage <= maxGroupedButtons) {
    return [
      [...Array(maxGroupedButtons).keys()],
      [],
      [...[totalPages - 1]],
    ];
  }

  if (currentPage > totalPages - maxGroupedButtons) {
    const buffer = totalPages - maxGroupedButtons;
    const group = [];

    [...Array(maxGroupedButtons).keys()].map(key => {
      group.push(key + buffer);
    });

    return [
      [0],
      [],
      group,
    ];
  }

  const split = Math.floor(maxGroupedButtons / 2);
  const buffer = currentPage - split - 1;

  const group = [];
  [...Array(maxGroupedButtons).keys()].map(key => {
    group.push(key + buffer);
  });

  return [
    [0],
    group,
    [...[totalPages - 1]],
  ];
};

function RenderedGroup(props)
{
  const { buttonSet } = props;
  const { pagination: { currentPage, nextLink }} = useSearch();

  const queryString = qs.parse(nextLink.split('?')[1]);
  const route = getRoute('search');

  return buttonSet.map(key => {
    if (!buttonSet.length) {
      return null;
    }

    const page = Number(key) + 1;
    const className = page === currentPage ? ' is-current' : '';
    queryString.page = page;

    return (
      <li key={page}>
        <NavLink
          className={`pagination-link${className}`}
          to={`${route}?${qs.stringify(queryString)}`}
        >
          {page}
        </NavLink>
      </li>
    )
  })
}

function Ellipsis(props)
{
  const { maxGroupedButtons, side } = props;
  const { pagination: { currentPage, totalPages }} = useSearch();

  const leftBuffer = currentPage - Math.floor(maxGroupedButtons / 2);
  const rightBuffer = currentPage + Math.ceil(maxGroupedButtons / 2) + 1;

  const showLeft = side === 'left' &&
    currentPage > maxGroupedButtons &&
    leftBuffer > 1;
  const showRight = side === 'right' &&
    rightBuffer < totalPages;

  if (!showLeft && !showRight) {
    return null;
  }

  return (
    <li>
      <span className="pagination-ellipsis">&hellip;</span>
    </li>
  )
}

function Details(props)
{
  const { pagination: { currentPage, perPage, totalResults }} = useSearch();

  const startRecord = (currentPage - 1) * perPage + 1;
  const endRecordTemp = startRecord + perPage - 1;
  const endRecord = endRecordTemp > totalResults ? totalResults : endRecordTemp;

  return (
    <div className="ml-4">
      {startRecord} - {endRecord} <span className="mx-2">of</span> {totalResults}
    </div>
  )
}

function PreviousButton(props)
{
  const { pagination: { currentPage, prevLink }} = useSearch();
  const queryString = prevLink.split('?')[1];

  if (currentPage === 1) {
    return (
      <span className="pagination-previous is-disabled">
        Previous Page
      </span>
    )
  }

  return (
    <NavLink
      className="pagination-previous"
      to={`${getRoute('search')}?${queryString}`}
    >
      Previous Page
    </NavLink>
  )
}

function NextButton(props)
{
  const { pagination: { currentPage, totalPages, nextLink }} = useSearch();
  const queryString = nextLink.split('?')[1];

  if (currentPage >= totalPages) {
    return (
      <span className="pagination-next is-disabled">
        Next Page
      </span>
    )
  }

  return (
    <NavLink
      className="pagination-next"
      to={`${getRoute('search')}?${queryString}`}
    >
      Next Page
    </NavLink>
  )
}
