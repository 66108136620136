import React              from 'react';
import SortableListHeader from 'components/Utilities/SortableListHeader';
import { useSearch }      from 'providers/SearchProvider';

export default function SearchListingHeader(props)
{
  const { doSetOrderBy } = useSearch();

  let classBase = props.classBase+'items--list-header';

  return (
    <article className={classBase}>
      <SortableListHeader
        className={`${classBase}--product-name-primary sortable`}
        identifier="artist"
        label="Artist"
        onClick={() => doSetOrderBy('artist')}
      />
      <SortableListHeader
        className={`${classBase}--product-name-secondary sortable`}
        identifier="album"
        label="Album"
        onClick={() => doSetOrderBy('album')}
      />
      <SortableListHeader
        className={`${classBase}--barcode sortable`}
        identifier="barcode"
        label="EAN / Barcode"
        onClick={() => doSetOrderBy('barcode')}
      />
      <SortableListHeader
        className={`${classBase}--label sortable`}
        identifier="label"
        label="Label"
        onClick={() => doSetOrderBy('label')}
      />
      <div className={classBase+'--format'}>Format</div>
      <div className={classBase+'--quantity'}>QTY</div>
      <div className={classBase+'--price'}>Price</div>
      <div />
    </article>
  )
}
