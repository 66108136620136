'use strict';

import React               from 'react';
import ControlErrorMessage from 'components/Form/ControlErrorMessage';
import ControlLabel        from 'components/Form/ControlLabel';
import * as DISH           from 'api/dilatedShop';

class StripeInput extends React.Component {
    render()
    {
        return (
            <article className={DISH.form.getClassNames(this.props)}>
                <ControlLabel {...this.props} />
                {/* <div id={DISH.form.getId(this.props)} /> */}
                {this.props.children}
                <ControlErrorMessage {...this.props} />
            </article>
        )
    }
};

export default StripeInput;
