import React, { useEffect, useState } from 'react';
import * as DISH           from 'api/dilatedShop';
import ControlDescription  from 'components/Form/ControlDescription';
import ControlErrorMessage from 'components/Form/ControlErrorMessage';
import ControlLabel        from 'components/Form/ControlLabel';
import useDebounce         from 'components/Utilities/useDebounce';

export const TYPE_CHECKBOX = 'textbox';
export const TYPE_PASSWORD = 'password';

export default function Input(props)
{
  const {
    debounce,
    error,
    errorMessage,
    maxLength,
    onChange,
    refx: ref,
    register,
    required,
    value: propValue,
    ...rest
  } = props;
  const [event, setEvent] = useState();
  const [value, setValue] = useState(propValue);
  const type = props.type || TYPE_CHECKBOX;
  const id = DISH.form.getId(props);
  const name = DISH.form.getName(props);
  const classNames = DISH.form.getClassNames(props);
  const classNamesInput = DISH.form.getInputClassNames(props);

  const debouncedValue = useDebounce(value, debounce || 0);

  useEffect(() => {
    // If we don't have an onChange handler OR no event object to send back, get
    // out of here, nothing more we can do.
    if (!onChange || !event) {
      return;
    }

    // Call the passed in handler with the event object from our local input
    // field.
    onChange(event);
  }, [debouncedValue]);

  const doOnChange = (event) => {
    // Storing the event object because we need it for the debounced handling.
    setEvent(event);
    setValue(event.target.value);
  }

  // If we've been passed a specific message in the errorMessage prop, that wins.
  // IF not, we'll try to resolve one out of potential error object.
  const resolvedErrorMessage = errorMessage || (error && error.message)

  const doRegister = () => {
    if (!register) {
      return null;
    }

    return {...register(name, { required: !!required })};
  }

  return (
    <article className={classNames}>
      <ControlLabel {...props} />
      <input
        type={type}
        id={id}
        name={name}
        ref={ref}
        maxLength={maxLength}
        className={classNamesInput}
        onChange={doOnChange}
        {...doRegister()}
        {...rest}
      />
      <ControlDescription {...props} />
      <ControlErrorMessage errorMessage={resolvedErrorMessage} {...props} />
    </article>
  )
}
