import React from 'react';

export default function TrackDuration(props)
{
  const { duration } = props;

  const durationParts = String(duration).split('.');
  const minutes = durationParts[0] || 0;
  const seconds = String(durationParts[1] || 0).substring(0, 2);

  return (
    <>{minutes.toString().padStart(1, '0')}:{seconds.toString().padStart(2, '0')}</>
  )
}
