import React, { useEffect }    from 'react';
import { useParams }           from 'react-router-dom';
import Page                    from 'components/Layouts/Page';
import OrderIdentification     from 'components/Order/OrderIdentification';
import OrderInformation        from 'components/Order/OrderInformation';
import OrderItems              from 'components/Order/OrderItems';
import SectionHeader           from 'components/Utilities/SectionHeader';
import { Header, SmallHeader } from 'components/UI/Typography';
import { useOrder }            from 'providers/OrderProvider';

export default function Order(props)
{
  const { thanks }          = props;
  const { orderToken }      = useParams();
  const { getOrder, order } = useOrder();

  useEffect(() => {
    getOrder(orderToken);
  }, [orderToken]);

  // If we don't have an order, we're not ready yet.
  if (!order) {
    return null;
  }

  return (
    <Page>
      <Header>Order Details</Header>

      <div>

        <OrderIdentification order={order} />
        <OrderInformation order={order} />

        <SectionHeader>Order Items</SectionHeader>
        <OrderItems items={order.items} />

        <Thanks thanks={thanks} />
      </div>
    </Page>
  )
}

function Thanks(props) {
  const { thanks } = props;

  if (!thanks) {
    return null;
  }

  return !thanks ?
    null :
    <SmallHeader className="textalign-center mt-6">
      Thank You for your Order!
    </SmallHeader>;
}
