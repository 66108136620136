'use strict';

import React        from 'react';
import PropTypes    from 'prop-types';
import { connect }  from 'react-redux';

class Oops extends React.Component {
    render()
    {
        return (
            <section>
                <h1>Oops</h1>
                <div className="grid">
                    <div className="column">Name:</div>
                    <div className="column-11">{this.props.route.name}</div>
                    <div className="column">Path:</div>
                    <div className="column-11">{this.props.route.path}</div>
                </div>
            </section>
        )
    }
};

const mapStateToProps = function(store)
{
    return {
        //
    };
}

export default connect(mapStateToProps)(Oops);
