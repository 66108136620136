import React, { useEffect } from 'react';
import SearchButton         from 'components/Shop/Search/SearchButton';
import SearchInput          from 'components/Shop/Search/SearchInput';
import SearchOptions        from 'components/Shop/Search/SearchOptions';

export default function SearchFields(props)
{
  const hideSearchButton = props.hideSearchButton === true;

  return (
    <section className="columns is-multiline">
      <div className="column">
        <SearchInput {...props} />
      </div>
      <div className="column is-narrow">
        <SearchButton hideSearchButton={hideSearchButton} />
      </div>

      <div className="column is-12">
        <SearchOptions />
      </div>
    </section>
  )
}
