'use strict';

import React                  from 'react';
import PropTypes              from 'prop-types';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions }            from 'redux-router5';
import { storeReady }         from 'store/createStore';
import * as DISH              from 'api/dilatedShop';
import Link                   from 'components/Utilities/Link';

const classBase = 'container-categories';

class ShopCategories extends React.Component {
    constructor(props, context)
    {
        super(props);
        this.router = context.router;
    }

    render()
    {
        return (
            <article className={classBase}>
                <h1 className={classBase+'--header'}>Categories</h1>
                {this._displayAllLink()}
                {this._displayCategories()}
            </article>
        )
    }

    _displayAllLink()
    {
        if (this.props._categoryId === null) {
            return null;
        }

        const { navigateTo } = this.props;

        return <Link
            className="btn--large-heavy all-link plain flat"
            router={this.router}
            navigateTo={navigateTo}
            routeName='shop'
        >&lt; ALL</Link>
    }

    _displayCategories()
    {
        if (storeReady('shopState.categories') === false) { return null; }

        const { navigateTo } = this.props;

        return this.props._categories.map((item, key) => {
            if (
                this.props._categoryId !== null &&
                this.props._categoryId !== item.id
            ) {
                return null;
            }

            return (
                <div
                    key={key}
                    className={classBase+'--category'}
                >
                    <Link
                        className={this._getClasses(item.slug)}
                        router={this.router}
                        navigateTo={navigateTo}
                        routeName='shop.category'
                        routeParams={{category: item.slug}}
                    >{item.category}</Link>
                </div>
            )
        });
    }

    _getClasses(categorySlug)
    {
        let classNames = ['btn--large-heavy plain flat'];

        if (categorySlug === this.props.categorySlug) {
            classNames.push('active');
        }

        return classNames.join(' ');
    }
};

ShopCategories.contextTypes = {
    router: PropTypes.object.isRequired
};

const mapStateToProps = function(store)
{
    return {
        _categoryId: store.shopState.categoryId,
        _categories: store.shopState.categories,
    };
}

export default connect(
    mapStateToProps,
    dispatch => bindActionCreators({ navigateTo: actions.navigateTo }, dispatch)
)(ShopCategories);
