import Checkout     from 'components/Pages/Cart/Checkout';
import Confirmation from 'components/Pages/Cart/Confirmation';
import Front        from 'components/Pages/Cart/Front';

export default {
  Checkout,
  Confirmation,
  Front,
};

// export default function Cart(props)
// {
//   return (
//     <section className="container-cart">
//       <Routes>
//         <Route
//           component={Front}
//           path={"/"}
//         />
//         <Route
//           exact
//           component={Checkout}
//           path={getRoute('cart.checkout')}
//         />
//         <Route
//           exact
//           component={Confirmation}
//           path={getRoute('cart.confirmation')}
//         />
//       </Routes>
//       <Outlet />
//     </section>
//   )
// }
