import React           from 'react';
import { NavLink }     from 'react-router-dom';
import Button          from 'components/Utilities/Button';
import { formatPrice } from 'lib/formatters';
import { useCart }     from 'providers/CartProvider';
import { getRoute }    from 'routes/utilities';

const classBase = 'container-cartlistingitem';

const METHOD_MINUS = 0;
const METHOD_PLUS  = 1;
const IMAGE_SIZE_SMALL = 's';

export default function CartListingItem(props)
{
  return (
    <article className={classBase}>
      <Image {...props} />
      <ProductName {...props} />
      <Price {...props} />
      <Quantity {...props} />
      <Total {...props} />
      <RemoveButton {...props} />
    </article>
  )
}

function CartItemLink(props)
{
  const { children, confirmation, item } = props;

  if (confirmation === true) {
    return children;
  }

  return (
    <NavLink
      to={getRoute('shop.album', {
        album:    item.album.slug,
        artist:   item.contributors[0].slug,
        category: 2,
        genre:    item.genre.main.slug,
      })}
    >
      {children}
    </NavLink>
  )
}

function Image(props)
{
  const { item, size } = props;
  let imageSize = IMAGE_SIZE_SMALL;

  if (typeof size !== 'undefined') {
    imageSize = size;
  }

  let image = <div className="image-na">N/A</div>;

  if (typeof item.images[imageSize] !== 'undefined') {
    image = <img width="100%" src={`//${item.images[imageSize].url}`} />;
  }

  return (
    <div className={`${classBase}--image`}>
      <CartItemLink item={item}>
        {image}
      </CartItemLink>
    </div>
  )
}

function ProductName(props)
{
  const { item }        = props;
  const album           = item.album;
  const publicationYear = album.publishDate.toString().substring(0, 4);
  const titleInfo       = `${album.title} [${publicationYear}]`;

  return (
    <div className={`${classBase}--product-name`}>
      <CartItemLink item={item}>
        <div>
          <div className={`${classBase}--product-name-primary`}>
            {item.contributors[0].name}
          </div>
          <div className={`${classBase}--product-name-secondary`}>
            {titleInfo}
          </div>
          <div className={`${classBase}--product-name-publisher`}>
            {item.publisher}
          </div>
          <div className={`${classBase}--product-name-barcode`}>
            {item.barcode}
          </div>
        </div>
      </CartItemLink>
    </div>
  )
}

function Price(props)
{
  const { item } = props;

  return (
    <div className={`${classBase}--price`}>
      $ {formatPrice(item.current_price)}
    </div>
  )
}

function Quantity(props)
{
  const { confirmation, item } = props;

  return (
    <div className={`${classBase}--quantity`}>
      <ChangeQuantityButton
        confirmation={confirmation}
        item={item}
        method={METHOD_MINUS}
      />
      <div className="quantity-value">{item.quantity}</div>
      <ChangeQuantityButton
        confirmation={confirmation}
        item={item}
        method={METHOD_PLUS}
      />
    </div>
  )
}

function ChangeQuantityButton(props)
{
  const { confirmation, item, method} = props;
  const { doUpdateQuantity } = useCart();
  let className;

  if (confirmation === true) {
    return null;
  }

  switch (Number(method)) {
    case METHOD_MINUS:
      className = 'remove';
      break;
    case METHOD_PLUS:
      className = 'add';
      break;
  }

  const doChangeQuantity = () => {
    let newQuantity = item.quantity;

    method === METHOD_MINUS ?
      newQuantity-- :
      newQuantity++;

    if (newQuantity < 1) {
      return;
    }

    doUpdateQuantity(item.token, item.id, newQuantity);
  };

  return (
    <Button
        className={`ion-ios-${className}-circle-outline cart-quantity-${className}`}
        onClick={doChangeQuantity}
    />
  )
}

function Total(props)
{
  const { item } = props;

  return (
    <div className={`${classBase}--total`}>
      $ {formatPrice(item.current_price * item.quantity)}
    </div>
  )
}

function RemoveButton(props)
{
  const { confirmation, item } = props;
  const { doRemoveFromCart } = useCart();

  if (confirmation === true) {
    return null;
  }

  const handleRemove = () => {
    doRemoveFromCart(item.token);
  };

  return (
    <div className={`${classBase}--remove`}>
      <Button
        title="Remove this item from your cart"
        className="ion-ios-close-circle-outline danger cart-remove"
        onClick={handleRemove}
      />
    </div>
  )
}
