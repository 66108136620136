import routes from 'routes/routes';

/**
 * Looks through the defined routes to get whatever is being asked for.
 *
 * @param {string} routeKey
 * @param {object} params
 * @param {string} attribute
 * @returns string
 */
export function getRoute(routeKey, params = {}, attribute = 'path')
{
  let routePath = resolveRoute(routeKey) || '/';

  Object.keys(params).map((item, key) => {
      routePath = routePath.replace(`:${item}`, params[item]);
  });

  return routePath;
}

/**
 * Steps through a dor-notated string of route components to get to an actual
 * route definition.
 *
 * @param {string} routeKey
 * @returns string
 */
function resolveRoute(routeKey)
{
  const parts = routeKey.split('.');

  // JUST in case the split on . doesn't give us anything... Shouldn't be possible.
  if (parts.length === 0) {
    return null;
  }

  // If we only have one element in the array, there wasn't any .'s in the routeKey
  // we were given, so just return whatever we have at that element, or null if
  // nothing is found there.
  if (parts.length === 1) {
    return routes[parts[0]]['path'] || null;
  }

  // Some initialization before we start iterating (potential) children to find
  // our final destination.
  let iterated = routes;
  let path = '';
  let isLast = false;

  for (const i in parts) {
    // Checking if we're on the last element of the dot-notated routeKey we were
    // supplied.
    isLast = Number(i) === Number(parts.length) - Number(1);

    if (isLast === false && iterated[parts[i]] && iterated[parts[i]].children) {
      path += iterated[parts[i]].path || '';
      iterated = iterated[parts[i]].children;

      continue;
    }

    iterated = iterated[parts[i]];
    path += iterated.path || '';
  }

  return path;
}

/**
 * Little helper that makes it easier to make a properly-formed "search" request
 * path.
 *
 * @param {string} search
 */
export function searchRoute(search)
{
  return getRoute('search') + '?s=' + search;
}
