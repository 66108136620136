import React, { useEffect } from 'react';
import { useParams }        from 'react-router';
import Page                 from 'components/Layouts/Page';
import ShopSearch           from 'components/Shop/Search';
import ShopAlbum            from 'components/Shop/Album';
import { useShop }          from 'providers/ShopProvider';

export default function Album() {
  const { artist, album } = useParams();
  const { clearAlbum, currentAlbum, getAlbum } = useShop();

  useEffect(() => {
    getAlbum(artist, album);

    return () => clearAlbum();
  }, [artist, album]);

  return (
    <Page>
      <div className="columns is-multiline">
        <div className="column is-10 is-offset-1 pt-4 pb-6">
          <ShopSearch
            instantInputSearch={false}
            quickSearchResults={false}
          />
        </div>
        <div className="column is-12">
          <ShopAlbum item={currentAlbum} />
        </div>
      </div>
    </Page>
  )
}
