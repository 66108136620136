import React           from 'react';
import Container       from 'components/Layouts/Container';
import PageHeader      from 'components/Layouts/PageHeader';
import PageFooter      from 'components/Layouts/PageFooter';

export default function Page(props)
{
  return (
    <Container>
      <PageHeader {...props} />
      <section className="content-page">
        {props.children}
      </section>
      <PageFooter {...props} />
    </Container>
  )
}
