import React from 'react';

export default function FormErrorMessage(props)
{
  const { error, register } = props;
  const name = props.name || 'message';

  return (
    <div>
      <input type="hidden" {...register(name)} />
      {error[name] && error[name].message}
    </div>
  )
}
