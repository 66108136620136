import { storeDispatch, storeTypes, storeGet }
                    from 'store/createStore';
import * as DISH    from 'api/dilatedShop';

let stripeObj = null;

/**
 * Initialize the Stripe fields. Up until this point, the checkout form only has
 * placeholders for the card number, expiry and CVC. Once we have the Stripe
 * "elements", their library will create the individual iframes that make up the
 * proper Stripe form(s).
 */
export function initialize()
{
    return;

    let style = {
        base: {
            fontFamily:      'Open Sans',
            fontSize:        '16px',
            height:           '40px',
            // lineHeight:      '34px',
            color:           '#3a3a3a',
            // backgroundColor: '#fbf9fb',
            '::placeholder': {
                color: '#e6e5e6',
            },
        },
    };

    let connection = window.Stripe(process.env.STRIPE_KEY);
    let elements   = connection.elements({
        fonts: [{
            family: 'Open Sans',
            cssSrc: "url('https://fonts.googleapis.com/css?family=Open+Sans')"
            // src: "url('https://fonts.gstatic.com/s/opensans/v15/mem8YaGs126MiZpBA-UFWJ0bf8pkAp6a.woff2')"
            // src: "url('https://fonts.gstatic.com/s/raleway/v11/QAUlVt1jXOgQavlW5wEfxQLUuEpTyoUstqEm5AMlJo4.woff2')"
        }],
    });
    let form       = {
        cardNumberElement: elements.create('cardNumber', {style: style}),
        cardExpiryElement: elements.create('cardExpiry', {style: style}),
        cardCvcElement:    elements.create('cardCvc',    {style: style}),
    };

    stripeObj = {
        connection: connection,
        elements:   elements,
        form:       form,
    };

    stripeObj.form.cardNumberElement.mount('#cc_cardnumber');
    stripeObj.form.cardExpiryElement.mount('#cc_expiry');
    stripeObj.form.cardCvcElement.mount('#cc_cvc');
}

export function processPaymentFields()
{
    return createToken();
}

export function storePaymentIdentifiers(identifiers = null)
{
    return storeToken(identifiers);
}

export function isEmpty()
{
    let _ding = storeGet('cartState.ccToken.token');

    return typeof _ding === 'undefined';
}

/**
 * Passes the Stripe inputs over to THEIR backend to get a token for the Credit
 * Card information that user has supplied. The token is what is used to do the
 * transaction at a later time.
 */
export function createToken()
{
    return new Promise((resolve, reject) => {
        resolve(stripeObj.connection.createToken(stripeObj.form.cardNumberElement));
    });
}

/**
 * Stores the credit card token returned back from the Stripe request in the
 * store for use when we're actually doing the transaction.
 *
 * @param {string} ccToken
 */
export function storeToken(token)
{
    /**** NOTE: This should really be part of the createToken method */
    return new Promise((resolve, reject) => {
        storeDispatch(
            storeTypes.CART.CHECKOUT_STORE_CC_TOKEN,
            token,
            () => {
                resolve();
            }
        );
    });
}
