import React from 'react';

export default function IconButton(props)
{
    if (typeof props.iconOnly === 'undefined') {
        return null;
    }

    return (
        <div
            className="container-icon"
            onClick={this.doAddToCart}
        >
            <i className="ion ion-ios-cart cart-icon" />
            <i className="ion ion-ios-add add-icon" />
        </div>
    )
}
