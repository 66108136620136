import React, { useEffect } from 'react';
import ReduxToastr          from 'react-redux-toastr';
import { useFramework }     from 'providers/FrameworkProvider';
import SessionTopUp         from 'routes/SessionTopUp';
import AppRoutes            from 'routes/AppRoutes';
import { authRoutes }       from 'routes/routes';

require('scss/main.scss');

export default function AppContainer()
{
  const { doFrameworkInit } = useFramework();

  useEffect(() => {
    doFrameworkInit();
  }, []);

  return (
    <article id="container-app" className="container-app">
      <AppRoutes adhocRoutes={authRoutes} />
      <SessionTopUp>
        <AppRoutes />
      </SessionTopUp>

      <ReduxToastr
        timeOut={8000}
        newestOnTop={false}
        preventDuplicates
        position="top-right"
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        progressBar
      />
    </article>
  )
}
