import React               from 'react';
import SearchListingItems  from 'components/Shop/Search/SearchListingItems';
import SearchPagination    from 'components/Shop/Search/SearchPagination';

const classBase = 'container-shopsearchlisting';

export default function SearchListing()
{
  return (
    <article className={classBase}>
      <h1 className={classBase+'--header'}>Search Results</h1>

      <SearchPagination location="top" />
      <SearchListingItems classBase={classBase} />
    </article>
  )
}
